import {
    Box,
    Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Strings from "../../assets/strings";
import { AppBar, LoadingProgress } from "../../components";
import ConfirmDialog from "../../components/ConfirmDialog";
import { DateInput } from "../../components/CustomTextField";
import SelectList from "../../components/selects/SelectList";
import { hasPermissionTo, nullToEmptyString, PERSMISOS } from '../../helpers';
import { TextValidatorCustom, ValidatorFormCustom } from "../../helpers/form-validator";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { agregarEditarOferta, eliminarOferta, mostrarOfertaById } from "../../redux/reducers/ofertasReducers";
import { IOferta } from "../../redux/types/IOferta";
import OfertaDetalles from "./OfertaDetalles";
import OfertaFarmacia from "./OfertaFarmacia";
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IOferta = {
    ofeDescripcion: "",
    ofeFechaDesde: "",
    ofeFechaVencimiento: "",
    ofeInactivo: false,
    detalle: [],
    farmacias: [],
}
function CrearOferta(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlMedId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:oferta,status} = useAppSelector(state => state.ofertas);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlMedId) Dispatch(mostrarOfertaById(Number(urlMedId)));
    },[urlMedId,Dispatch])
    useEffect(()=>{
        if(!!urlMedId && oferta?.id === parseInt(urlMedId)) {
            setState({...initialState, ...nullToEmptyString(oferta)});
        }else{
            setState(initialState)
        }
    },[urlMedId,oferta])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value,type,checked} = e.target;
        if(type==="checkbox"){
            return setState({...state,[name]:checked})
        }
        setState({...state,[name]:value})
    };
    const submit = () =>{
     //   e.preventDefault();
        Dispatch(agregarEditarOferta(state));
    }

    return(
        <Fragment>
            <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.oferta} backUrl={"/ofertas"}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}


                        <Paper className={classes.paper}>
                            <ValidatorFormCustom id="form1" onSubmit={submit}>
                                <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <TextValidatorCustom
                                            name={"ofeDescripcion"}
                                            label={Strings.descripcion}
                                            value={state.ofeDescripcion}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        {/*// @ts-ignore*/}
                                        <DateInput
                                            name={"ofeFechaDesde"}
                                            label={Strings.fechaDesde}
                                            value={state.ofeFechaDesde}
                                            onChange={handleChange}
                                            validators={['required']}
                                        />
                                    </Grid>
                                    <Grid item  xs={6} sm={3}>
                                        <SelectList
                                            required
                                            label={Strings.tipo}
                                            list={[{id: 1, name: "Vencimiento"}, {id: 2, name: "Promoción"}]}
                                            value={{id:state.ofeTipo, name:state.ofeTipoString??""}}
                                            onChange={(val)=>setState({...state,ofeTipo: val.id,ofeTipoString:val.name  })}
                                        />
                                    </Grid>
                                    <Grid item  xs={6} sm={3}>
                                        {/*// @ts-ignore*/}
                                        <DateInput
                                            validators={['required']}
                                            name={"ofeFechaVencimiento"}
                                            label={Strings.fechaVencimiento}
                                            value={state.ofeFechaVencimiento ?? null}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{pt:"4px !important"}}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={state.ofeInactivo}
                                                                                 name={"ofeInactivo"}
                                                                                 onChange={handleChange} />}
                                                              label={Strings.inactivo} />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </ValidatorFormCustom>
                        </Paper>
                        <Paper className={classes.paper}>
                            <OfertaDetalles ofertaState={state} onChange={v => setState({...state, detalle: v})}/>
                        </Paper>
                        <Paper className={classes.paper}>
                            <OfertaFarmacia farmacias={state.farmacias} onChange={v => setState({...state, farmacias: v})}/>
                        </Paper>
                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {!!state.id &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.ofertasActualizar)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="inherit" sx={{marginRight:"auto"}}>Eliminar</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>

                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarOferta(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearOferta;
