import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme
} from "@mui/material";
import {LoadingProgress, TopBarDialog} from "../../../components";

import Typography from "@mui/material/Typography";
import ListPreVenta from "./ListPreVenta";
import AppBar from "../../../components/appBar";
import Strings from '../../../assets/strings'
import {getArticulosByPacienteId, getOfertasDisponible, getPacienteById} from "../../../services";
import {Close} from "@mui/icons-material";
import SelectSearchPaciente from "../../../components/selects/SelectSearchPaciente";
import {SelectFiles} from "../../../components/selects";
import SelectSearchFarmacia from "../../../components/selects/SelectSearchFarmacia";
import {ICompra, ICompraDetalle} from "../../../redux/types/ICompra";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {agregarEditarCompra, eliminarCompra, mostrarCompraById} from "../../../redux/reducers/comprasReducers";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {hasPermissionTo, noTilde, nullToEmptyString, PERSMISOS, TIPOUSUARIO} from "../../../helpers";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {IOferta, IOfertaDetalle} from "../../../redux/types/IOferta";
import ListOferta from "./ModalOfertas";
import {TextValidatorCustom, ValidatorFormCustom} from "../../../helpers/form-validator";
import {getTokenInfo} from "../../../services/auth";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
       // paddingTop: theme.mixins.toolbar.minHeight,
        "& .topSearch":{
            background: theme.palette.grey[200],
          //  padding:`25px 0px 0px 8px`
            padding: "25px 10px 0px 10px",
            position: "sticky",
            top: "-152px",
            [theme.breakpoints.down('sm')]: {
                top: "-266px",
            }
        },
        "& .left":{
            height: "calc(100vh - 50px)",
            overflow: "auto",
        }
    },
    rightCont:{
        height: "25%",
        [theme.breakpoints.down('md')]: {
            position: "fixed",
            width: "100%",
            height: "131px",
            //height: "100%",
            bottom: 0,
            zIndex: 3000,
            transition: "height .4s",
            "&.opened":{
                height: "100%",
            }
        }
    },
    search:{
        "& input":{
            padding: theme.spacing(1),
            width: "100%",
            boxSizing: "border-box",
            marginBottom: 10,
            border: "1px solid #d4d4d4",
        }
    },
    itemsCont:{
    //    height: "calc(100vh - 263px)",
        background: theme.palette.common.white,
    },
    preVenta:{
        position:"relative",
        borderLeft: "1px solid " +theme.palette.grey[200],
        paddingTop: 14,
        height: "100%",
        background: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            paddingTop:0,
        }
    },
    tabs:{
        display:"flex",
        height: Number(theme.mixins.toolbar.minHeight) -13,
        background: theme.palette.grey[300],
        justifyContent:"space-between",
        "& ul":{
            display:"flex",
            padding:0,
            "& li":{
                borderTop:"2px solid " +theme.palette.grey[400],
                borderRight: "1px solid " +theme.palette.grey[400],
                "&:first-child":{
                    borderTop:"2px solid " +theme.palette.primary.main,
                    background: theme.palette.common.white,
                }
            }
        }

    },
    item:{
        cursor: "pointer",
        "&.libre":{
            background: "#fff9d9 !important",
        }
    }
}));
const initialState:ICompra = {
    id: undefined,
    pacienteId: undefined,
    paciente: "",
    farmaciaId: undefined,
    farmacia: "",
    planId: undefined,
    plan: "",
    comNota: "",
    comNumeroDoc: "",
    comAdjunto: "",
    comInactivo: false,
    detalle:[]
}

interface IPacArticulo {
        id: number,
        nombre: string,
        codigo: string,
        codigoBarra: string,
        comprimidos: number,
        indicadorVentaLibre: boolean,
        puntosCompra: number,
        puntosCanje: number,
}

export default function CrearCompra() {
    const classes = useStyles();
    const navigate = useNavigate();
    let { id:urlComId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const user = useAppSelector(state=>state.auth.user)
    const Dispatch = useAppDispatch()
    const [state, setState] =  React.useState(initialState);
    const [loading, setLoading] =  React.useState<boolean>(false);
    const [articulos, setArticulos] =  React.useState<IPacArticulo[]>();
    const [ofertas, setOfertas] =  React.useState<IOferta[]>();
    const artEnOfertas:IOfertaDetalle[] = ofertas?.reduce((a:IOfertaDetalle[],b)=> a.concat([...b.detalle]),[]) ?? []
    const [viewCart, setViewCart] =  React.useState(false);


    const [filterArticulos, setFilterArticulos] =  React.useState<IPacArticulo[]>();

    const [editingItem, setEditingItem] = React.useState<ICompraDetalle|null>(null);
    const {byId:compra,status} = useAppSelector(state => state.compras);

    const [filter, setFilter] = useState({name:'', categoriaId:''});


    useEffect(()=>{

        let filtered = articulos?.filter((p)=>
            noTilde(p.nombre).indexOf(noTilde(filter.name??"")) !== -1 ||
            noTilde(p.codigo).indexOf(noTilde(filter.name??"")) !== -1 ||
            noTilde(p.codigoBarra).indexOf(noTilde(filter.name??"")) !== -1
        )

        setFilterArticulos(filtered)
    },[filter,articulos,setFilterArticulos])

    useEffect(()=>{
        if(urlComId) Dispatch(mostrarCompraById(Number(urlComId)));
    },[urlComId,Dispatch])
    useEffect(()=>{
        if(!!urlComId && compra?.id === parseInt(urlComId)) {
            setState({...initialState, ...nullToEmptyString(compra)});
        }else{
            setState(initialState)
        }
    },[urlComId,compra])
    useEffect(()=>{
        if(state.pacienteId){
            setLoading(true)
            getPacienteById(state.pacienteId).then(({data})=>{
                setState(prevState => ({...prevState,planId:data.planId,plan:data.plan}))

                setLoading(true)
                getArticulosByPacienteId(state.pacienteId).then(({data})=>{
                    setArticulos(data)
                    setState(prevState => ({...prevState, detalle:[]}))
                }).finally(()=>setLoading(false))
            }).finally(()=>setLoading(false))
        }else{
            setArticulos([])
        }
    },[state.pacienteId,setArticulos,setState])

    useEffect(()=>{
        if(state.farmaciaId){
            setLoading(true)
            getOfertasDisponible(state.farmaciaId).then(({data})=>{
                setOfertas(data);
                setState(prevState => ({...prevState, detalle:prevState.detalle.filter(d=>!d.ofertaId)}))

            }).finally(()=>setLoading(false))
            setState(prevState => ({...prevState,detalle:prevState.detalle?.filter(d=>!d.ofertaId)??[]}))

        }else{
            setArticulos([])
        }
    },[state.farmaciaId,setOfertas, setState])

    useEffect(()=>{
        if(user?.usuTipo === TIPOUSUARIO.farmacia){
            setState(prevState => ({...prevState,farmaciaId:(getTokenInfo() as any)["RelatedId"],farmacia:user.usuNombre}))
        }else if(user?.usuTipo === TIPOUSUARIO.paciente){
            setState(prevState => ({...prevState,pacienteId:(getTokenInfo() as any)["RelatedId"], paciente:user.usuNombre}))
        }
    },[user, setState])



    const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFilter({...filter,[name]:value})
    };
    const onAddItem = async ()=>{
        let list = [...(state.detalle ?? [])];
        let item = {...editingItem, comCantidadDetalle: editingItem?.comCantidadDetalle || 0 }
        setEditingItem(null)

        if(item){

            if(list?.length){
                let found = list?.find(s=>(s.articuloId === item.articuloId && s.ofertaId === item.ofertaId));
                if(found) {
                    list = list.map(l => {
                        if (l.articuloId === item.articuloId && l.ofertaId === item.ofertaId)
                            return {...item, accion: item.accion === 3 ? 2 : item.accion};

                        return l
                    })
                    setState(prev=>({...prev, detalle: list}))

                }else{
                    setState(prev=>({...prev, detalle: [{...item},...list]}))
                }

            }else{
                setState(prev=>({...prev, detalle: [{...item}]}))
            }
        }


    }
    const deleteItem = () => {
        let list = [...(state.detalle ?? [])];
        let index =  list.findIndex(l=>l.articuloId===editingItem?.articuloId && l.ofertaId === editingItem?.ofertaId)


        if(index === -1) return false;

        if(list[index].accion === 1){
            list.splice(index,1)
        }else if(list[index].accion === 3){
            list.splice(index,1,{...list[index],accion:2})

        }else{
            list.splice(index,1,{...list[index],accion:3})
        }
        setEditingItem(null)
        setState(prev=>({...prev, detalle: list}))
    }

    const handleEditingItem = ({id:articuloId, nombre: articulo,puntosCompra: comPuntos, indicadorVentaLibre, comprimidos}:IPacArticulo)=>{
        let list = [...(state.detalle ?? [])];
        let found = list?.find(item=>item.articuloId === articuloId && !item.ofertaId);

        if(found){
            setEditingItem({...found,accion:(found.accion === 0 || found.accion === 3)?2:found.accion})
        }else{
            let item:ICompraDetalle = {
                accion: 1,
                articuloId,
                articulo,
                comPuntos,
                artComPuntos: comPuntos,
                artComprimidos: comprimidos,
                comCantidad: 1,
                comCantidadDetalle: 0,
                comIndicadorVentaLibre:indicadorVentaLibre
            }
            if(urlComId) item.compraId = parseInt(urlComId);

            setEditingItem(item)
        }
    }

    const [anchorElMenu, setAnchorElMenu] = React.useState<HTMLButtonElement|null>(null);
    const submit = () =>{
        let data = {...state};
        if(data.comAdjunto === compra?.comAdjunto)
            data.comAdjunto = "";

        Dispatch(agregarEditarCompra(data))
    }

    const calPuntos = (cantOrdenada:any, comprimidosOrdenados:any) => {

        let artComPuntos = editingItem?.artComPuntos ?? 0
        let artComprimidos = editingItem?.artComprimidos ?? 0


        let comCantidad = +cantOrdenada;
        let comCantidadDetalle = +comprimidosOrdenados;


        let comPuntos = editingItem?.comPuntos ?? 0;

        if(artComprimidos > 0){
            comCantidad = comCantidad + (comCantidadDetalle / artComprimidos)
        }

        comPuntos =  +(comCantidad * artComPuntos).toFixed(1);

        setEditingItem(prevState => ({
            ...prevState,
            comCantidad:cantOrdenada,
            comCantidadDetalle:comprimidosOrdenados,
            comPuntos}
        ))
    }
    let selectedArt = articulos?.find(a=>a.id === editingItem?.articuloId);
    return (
        <>
            <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.compra}>
                {!!urlComId &&
                    <>
                        <Button onClick={e=> setAnchorElMenu(e.currentTarget)}>
                            {Strings.opciones}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElMenu}
                            open={!!anchorElMenu}
                            onClose={e=> setAnchorElMenu(null)}
                            onClick={e=> setAnchorElMenu(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {!!urlComId &&
                                <MenuItem onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.comprasCrear)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}} >
                                    <ListItemIcon>
                                        <Close fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Eliminar compra</ListItemText>
                                </MenuItem>
                            }

                        </Menu>
                    </>}
            </AppBar>
            <div className={classes.root}>
                {(loading || status === "loading") && <LoadingProgress vh/>}
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8}>
                        <div className={"left"}>
                            <div className="topSearch">
                                <ValidatorFormCustom onSubmit={submit} id={"formCompra"}>
                                <Grid container spacing={2}>
                                    {user?.usuTipo !== TIPOUSUARIO.paciente &&
                                        <Grid item xs={12} sm={4}>
                                            <SelectSearchPaciente required
                                                                  label={"Cliente/Paciente:"}
                                                                  value={{
                                                                      id: state.pacienteId,
                                                                      pacNombres: state.paciente
                                                                  }}
                                                                  onChange={(v)=>setState(prevState => ({
                                                                      ...prevState,
                                                                      paciente: v.pacNombres??"",
                                                                      pacienteId:v.id,
                                                                  }))}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={4}>
                                        <SelectSearchFarmacia required //disabled={user?.usuTipo === 5}
                                            value={{
                                                farmaciaId: (state.farmaciaId ?? "").toString(),
                                                farmacia: state.farmacia ?? ""
                                            }}
                                            onChange={(v)=> setState(prev=>({...prev,
                                                farmacia: v.farmacia,
                                                farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextValidatorCustom
                                            name={"comNumeroDoc"}
                                            label={Strings.numeroFactura}
                                            value={state.comNumeroDoc}
                                            onChange={({target}:any)=>setState(prev=>({...prev,comNumeroDoc:target.value}))}
                                            inputProps={{maxLength: 20}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        {/*<Typography sx={{lineHeight: 1.2}}>Adjunto (imagen o pdf) *</Typography>*/}
                                        <SelectFiles showImg={false} src={state.comAdjunto??""} label={"Adjunto (imagen o pdf)"} required={true}
                                                     accept="image/*,.pdf"
                                                     onChange={(comAdjunto:string)=>setState(prevState => ({...prevState,comAdjunto}))}/>
                                        {/*<div style={{height:0,width:0, overflow:"hidden"}}>*/}
                                        {/*    <input value={state.comAdjunto??""} required onChange={()=>{}}/>*/}
                                        {/*</div>*/}
                                    </Grid>
                                    <Grid item xs={12} sm={user?.usuTipo === TIPOUSUARIO.paciente?12: 8}>
                                        <TextField
                                                   name={"comNota"}
                                                   label={Strings.comentario}
                                                   value={state.comNota}
                                                   onChange={({target})=>setState(prev=>({...prev,comNota:target.value}))}
                                                   inputProps={{maxLength: 100}}
                                                   fullWidth
                                        />
                                    </Grid>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <TopSearch onFilter={handleFilter} filter={filter}/>*/}
                                    {/*</Grid>*/}
                                </Grid>
                                </ValidatorFormCustom>
                                <Typography sx={{
                                    textAlign: "right",
                                    padding: "5px 0px 2px 0px",
                                    fontSize: "1em",
                                }}>
                                    {!!state.plan && <>{Strings.plan}: {state.plan}</>}
                                </Typography>
                                {!!articulos?.length &&
                                    <Box className={classes.search}>
                                        <input type="rearch" placeholder={Strings.buscar} name="name" value={filter.name} onChange={handleFilter}/>
                                    </Box>
                                }
                            </div>
                            <div className={classes.itemsCont}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{Strings.codigo}</TableCell>
                                                <TableCell>{Strings.nombre}</TableCell>
                                                <TableCell>{"Comprimidos"}</TableCell>
                                                <TableCell>Código de barra</TableCell>
                                                <TableCell>Puntos</TableCell>
                                                <TableCell>Venta libre</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filterArticulos?.map((art:IPacArticulo, index) =>
                                                <TableRow key={index} onClick={()=>handleEditingItem(art)} className={classes.item+(!art.indicadorVentaLibre?" libre":"")}>
                                                    <TableCell>{art.codigo}</TableCell>
                                                    <TableCell >
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <div>{art.nombre}</div>
                                                            {artEnOfertas.findIndex(o=>o.articuloId === art.id) !== -1 &&
                                                                <Chip label={Strings.oferta} size="small" variant={"outlined"} color="secondary" />
                                                            }
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{art.comprimidos}</TableCell>
                                                    <TableCell>{art.codigoBarra}</TableCell>
                                                    <TableCell>{art.puntosCompra}</TableCell>
                                                    <TableCell>{art.indicadorVentaLibre?"si":"no"}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        {!!ofertas?.length &&
                            <ListOferta list={ofertas} selected={state.detalle ?? []} onChage={(detalle)=>setState({...state,detalle})}/>
                        }
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.rightCont + " "+(viewCart?"opened":"")} onClick={()=>setViewCart(!viewCart)}>
                        {/*sx={{ display: { xs: 'none', md: 'block' } }}*/}
                        <div className={classes.preVenta}>
                            <ListPreVenta
                                list={state.detalle??[]}
                                onClick={setEditingItem}
                                listOfertas={artEnOfertas}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Dialog open={!!editingItem} onClose={()=>setEditingItem(null)} fullWidth maxWidth={"xs"}>
                <TopBarDialog onClose={() => setEditingItem(null)} nofullScreen
                              title={editingItem?.articulo?.replace(/(.{25})..+/, "$1…") ?? ""}/>
                <DialogContent>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                type={"number"}
                                label={Strings.cantidad}
                                value={editingItem?.comCantidad??""}
                                onChange={({target})=>{
                                    calPuntos(target.value, editingItem?.comCantidadDetalle)
                                }}
                                // onChange={({target})=>setEditingItem(prev=> {
                                //     let comCantidad = target.value ? parseInt(target.value) : undefined;
                                //
                                //     if((editingItem?.artComprimidos ?? 0) > 0){
                                //         comCantidad = (comCantidad ?? 0) + ((editingItem?.comCantidadDetalle ?? 0) / (editingItem?.artComprimidos ?? 0))
                                //     }
                                //
                                //     let comPuntos = (comCantidad ?? 0) * (prev?.comPuntos??0);
                                //
                                //
                                //    // let comPuntos = (comCantidad ?? 0) * ((prev?.comPuntos??0)/(prev?.comCantidad??0));
                                //
                                //     return {...prev!, comCantidad,  comPuntos: Number.isInteger(comPuntos) ? comPuntos:0}
                                // })}
                                required
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        {!!selectedArt?.comprimidos &&
                            <Grid item xs={12}>
                                <TextField
                                    type={"number"}
                                    label={"Comprimido"}
                                    inputProps={{min:0}}
                                    value={editingItem?.comCantidadDetalle || ""}
                                    onChange={({target})=>{
                                        calPuntos(editingItem?.comCantidad, target.value)
                                    }}

                                    // onChange={({target})=>setEditingItem(prev=>({...prev!,comCantidadDetalle:parseInt(target.value)}))}
                                    required
                                    fullWidth
                                    margin="dense"
                                />
                            </Grid>
                        }

                    </Grid>
                    <Typography>Puntos a generar: {editingItem?.comPuntos??0}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"}
                            disabled={state.detalle?.findIndex(l=>l.articuloId===editingItem?.articuloId) === -1}
                            onClick={deleteItem}>
                        {editingItem?.accion===3?Strings.agregar:Strings.eliminar}
                    </Button>
                    <Button onClick={onAddItem} disabled={!editingItem?.comCantidad && !editingItem?.comCantidadDetalle}>{Strings.guardar}</Button>
                </DialogActions>
            </Dialog>

            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarCompra(urlState.confirmDialog))}/>
            }
        </>
    )
}


