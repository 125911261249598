import * as React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import {Box, Button, Grid, List, ListItem, ListItemText, Theme, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Strings from "../../../assets/strings";
import {ICompraDetalle} from "../../../redux/types/ICompra";
import {IOfertaDetalle} from "../../../redux/types/IOferta";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        "& .top":{
            display:"flex",
            justifyContent: "space-between",
            background:theme.palette.primary.main,
            alignItems: "center",
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            "&>div":{
                "&:first-child":{
                    cursor:"pointer",
                    width:"79%"
                },
                "&:last-child":{
                    display:"flex"
                },
            },
            "& input":{
                color:theme.palette.secondary.main,
                border: "none",
                fontSize: "1.1em",
                outlineColor:theme.palette.grey[300],
                padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)}`,
                background: "transparent",
                "&:focus":{
                    padding: theme.spacing(0.5),
                    background:theme.palette.common.white
                }
            },
            "& div button":{
                marginLeft:theme.spacing(1),
            },
            [theme.breakpoints.down('md')]: {
                background:theme.palette.grey[500],
            }
        }
    },
    clienteCont:{
        borderBottom:"1px solid "+theme.palette.grey[300],
        display:"flex",
        justifyContent:"space-between",
        margin:theme.spacing(0.5),
        padding:theme.spacing(1),
        "& .inputCli":{
            width:"100%",
            marginRight:theme.spacing(1),
        },
        "& .btnNew":{
            padding: "0px 15px",
            fontSize: "0.8em",
            "& svg":{
                marginLeft:5
            }
        }
    },
    items:{
        //height: `calc(100vh - 130px)`,
        height: `calc(100vh - 104px)`,
        overflow: "auto",
    },
    total:{
        position:"absolute",
        bottom:0,
        width:"100%",
        background:theme.palette.grey[100],
        padding: 0,
    }
}));


type IProps = {
    list?:ICompraDetalle[],
    listOfertas?:IOfertaDetalle[],

    onClick:(detalle:ICompraDetalle)=>void
}

export default function ListPreVenta({list,listOfertas, onClick}:IProps) {
    const classes = useStyles();
    const renderItem = (articulo:  ICompraDetalle) => {
        let ofertaString =  "";

        if(articulo.ofertaId){
            let found = listOfertas?.find(o=>o.articuloId === articulo.articuloId);
            if(found){
                ofertaString = "+ "+Math.floor((articulo.comCantidad ?? 0) / (found.ofeCantidad ?? 0)) * (found.ofeCantidadOferta ?? 0)+" ("+Strings.oferta+")";
            }
        }

        return(
            <>
                <ListItem onClick={()=> {
                    if(!articulo.ofertaId)
                        onClick(articulo);
                }}>
                    <ListItemText
                        sx={articulo.accion===3?{textDecoration: "line-through",color: "#a91a1a"}:{}}
                        secondary={`${Strings.cantidad}: `+articulo.comCantidad+(!!articulo.comCantidadDetalle? ` / ${Strings.comprimidos}: `+articulo.comCantidadDetalle:" ")
                            +(!!articulo.comPuntos? ` / ${Strings.puntos}: `+articulo.comPuntos:"")
                            +(!!articulo.comIndicadorVentaLibre? " ("+Strings.ventaLibre+")":"")+ofertaString
                            +(articulo.comArticuloLote? " Lote: "+articulo.comArticuloLote:"")
                    }
                    >
                        {articulo.articulo?.replace(/(.{35})..+/, "$1…")}
                    </ListItemText>
                </ListItem>
                <Divider component="li" />
            </>
        )
    }
    return (
        <>
            <div className={classes.root}>
                <div className="top">
                    <div>
                        <Typography color={"common.white"} noWrap>{Strings.compra}</Typography>
                    </div>
                </div>
                <div className={classes.items}>
                    <List>
                        {list?.map((p,i)=>
                            <React.Fragment key={i}>
                                {renderItem(p)}
                            </React.Fragment>
                        )}
                    </List>
                </div>
                <div className={classes.total}>
                    <Grid container spacing={1}  padding={1} pb={2}>
                        <Grid item xs={12}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <div>Total articulos: {list?.length}</div>
                                <Typography color={"secondary"}>Puntos: {list?.reduce((pre, cur) => pre + (cur.comPuntos ?? 0), 0)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} onClick={(e)=>e.stopPropagation()}>
                            <Button fullWidth disabled={!list?.length} type={"submit"} form={"formCompra"} variant={"contained"}>Crear {Strings.compra}</Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}
