import React, {useState} from 'react';
import {IMaskInput} from 'react-imask';
import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterMoment';
// import DatePicker from '@mui/lab/DatePicker'
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';


//import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {Divider, MenuItem, NativeSelect, Select, useMediaQuery, useTheme} from "@mui/material";
import {formatPeso} from "../helpers";
//import moment from "moment";
import Strings from "../assets/strings";
import TextFieldPhone from "./TextFieldPhone";
import moment from "moment";
import {TextValidatorCustom} from "../helpers/form-validator";

// function TextMaskCustomCurrency({ maskOptions, ...inputProps }) {
//     const currencyMask = createNumberMask({
//         prefix: "",
//         includeThousandsSeparator: true,
//         allowDecimal: true,
//         decimalLimit: 4,
//         allowNegative: false,
//         allowLeadingZeroes: false,
//         ...maskOptions,
//     })
//
//     return <MaskedInput mask={currencyMask} {...inputProps} />
// }
export const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={"(#00) 000-0000"}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

//  {
//                         mask: /^\S*@?\S*$/
//                     }
export const IDMaskCustom = React.forwardRef(function IDMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={"000-0000000-0"}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
export function PhoneTextField({...props}){
    return (
        <TextValidatorCustom
            {...props}
            type={"tel"}
            InputProps={{
                inputComponent: TextMaskCustom,
            }}
        />

    )
}

export function CedulaTextField({noMasked=false, ...props}){
    return (
        <TextValidatorCustom
            {...props}
            {...(!noMasked?{
                InputProps:{inputComponent:IDMaskCustom}
            }:{})}
        />

    )
}

export function IDTextField({...props}){
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: IDMaskCustom,
            }}
        />

    )
}


export function TextFieldCurrency({min=null,value,...props}){
    //const { name, onChange, ...other } = props;
    const [isFocused,setIsFocused] = useState(false);
    return (
        <TextField
            {...props}
            value={isFocused?value:(value?formatPeso(value):"")}
            onFocus={()=>setIsFocused(true)}
            onBlur={()=>setIsFocused(false)}
            {...(isFocused?{
                type:"number",
                inputProps:{step:"any",inputMode:"decimal",min}
            }:{})}
        />

    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            decimalSeparator
            isNumericString
            prefix="$"
        />
    );
}
export function TextFieldCurrency2(props) {
    const { name, onChange, ...other } = props;
    return (
            <TextField
                {...other}
                {...((name && onChange) && {
                    onChange: ({target:{value}})=>props.onChange({target:{value: parseInt(value),name: props.name}})
                })}
                type={"tel"}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
    );
}
export function DateInput({name,validators, value, onChange,...other}) {

    return(
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                {...other}
                inputFormat="DD-MM-yyyy"
                value={value?moment(value):null}
                onChange={(val)=>onChange({target:{value:val ? val.format('YYYY-MM-DD'):"",name}})}
                renderInput={(params) => <TextValidatorCustom fullWidth value={value??""} validators={[...validators??[], "matchRegexp:^[0-9\\-:]"]} {...params} />}
            />
        </LocalizationProvider>
    )

    // if(smVewport || native)
    //     return (
    //         <ClickAwayListener onClickAway={()=>setOpenTp(false)}>
    //         <div>
    //             <HtmlTooltip
    //                 PopperProps={{
    //                     disablePortal: true,
    //                 }}
    //                 arrow
    //                 onClose={()=>setOpenTp(false)}
    //                 open={openTp}
    //                 disableFocusListener
    //                 disableHoverListener
    //                 disableTouchListener
    //                 title={Strings.inputQuincenalMsj }
    //             >
    //                 <div>
    //                     {/*{checkDateInput() ?(*/}
    //                     {/*    <TextField*/}
    //                     {/*        name={name}*/}
    //                     {/*        onChange={onChange}*/}
    //                     {/*        value={value ? formatDate(value,"YYYY-MM-DD"):""}*/}
    //                     {/*        placeholder={"YYYY-MM-DD"}*/}
    //                     {/*        {...other}*/}
    //                     {/*        type="date"*/}
    //                     {/*        fullWidth*/}
    //                     {/*        InputLabelProps={{*/}
    //                     {/*            shrink: true,*/}
    //                     {/*        }}*/}
    //                     {/*    />*/}
    //                     {/*):(*/}
    //                         <LocalizationProvider  dateAdapter={AdapterDateFns}>
    //                             <DatePicker
    //                                 autoOk
    //                                 format="DD MMM, YYYY"
    //                                 todayLabel={Strings.hoy}
    //                                 cancelLabel={Strings.cancelar}
    //                                 clearLabel={Strings.sinFecha}
    //                                 value={value?value:null}
    //                                 showTodayButton={!clearable}
    //
    //                                 {...{clearable,openTo,views}}
    //
    //                                 onChange={(value)=>onChange({target:{value:value && value.format('YYYY-MM-DD'),name}})}
    //                                 renderInput={(params) => <TextField {...params} {...other} fullWidth />}
    //
    //                             />
    //                             <input type="text" value={value} onChange={()=>{}} required={other.required} style={{height: "0", padding: "0", margin: "0 auto", width: "0", opacity: "0"}}/>
    //                         </LocalizationProvider>
    //                     {/*)}*/}
    //                 </div>
    //
    //             </HtmlTooltip>
    //         </div>
    //         </ClickAwayListener>
    //     )
    // else


}
export function SelectSmart({opciones, loading, value, ...props}) {
    const theme = useTheme();
    const smVewport = useMediaQuery(theme.breakpoints.down('sm'));

    let validValue = opciones?.find(o=>o.key.toString() === value.toString()) ? value : "";


    if(smVewport)
        return (
            <NativeSelect value={validValue} {...props}>
                {loading && <option value={validValue} disabled>{Strings.cargando}...</option>}
                {/*{all ? (*/}
                {/*    <option value="">Todos</option>) :(<option value="" disabled={props.required}>Seleccione</option>*/}
                {/*)}*/}
                {opciones.length > 0 && opciones.map(opt=> {
                    if(opt.key?.toString().indexOf("--") === 0){
                        return <option key={opt.key} value={opt.key} disabled>-------</option>
                    }
                    return <option key={opt.key} value={opt.key} disabled={opt.disabled}>{opt.value}</option>
                })}
            </NativeSelect>
        )
    else
        return (
            <>
            <Select displayEmpty value={validValue} {...props}>
                {loading && <MenuItem value={validValue} disabled>{Strings.cargando}...</MenuItem>}
                {/*{all ? (*/}
                {/*    <MenuItem value={opciones.length <= 0 ? value :""}>Todos</MenuItem>) :(*/}
                {/*    <MenuItem value={opciones.length <= 0 ? value :""} disabled={props.required}>Seleccione</MenuItem>*/}
                {/*)}*/}
                {opciones.length > 0 && opciones.map(opt=> {
                    if(opt.key?.toString().indexOf("--") === 0){
                        return <Divider key={opt.key}/>
                    }
                    return <MenuItem key={opt.key} value={opt.key} disabled={opt.disabled}>{opt.value}</MenuItem>
                })}
            </Select>
                <input type="text" value={validValue} onChange={()=>{}} required={props.required} style={{height: "0", padding: "0", margin: "0 auto", width: "0", opacity: "0"}}/>
            </>
        )


}

export {TextFieldPhone}

