import React from 'react';
import {Box, Paper, Typography} from "@mui/material";

export default function ClientListItem(props) {

    const { title, subTitle, Icon, color = "#d9534f" } = props;
    return (

        <Paper sx={{display:"flex",alignItems:"center",p:1}}>
            <Box sx={{p:1,mr:1,}}>
                <Icon sx={{fill:color,fontSize:"2.5rem",display: "flex"}}/>
            </Box>
            <Box>
               <Typography variant={"body2"} color={"text.disabled"}>{title}</Typography>
                {subTitle}
            </Box>
            <Box sx={{ml:"auto",mr:1}} color={"text.disabled"}>
                &#8594;
            </Box>
        </Paper>
    )
}
