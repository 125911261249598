import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getAutorizaciones, ApproveAutorizacion, RejectAutorizacion, getAutorizacionesNumber} from '../../services';
import {IAutorizacionState, IAutorizacion} from "../types/IAutorizacion";
import historyHelper from "../../helpers/history";
import {TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import {updateCompraRow} from "./comprasReducers";
import {ICompra} from "../types/ICompra";
import {AppState} from "./rootReducer";
import {updateFarmaciaRow} from "./farmaciaReducers";
import {updateIndicacionRow} from "./pacientesReducers";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
import {IIndicaciones} from "../types/IPacientes";
import {IFarmacia} from "../types/IFarmacia";
import {setAutorizacionNumber} from "./themeReducer";
import { updateCanjeRow } from './canjesReducers';
const initialState: IAutorizacionState = {
    status: "idle"
}

export const mostrarAutorizacionesNumber = createAsyncThunk(
    'mostrarAutorizacionesNumber', async (undefined,thunkAPI) => {
        const response = await getAutorizacionesNumber()
        if (response.status === 200 || response.status === 204) {
            thunkAPI.dispatch(setAutorizacionNumber(response.data))
        }
        return {data: response.data}
    }
)
export const mostrarAutorizaciones = createAsyncThunk(
    'mostrarAutorizaciones', async (params:object|undefined ) => {
        const response = await getAutorizaciones(params)
        return {data: response.data as IAutorizacion[], params: response.config.params}
    }
)
export const aprobarAutorizacion = createAsyncThunk(
    'autorizacion/aprobar', async (params:any, thunkAPI) => {
        const response = await ApproveAutorizacion(params);
        if (response.status === 200 || response.status === 204) {
            let data = response.data as IAutorizacion;
            let res = {id:data.autTransacionId, autorizacionEstado: 2,autorizacionEstadoString:"Aprobada", autorizadoPorNombre:data.actualizadoPorNombre};

            switch (data.autTipo) {
                case TIPO_AUTORIZACION.compra:
                    thunkAPI.dispatch(updateCompraRow(res as ICompra))
                    break
                case TIPO_AUTORIZACION.canje:
                    thunkAPI.dispatch(updateCanjeRow(res as ICompra))
                    break
                case TIPO_AUTORIZACION.creacionSucursal:
                    if((thunkAPI.getState() as AppState)?.farmacias?.byId){
                        thunkAPI.dispatch(updateFarmaciaRow(res as IFarmacia))
                    }
                    break
                case TIPO_AUTORIZACION.pacienteIndicacion:
                    if((thunkAPI.getState() as AppState)?.pacientes?.byId){
                        thunkAPI.dispatch(updateIndicacionRow(res as IIndicaciones))
                    }
                    break
            }

            thunkAPI.dispatch(mostrarAutorizacionesNumber())
            //callback message
            store.dispatch(Notifications.success({ title: 'Autorización aprobada correctamente.', autoDismiss: 4}));
            historyHelper.back();
        }
        return params;
    }
);
export const rechazarAutorizacion = createAsyncThunk(
    'autorizacion/rechazar', async (params:any, thunkAPI) => {
        const response = await RejectAutorizacion(params);
        if (response.status === 200 || response.status === 204) {
            let data = response.data as IAutorizacion;
            let res = {id:data.autTransacionId, autorizacionEstado: 3,autorizacionEstadoString:"Rechazada", autorizadoPorNombre:data.actualizadoPorNombre};
            switch (data.autTipo) {
                case TIPO_AUTORIZACION.compra:
                    thunkAPI.dispatch(updateCompraRow(res as ICompra))
                    break
                case TIPO_AUTORIZACION.canje:
                    thunkAPI.dispatch(updateCanjeRow(res as ICompra))
                    break
                case TIPO_AUTORIZACION.creacionSucursal:
                    if((thunkAPI.getState() as AppState)?.farmacias?.byId){
                        thunkAPI.dispatch(updateFarmaciaRow(res  as IFarmacia))
                    }
                    break
                case TIPO_AUTORIZACION.pacienteIndicacion:
                    if((thunkAPI.getState() as AppState)?.pacientes?.byId){
                        thunkAPI.dispatch(updateIndicacionRow(res  as IIndicaciones))
                    }
                    break
            }

            thunkAPI.dispatch(mostrarAutorizacionesNumber())
            //callback message
            store.dispatch(Notifications.warning({ title: 'Autorización rechasada.', autoDismiss: 4}));
            historyHelper.back();
        }
        return response.data;
    }
);
const slice = createSlice({
    name: 'autorizaciones',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarAutorizaciones
        builder.addCase(mostrarAutorizaciones.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAutorizaciones.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAutorizaciones.rejected, (state ) => {
            state.status = "idle";
        })

        //--aprobarAutorizacion
        builder.addCase(aprobarAutorizacion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(aprobarAutorizacion.fulfilled, (state,action ) => {
            state.status = "idle";
            const {id,value} = action.payload;
            state.list = state.list?.map(l=>(l.id === id?{...l,autEstado:2,autComentario:value}:l))
        })
        builder.addCase(aprobarAutorizacion.rejected, (state ) => {
            state.status = "idle";
        })
        //--rechazarAutorizacion
        builder.addCase(rechazarAutorizacion.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(rechazarAutorizacion.fulfilled, (state,action ) => {
            state.status = "idle";
            const data = action.payload;
            state.list = state.list?.map(l=>(l.id === data.id?data:l))

        })
        builder.addCase(rechazarAutorizacion.rejected, (state ) => {
            state.status = "idle";
        })
    }
});


export default slice.reducer;
