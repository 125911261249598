import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarMedicos} from "../../redux/reducers/medicosReducers";

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IMedico} from "../../redux/types/IMedico";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Medicos(){
  let navigate =useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.medicos)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarMedicos());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarMedicos({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:value})
  };
  const resetFilter = () => {
    setFilter(initialState);
    Dispatch(mostrarMedicos({}))
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarMedicos({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Medicos"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
    </AppBar>
    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((med) =>
                <React.Fragment key={med.id}>
                  <ListItem button onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.medicosActualizar)) return false;
                              navigate("/editar/medico/"+med.id)}}>
                    <ListItemText primary={`${med.medNombres} ${med.medApellidos}`} secondary={med.medNumLicencia}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"medNombres",text:Strings.nombres},
            {pro:"medNumLicencia",text:Strings.licencia},
            {pro:"medTelefono",text:Strings.telefono},
            {pro:"medTelefono2",text:Strings.celular},
            {pro:"cenDireccion",text:Strings.direccion},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((med:IMedico) =>
                  <TableRow key={med.id} onClick={()=> {
                    if(!hasPermissionTo(PERSMISOS.medicosActualizar)) return false;
                    navigate("/editar/medico/" + med.id)
                  }}>
                    <TableCell>{med.medNombres} {med.medApellidos}</TableCell>
                    <TableCell>{med.medNumLicencia}</TableCell>
                    <TableCell>{med.medTelefono}</TableCell>
                    <TableCell>{med.medTelefono2}</TableCell>
                    <TableCell>{med.medDireccion}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarMedicos({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.medicosCrear) &&
    <PlusFab component={Link} to="/crear/medico" />
    }

  </>;
}

export default Medicos;

