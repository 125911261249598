import React, {useEffect, useState} from 'react';
import {getPermisos} from '../../services';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Theme,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Strings from '../../assets/strings';
import {agregarEditarRol} from "../../redux/reducers/rolesReducers";
import {MostrarError} from "../../components/MostrarError";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {useLocation, useNavigate} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
//import {SlideLeft} from "../../components/transitions";
import StyledCheckbox from "../../components/StyledCheckbox";
import LoadingProgress from "../../components/LoadingProgress";
import {ExpandMore} from '@mui/icons-material';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IRol, IRolPermiso} from "../../redux/types/IRoles";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    cbRow:{
        borderBottom: "1px solid" +theme.palette.grey[200],
        "& label":{
                width: "100%",
        },
    },
    accordion: {
        margin: "0 !important",
        boxShadow: "none",
        borderBottom: "1px solid" +theme.palette.grey[200],
    },
    accordionDetails:{
        padding: 0,
        paddingBottom: 8,
    },
    accordionSummaryRoot: {
        minHeight:"0 !important",
        padding: 0,
    },

    accordionSummaryContent: {
            margin: "0 !important",
    }
}));
const initialState:IRol = {
        rolDescripcion: "",
};

function Crear() {
    const classes = useStyles();

    const { error, status } = useAppSelector(state => state.roles);
    const Dispatch = useAppDispatch();



    const navigate =useNavigate();
    const {state:urlState}:{state:any} = useLocation();

    const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('md'));
    const [state, setState] = useState(initialState);
    const [permisos, setPermisos] = useState<any[]>([]);


    useEffect(() => {
        getPermisos()
            .then(({data}) => {
                setPermisos(data);
            })
    }, []);
    useEffect(() => {
        if(!!urlState && urlState.id) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);
    const includePermiso = (e:any, arrayPer:number[],parentPerId?:number)=> {
         const {name, checked } = e.target;
         const perId  = parseInt(name);

         const mergePermisos = [perId,...arrayPer]
         let permisosSelected:IRolPermiso[] = [...(state.permisos??[])];



        if(checked){
            if(mergePermisos.length){
                mergePermisos.forEach(eID=>{
                    if(!permisosSelected.find(p=>p.permisoId === eID)){
                        permisosSelected.push({
                            id:0,
                            permisoId:eID,
                            accion:1
                        })
                    }else{
                        let foundIndex = permisosSelected.findIndex(p=>p.permisoId === eID);
                        permisosSelected[foundIndex].accion = 0
                    }
                })
            }

            if(parentPerId){
                if(!permisosSelected.find(p=>p.permisoId === parentPerId)){
                    permisosSelected.push({
                        id:0,
                        permisoId:parentPerId,
                        accion:1
                    })
                }else{
                    let foundIndex = permisosSelected.findIndex(p=>p.permisoId === parentPerId);
                    permisosSelected[foundIndex].accion = 0
                }
            }

        } else{

            if(mergePermisos.length){
                mergePermisos.forEach(item => {
                    permisosSelected.forEach((per,index)=>{
                        if(per.permisoId === item && per.id > 0){
                            permisosSelected[index].accion = 3
                        }else if(per.permisoId === item){
                            permisosSelected.splice(index, 1);
                        }
                    })

                })
            }
        }
         setState({ ...state, permisos:[...permisosSelected] });
    };
    const handleSubmit = () => {
        Dispatch(agregarEditarRol(state));
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))}
                    onClose={()=>navigate(-1)}
                    maxWidth={"md"}
                    fullScreen={smVewport}
                    fullWidth>
                {/*{...(smVewport ? {TransitionComponent: SlideLeft}:{})}*/}

                <TopBarDialog nofullScreen={false} title={!!state.id ? Strings.actualizar : Strings.agregar+" "+Strings.nuevo} onClose={()=>navigate(-1)}/>
                <DialogContent>
                    {error && <MostrarError errors={error}/>}
                    {status === "loading" && <LoadingProgress />}
                    <br/>
                    <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                        <Grid container spacing={1}>
                            <Grid  item xs={12}>
                                <TextValidatorCustom
                                    name={""}
                                    label={Strings.descripcion}
                                    value={state.rolDescripcion}
                                    onChange={(e:any)=>setState({ ...state, "rolDescripcion": e.target.value })}
                                    inputProps={{maxLength: 50}}
                                    validators={['required']}
                                    fullWidth
                                />
                            </Grid>
                            <Grid  item xs={12}>
                                {!!permisos.length && permisos.map(per =>
                                    (per.acciones && per.acciones.length > 0) ? (
                                        <Accordion key={per.permisoId} className={classes.accordion} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMore/>}
                                                                   classes={{
                                                                       root:classes.accordionSummaryRoot,
                                                                       content:classes.accordionSummaryContent}}>

                                                <FormControlLabel
                                                    checked={!!state.permisos?.find(p=>p.permisoId===per.permisoId && p.accion !== 3)}

                                                    //checked={state.permisos?.indexOf(per.permisoId) !== -1}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    onChange={(e)=>includePermiso(e,per.acciones.map((acc:any)=>acc.key))}
                                                    control={<Checkbox  name={per.permisoId.toString()}/>}
                                                    label={per.permiso}
                                                />

                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Grid container>
                                                    {per.acciones.map((acc:any)=>
                                                        <Grid item  xs={6} md={4} key={acc.key}>
                                                            <FormControlLabel
                                                                control={
                                                                    <StyledCheckbox
                                                                        checked={!!state.permisos?.find(p=>p.permisoId===acc.key && p.accion !== 3)}

                                                                        //checked={state.permisos?.indexOf(acc.key) !== -1}
                                                                        onChange={(e:any)=>includePermiso(e,[],per.permisoId)}
                                                                        color="primary"
                                                                        inputProps={{
                                                                            'aria-label': 'primary checkbox',
                                                                            name: acc.key.toString(), //+ '_' + acc.key,
                                                                        }}
                                                                    />
                                                                }
                                                                label={acc.value}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        ):(
                                            <div className={classes.cbRow} key={per.permisoId} >
                                                <FormControlLabel
                                                    checked={!!state.permisos?.find(p=>p.permisoId===per.permisoId && p.accion !== 3)}
                                                    onChange={(e)=>includePermiso(e,per.acciones.map((acc:any)=>acc.key))}
                                                    control={<Checkbox  name={per.permisoId.toString()}/>}
                                                    label={per.permiso}
                                                />
                                            </div>
                                        )
                                )}
                            </Grid>
                        </Grid>
                    </ValidatorFormCustom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={status === "loading"}>
                        {!!state.id ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default Crear;
