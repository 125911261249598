import React, {useEffect, useState} from 'react';
import {AttachFile,Check, Close} from "@mui/icons-material";
import {ShieldKeyOutline} from "mdi-material-ui";

import Strings from "../../assets/strings";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Theme,
    Typography
} from "@mui/material";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {IIndicaciones} from "../../redux/types/IPacientes";
import SelectSearchMedico from "../../components/selects/SelectSearchMedico";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import SelectFiles from "../../components/selects/SelectFiles";
import SelectSearchCentroMedico from "../../components/selects/SelectSearchCentroMedico";
import {addEditPacientesIndicaciones, delPacientesIndicaciones, getMedicoById} from "../../services";
import {IEspecialidad, IMedico} from "../../redux/types/IMedico";
import SelectList from "../../components/selects/SelectList";
import {HtmlTooltip, LoadingProgress} from "../../components";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchArticuloByPacienteId from "../../components/selects/SelectSearchArticuloByPlanId";
import AutorizacionDetalle from "../Autorizaciones/AutorizacionDetalle";
import {ESTADO_AUTORIZACION, getValueString, PERSMISOS, TIPO_AUTORIZACION, TIPOUSUARIO} from "../../helpers/CONSTANTES";
import {getUsuTipo, hasPermissionTo} from "../../helpers";
import {store} from "../../redux/store";
import Notifications from "react-notification-system-redux";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(1),
        "& tr.aprobada":{
            background: "#eff8ff !important"
        },
        "& tr.rechazada":{
            background: "#fff3f3 !important"
        },
    }
}));
let initialState:IIndicaciones={
    accion: 1,
    pacienteId: undefined,
    articuloId: undefined,

    centroMedicoId: undefined,
    centroMedico: "",
    especialidadId: undefined,
    especialidad: "",
    medNumLicencia: "",



    articulo: "",
    pacIndicacion: "",
    medicoId: undefined,
    medico: "",
    pacCicloUso: undefined,
    pacCicloUsoString: "",
    autorizadoPorNombre: "",
};
type Iprops = {
    indicaciones?:IIndicaciones[],
    onChange: (vars:IIndicaciones[])=>void,
    planId?:number,
    viewOnly?:boolean
}
export default  function Indicaciones({indicaciones, onChange,viewOnly,planId}:Iprops){
    const classes = useStyles();

    let { id:IndId} = useParams()
    let navigate =useNavigate();
    const {pathname} = useLocation();

    if(IndId) initialState.pacienteId = parseInt(IndId);
    const [loading, setLoading] = useState(false);
    const [openImg, setOpenImg] = useState("");

    const [state, setState] = useState(initialState);
    const [error, setError] = useState<string>("");
    const [especialidad, setEspecialidad] = useState<IEspecialidad[]>([]);


    useEffect(()=>{
        if(state.medicoId){
            getMedicoById(state.medicoId).then(({data})=>{
                let medico = data as IMedico;
                setEspecialidad(medico.especialidades)
                setState(prevState => ({
                    ...prevState,
                    medNumLicencia: medico.medNumLicencia,
                    especialidadId:medico.especialidades?.length === 1 ? medico.especialidades[0].especialidadId :undefined,
                    especialidad:medico.especialidades?.length === 1 ? medico.especialidades[0].especialidad: "",
                }))
            })
        }else{
            setEspecialidad([])
        }
    },[state.medicoId,setEspecialidad,setState])
    const [openNewVar, setOpenNewVar] = useState(false);

    const deleteDet = (varIndex:number) =>{
        let vars = [...(indicaciones ?? [])];
        let itemToDelete = {...vars[varIndex]};

        if(viewOnly){
            if (window.confirm("Estas seguro de querer eliminar esta indicación?") === true) {
                setLoading(true)
                delPacientesIndicaciones(vars[varIndex].id).then(()=>{
                    vars.splice(varIndex, 1);
                    onChange(vars);
                }).finally(()=>setLoading(false))
            }
        }else{
            if(itemToDelete.accion === 0){
                itemToDelete.accion = 3;
                vars.splice(varIndex, 1,itemToDelete);
            }else if(itemToDelete.accion === 3){
                itemToDelete.accion = 0;
                vars.splice(varIndex, 1,itemToDelete);
            }else{
                vars.splice(varIndex, 1);
            }
            onChange(vars);
        }

    };
    const addDetalle = () =>{
        setError("");
        let vars = [...(indicaciones ?? [])];

        if(state.centroMedicoId && state.pacIndicacion){
            if(viewOnly){
                setLoading(true)
                addEditPacientesIndicaciones(state).then(({data})=>{
                    if(state.id){
                        let varIndex = vars.findIndex((i=>i.id === state.id));
                        vars.splice(varIndex, 1, state);
                    }else{
                        vars.unshift(state);
                    }
                    onChange(vars);
                    closeDialog()
                    setState(initialState)

                    store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Esta indicación esta pendiente a autorización por un administrador.", autoDismiss: 8,
                        action: {
                            label: 'ok',
                            callback: () => Notifications.removeAll()
                        }
                    }));

                }).finally(()=>setLoading(false))
            }else{
                if(state.id){
                    let varIndex = vars.findIndex((i=>i.id === state.id));
                    vars.splice(varIndex, 1,state);
                }else{
                    vars.unshift(state);
                }
                onChange(vars);
                closeDialog()
                setState(initialState)
            }
        }else {
            setError(Strings.completeCamposRequeridosMsj)
        }
    };
    const closeDialog = ()=>{
        setState(initialState)
        setOpenNewVar(false)
    }
    const viewFileClick = (src?:string) =>{
        if(src?.indexOf("pdf") !== -1) return src?.indexOf("base64") === -1 ? window.open(encodeURI(src??"")) : false;
        if(src) setOpenImg(src);
    }
    return <>
        <div className={classes.root}>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.indicaciones}
                </Typography>

                <Box display='flex' alignItems="center">
                    <Button size="small" color={"primary"} onClick={(e)=> {
                        e.stopPropagation()
                        setOpenNewVar(!openNewVar)
                    }}>
                        {Strings.agregar}
                    </Button>
                </Box>

            </Box>
            {loading && <LoadingProgress/>}
            {!!indicaciones?.length &&
                <TableContainer>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.articulo}</TableCell>
                                <TableCell>{Strings.medico}</TableCell>
                                <TableCell>{Strings.licencia}</TableCell>
                                <TableCell>{Strings.dosis}</TableCell>
                                <TableCell>{Strings.estado}</TableCell>
                                <TableCell>{Strings.autorizadoPor}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {indicaciones.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow sx={{textDecoration:item.accion===3?"line-through":""}}
                                                  className={getValueString(ESTADO_AUTORIZACION,item?.autorizacionEstado)}
                                                  onClick={()=> {
                                                        if(item.accion===3 || item.accion===1 || getUsuTipo() !== TIPOUSUARIO.admin) return false;
                                                        setState(item);
                                                        setOpenNewVar(true);
                                                    }
                                        }>
                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                <HtmlTooltip title={item.accion===3?Strings.eliminar:"Deshacer"}>
                                                    <IconButton onClick={()=> {
                                                        if(item.pacInactivo) return false;
                                                        deleteDet(i)
                                                    }} size="large">
                                                        {item.accion===3?(
                                                            <Check className="text-blue"/>
                                                        ):(
                                                            <Close className={item.pacInactivo? "text-gray" :"text-red"}/>
                                                        )}
                                                    </IconButton>
                                                </HtmlTooltip>
                                                <HtmlTooltip title={Strings.ver+" "+Strings.adjunto}>
                                                    <IconButton  onClick={()=>viewFileClick(item.pacIndicacion)}  size={"small"}>
                                                        <AttachFile fontSize={"small"} className="text-blue"/>
                                                    </IconButton>
                                                </HtmlTooltip>
                                                {item.autorizacionEstado === 1 && hasPermissionTo(PERSMISOS.autorizaciones) &&
                                                    <HtmlTooltip title={Strings.autorizacion}>
                                                        <IconButton  onClick={()=> navigate(pathname, {state: {autdetalle: {autEstado:1, id:item.autorizacionId,autTipo:TIPO_AUTORIZACION.pacienteIndicacion,autTransacionId:item.id}}})}  size={"small"}>
                                                            <ShieldKeyOutline fontSize={"small"} className="text-blue"/>
                                                        </IconButton>
                                                    </HtmlTooltip>
                                                }
                                            </TableCell>
                                            <TableCell>{item.articulo}</TableCell>
                                            <TableCell>{item.medico}</TableCell>
                                            <TableCell>{item.medNumLicencia}</TableCell>
                                            <TableCell>{item.pacCicloUsoString}</TableCell>
                                            <TableCell>{item.autorizacionEstadoString}</TableCell>
                                            <TableCell>{item.autorizadoPorNombre}</TableCell>
                                        </TableRow>
                                    </React.Fragment>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="sm" onClose={closeDialog} open={!!openNewVar}>
            <TopBarDialog title={(state.id?Strings.actualizar:Strings.agregar)+" "+Strings.indicaciones} onClose={closeDialog} nofullScreen/>
            <DialogContent>
                {error && <Alert severity="warning" sx={{mb:1}}>{error}</Alert>}
                {loading && <LoadingProgress/>}
                <ValidatorFormCustom id="formInd" onSubmit={addDetalle} autoComplete={"off"}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={6} sx={{mb:2}}>
                            <SelectSearchArticuloByPacienteId
                                required
                                planId={planId}
                                value={{
                                    id: state.articuloId,
                                    nombre: state.articulo ?? ""
                                }}
                                onChange={(v)=> {
                                    setState(prevState => ({
                                        ...prevState,
                                        articulo: v.nombre,
                                        articuloId: v.id
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:2}}>
                            <SelectSearchCentroMedico
                                required
                                value={{
                                    centroMedicoId: (state.centroMedicoId ?? "").toString(),
                                    centroMedico: state.centroMedico ?? ""
                                }}
                                onChange={(v)=>setState(prevState => ({
                                    ...prevState,
                                    centroMedico:v.centroMedico,
                                    centroMedicoId:v.centroMedicoId?parseInt(v.centroMedicoId):undefined
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:2}}>
                            <SelectSearchMedico
                                required
                                value={{
                                    medicoId: (state.medicoId ?? "").toString(),
                                    medico: state.medico ?? ""
                                }}
                                onChange={(v)=>setState(prevState => ({
                                    ...prevState,
                                    medico:v.medico,
                                    medicoId:v.medicoId?parseInt(v.medicoId):undefined
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb:2}}>
                            <SelectList
                                required
                                //loading={loading}
                                label={Strings.especialidad}
                                list={especialidad?.map(e=>({id:e.especialidadId!,name:e.especialidad!})) ?? []}
                                disabled={!especialidad.length}
                                value={{
                                    id: state.especialidadId ?? undefined,
                                    name: state.especialidad ?? ""
                                }}
                                onChange={(v)=>
                                    setState(pre=>({...pre,
                                        especialidad:v.name,
                                        especialidadId:v.id,
                                    }))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mb: 2}}>
                            <TextField name={"medNumLicencia"}
                                       disabled
                                       label={"Licencia"} value={state.medNumLicencia}
                                       onChange={({target})=>setState(pre =>({...pre,medNumLicencia:target.value}) )}
                                       inputProps={{maxLength: 20}}
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectUsoMultiples
                                label={Strings.dosis}
                                value={state.pacCicloUso?.toString() ?? ""}
                                onChange={(v)=>setState(prevState => ({
                                    ...prevState,
                                    pacCicloUso:v.key,
                                    pacCicloUsoString:v.value
                                }))}
                                group={"IndicacionesCiclosUso"}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{mt:1}}>
                            <SelectFiles accept="image/*,.pdf" showImg={false} src={state.pacIndicacion ? state.pacIndicacion : ""}
                                         required={!state.pacIndicacion} label={Strings.indicacion+" (imagen o pdf)"}
                                         onChange={(base64:string)=>setState({ ...state, pacIndicacion: base64 } )}/>
                        </Grid>
                    </Grid>
                </ValidatorFormCustom>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">{Strings.cancelar}</Button>
                <Button type={"submit"} form={"formInd"} color="primary">{Strings.guardar}</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={!!openImg}
            onClose={()=>setOpenImg("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <TopBarDialog title={"Adjunto"} nofullScreen onClose={()=>setOpenImg("")}/>

            <DialogContent  sx={{p:0}}>
                <img src={openImg} alt="adjunto" style={{width:"100%"}}/>
            </DialogContent>
        </Dialog>
        {hasPermissionTo(PERSMISOS.autorizaciones)  &&
          <AutorizacionDetalle />
        }

    </>;
}
