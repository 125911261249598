import React, {useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {Check, Close} from "@mui/icons-material";
import Strings from "../../assets/strings";
import Typography from "@mui/material/Typography";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {IOferta, IOfertaDetalle} from "../../redux/types/IOferta";
import SelectSearchArticulo from "../../components/selects/SelectSearchArticulo";
import {useParams} from "react-router-dom";
import {formatDate} from "../../helpers";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import {DateInput} from "../../components/CustomTextField";
import _ from "lodash";

let initialState:IOfertaDetalle={
    accion: 1,
    articuloId: undefined,
    articulo:"",
    ofeLote: "",
    ofeFechaVencimiento:"",
    ofeCantidad: undefined,
    ofeCantidadOferta: undefined,
};
type Iprops = {
    ofertaState?:IOferta,
    onChange: (vars:IOfertaDetalle[])=>void
}

export default  function OfertaDetalles({ofertaState, onChange}:Iprops){
    let { id:ofeId} = useParams()
    if(ofeId) initialState.ofertaId = parseInt(ofeId);

    const [state, setState] = useState(initialState);
    const [error, setError] = useState<string>("");


    const [openNewVar, setOpenNewVar] = useState(false);
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const deleteDet = (varIndex:number) =>{
        let vars = [...(ofertaState?.detalle ?? [])];
        let itemToDelete = {...vars[varIndex]};

        if(itemToDelete.accion === 0){
            itemToDelete.accion = 3;
            vars.splice(varIndex, 1,itemToDelete);
        }else if(itemToDelete.accion === 3){
            itemToDelete.accion = 0;
            vars.splice(varIndex, 1,itemToDelete);
        }else{
            vars.splice(varIndex, 1);
        }
        onChange(vars);
    };
    const addDetalle = ()=>{
        setError("");
        let vars = [...(ofertaState?.detalle ?? [])];
        let datos = {..._.omitBy(state, (v) => v === '' || _.isNil(v))};

            if(datos.id){
                let varIndex = vars.findIndex((i=>i.id === datos.id));
                datos.accion = 2; //si tiene un id, se esta editando.
                vars.splice(varIndex, 1,datos);
            }else{
                vars.unshift(datos);
            }
            onChange(vars);
            closeDialog()
            setState(initialState)
    };
    const closeDialog = ()=>{
        setState(initialState)
        setOpenNewVar(false)
    }
    return <>
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.detalles}
                </Typography>
                <Button color={"primary"} disabled={!ofertaState?.ofeTipo} onClick={()=>setOpenNewVar(!openNewVar)}>
                    {Strings.agregar}
                </Button>
            </Box>
            {!!ofertaState?.detalle?.length &&
                <TableContainer>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.articulo}</TableCell>
                                <TableCell>Lote</TableCell>
                                <TableCell>{Strings.cantidad}</TableCell>
                                <TableCell>{Strings.cantidad} {Strings.oferta}</TableCell>
                                <TableCell>{Strings.fechaVencimiento}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ofertaState?.detalle.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow sx={{textDecoration:item.accion===3?"line-through":""}} onClick={()=> {
                                            if(item.accion===3 || item.accion===1) return false;
                                            setState(item);
                                            setOpenNewVar(true);
                                        }}>
                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                <IconButton onClick={()=>deleteDet(i)} size="large">
                                                    {item.accion===3?(
                                                        <Check className="text-blue"/>
                                                    ):(
                                                        <Close className="text-red"/>
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{item.articulo}</TableCell>
                                            <TableCell>{item.ofeLote}</TableCell>
                                            <TableCell>{item.ofeCantidad}</TableCell>
                                            <TableCell>{item.ofeCantidadOferta}</TableCell>
                                            <TableCell>{formatDate(item.ofeFechaVencimiento)}</TableCell>
                                        </TableRow>
                                    </React.Fragment>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="xs" onClose={closeDialog} open={!!openNewVar}>
            <TopBarDialog title={(state.id?Strings.actualizar:Strings.agregar)+" "+Strings.detalles} onClose={closeDialog} nofullScreen/>
            <Box padding={"5px 12px"} bgcolor={"#ececec"}><Typography color={"secondary"} variant={"body2"}>{Strings.tipo}: {ofertaState?.ofeTipoString}</Typography></Box>

            <DialogContent>
                {error && <Alert severity="warning">{error}</Alert>}
                <ValidatorFormCustom id={"ofertaDetalleForm"} onSubmit={addDetalle}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectSearchArticulo
                                required
                                value={{
                                    articuloId: (state.articuloId ?? "").toString(),
                                    articulo: state.articulo ?? ""
                                }}
                                onChange={(v)=>setState(prevState => ({
                                    ...prevState,
                                    articulo:v.articulo,
                                    articuloId:v.articuloId?parseInt(v.articuloId):undefined
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidatorCustom
                                type={"number"}
                                name={"ofeCantidad"}
                                label={"Al comprar"}
                                value={state.ofeCantidad??""}
                                onChange={handleChange}
                                inputProps={{min: 0}}
                                validators={['required']}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidatorCustom
                                type={"number"}
                                name="ofeCantidadOferta"
                                label={"Recibe adicional"}
                                value={state.ofeCantidadOferta??""}
                                onChange={handleChange}
                                inputProps={{min: 0}}
                                validators={['required']}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        {ofertaState?.ofeTipo === 1 && <>
                            <Grid item xs={12}>
                                <TextValidatorCustom
                                    name="ofeLote"
                                    label={"Lote"}
                                    value={state.ofeLote}
                                    onChange={handleChange}
                                    fullWidth
                                    validators={['required']}
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/*// @ts-ignore*/}
                                <DateInput
                                    name="ofeFechaVencimiento"
                                    label={Strings.fechaVencimiento}
                                    value={state.ofeFechaVencimiento}
                                    onChange={handleChange}
                                    validators={['required']}
                                />
                            </Grid>
                        </>}
                    </Grid>
                </ValidatorFormCustom>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">{Strings.cancelar}</Button>
                <Button color="primary" type={"submit"} form={"ofertaDetalleForm"} >{Strings.guardar}</Button>
            </DialogActions>
        </Dialog>
    </>;
}
