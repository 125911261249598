import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getUsosMultiples, addEditUsosMultiple, delUsosMultiple} from '../../services';
import {IUsoMultipleState, IUsoMultipleRes, IUsoMultiple} from "../types/IUsosMultiples";
import historyHelper from "../../helpers/history";
const initialState: IUsoMultipleState = {
    status: "idle"
}

export const mostrarUsosMultiples = createAsyncThunk(
    'mostrarUsosMultiples', async (params:object|undefined ) => {
        const response = await getUsosMultiples(params)
        return {data: response.data as IUsoMultipleRes, params: response.config.params}
    }
)

export const agregarEditarUsoMultiple = createAsyncThunk(
    'usoMultiples/agregarEditar', async (usoMultiple: IUsoMultiple) => {
        const response = await addEditUsosMultiple(usoMultiple);
        if (response.status === 200) historyHelper.back();
        return {data: response.data, edit:!!usoMultiple.id};
    }
);
export const eliminarUsosMultiples = createAsyncThunk(
    'usoMultiples/eliminar', async (usoMultiple: IUsoMultiple) => {
        await delUsosMultiple(usoMultiple.id);
        return usoMultiple;
    }
);


const slice = createSlice({
    name: 'mostrarUsosMultiples',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //---mostrarUsosMultiples
        builder.addCase(mostrarUsosMultiples.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarUsosMultiples.fulfilled, (state,action ) => {

            const { data } = action.payload
            let list:any = {};
            if (data.results.length) {
                let usoGrupo = new Set(data.results.map(g=>g.usoGrupo))
                usoGrupo.forEach((g?:string)=>{
                    if(g) list[g] = data.results.filter(item=>item.usoGrupo === g)
                })
            }

            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = list;

        })
        builder.addCase(mostrarUsosMultiples.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarUsoMultiple
        builder.addCase(agregarEditarUsoMultiple.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarUsoMultiple.fulfilled, (state,action ) => {

            const { data } = action.payload;
            let list:any = {...state.list};
            let listFiltered = (state.list as any)[data.usoGrupo]?.filter((e:IUsoMultiple) => e.id !== data.id) ?? [];
            listFiltered.unshift(data)
            list[data.usoGrupo] = listFiltered;


            state.status = "idle";
            state.list = list;

        })
        builder.addCase(agregarEditarUsoMultiple.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarUsoMultipleCategoria
        builder.addCase(eliminarUsosMultiples.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarUsosMultiples.fulfilled, (state,action ) => {
            const { id,usoGrupo } = action.payload;
            let list:any = {...state.list};

            if(usoGrupo){
                let listFiltered = (state.list as any)[usoGrupo]?.filter((e:IUsoMultiple) => e.id !== id) ?? [];

                list[usoGrupo] = listFiltered;
                state.list = list;

            }


            state.status = "idle";


        })
        builder.addCase(eliminarUsosMultiples.rejected, (state ) => {
            state.status = "idle";
        })
    }

});


export default slice.reducer;