import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarFarmacias} from "../../redux/reducers/farmaciaReducers";

import {
  Button,
  Dialog, DialogActions, DialogContent,
  Divider, FormControl, Grid, InputLabel,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  ButtonResponsive,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IFarmacia} from "../../redux/types/IFarmacia";
import {FilterList} from "@mui/icons-material";
import {SelectSmart} from "../../components/CustomTextField";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  estado:"",
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Farmacias(){
  let navigate =useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.farmacias)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarFarmacias());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarFarmacias({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarFarmacias({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarFarmacias(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarFarmacias({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Farmacias"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.estado}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.estado ?? ""}
                  onChange={handleFilter}
                  name="estado"
                  opciones={[
                    {key: '', value: Strings.todo},
                    {key: '1', value: Strings.activo},
                    {key: '2', value: Strings.pendiente},
                    {key: '3', value: Strings.rechazada},
                    {key: '4', value: Strings.inactivo},
                  ]}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((far) =>
                <React.Fragment key={far.id}>
                  <ListItem button onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.farmaciasActualizar)) return false;
                              navigate("/detalles/farmacia/"+far.id)}}>
                    <ListItemText primary={`${far.farNombre}`} secondary={far.farCodigo}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"farCodigo",text:Strings.codigo},
            {pro:"farNombre",text:Strings.nombre},
            {pro:"farDireccion",text:Strings.direccion},
            {pro:"farTelefono",text:Strings.telefono},
            {pro:"farTelefono2",text:Strings.celular},
            {pro:"farEmail",text:Strings.email},
            {pro:"farInactiva",text:Strings.estado},
            {pro:"autorizadoPorNombre",text:Strings.autorizadoPor},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((far:IFarmacia) =>
                  <TableRow key={far.id} onClick={()=>navigate("/detalles/farmacia/"+far.id)}>
                    <TableCell>{far.farCodigo}</TableCell>
                    <TableCell>{far.farNombre}</TableCell>
                    <TableCell>{far.farDireccion}, {far.farDireccion2}</TableCell>
                    <TableCell>{far.farTelefono}</TableCell>
                    <TableCell>{far.farTelefono2}</TableCell>
                    <TableCell>{far.farEmail}</TableCell>
                    <TableCell>{far.farInactiva?Strings.inactivo:Strings.activo}</TableCell>
                    <TableCell>{far.autorizadoPorNombre}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarFarmacias({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.farmaciasCrear) &&
      <PlusFab component={Link} to="/crear/farmacia" />
    }

  </>;
}

export default Farmacias;

