import React, { useEffect } from 'react';
import {MenuItem} from "@mui/material";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {mostrarArticulosMarcas} from "../../redux/reducers/articulosMarcaReducers";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";


export default function SelectM({ value = "", required=false, name = "marcaId",label="",disabled=false, onChange, all=false}) {
    const Dispatch = useAppDispatch();

    const { status} = useAppSelector(state => state.articulosMarcas)
    const articulosMarcas = useAppSelector(state => state.articulosMarcas.list?.results)


    useEffect((e) => {
        if(!hasPermissionTo(PERSMISOS.marcas)) return false
        Dispatch(mostrarArticulosMarcas());
    }, [Dispatch]);


    var options = (articulosMarcas && articulosMarcas.map(opc=>({key:opc.id,value:opc.marNombre}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})
    const validators=required?['required']:[]
    return (
            <SelectValidatorCustom
                {...{value, validators,disabled:(status === "loading" || disabled),loading: status === "loading", errorMessages:errorMessagesC(validators), name, label, onChange}}
            >
                {options.map(o=>
                    <MenuItem key={o.key} value={o.key}>{o.value}</MenuItem>
                )}
            </SelectValidatorCustom>
    );
}
