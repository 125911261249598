import React from 'react';
import {Box, Button, Theme,Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
//import DetallesSolicitud from "../../../views/solicitud/DetallesSolicitud";
//import {setCurrentUser} from "../../../redux/actions/authentication";
//import {setGenerales as setGlobalGenerales} from "../../../redux/actions/generalesActions";
import CrearPaciente from "../../Pacientes/crearPaciente";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import healthcare from "../../../assets/img/healthcare.png";
import family from "../../../assets/img/family.png";
import Notifications from 'react-notification-system-redux';
import { useAppSelector } from '../../../redux/hooks';
import {ArrowBack} from '@mui/icons-material';
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        boxShadow: "#5d5d5d 0px 0px 12px",
        maxWidth: 990,
        margin: "-110px auto 30px auto",
        boxSizing: "border-box",
        background:"#fff",
        [theme.breakpoints.down('md')]: {
            marginTop:-71,
            "& h1":{
                marginTop: "-64px !important",
                color: "white  !important",
                marginBottom: "39px !important",
            }
        }


    },
    topHead: {
        position:"relative",
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "103px",
        alignItems: "center",
        padding: theme.spacing(2),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.5rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.4rem",
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: 99,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".9rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            height: "auto",
            "& .info":{
                flexDirection: "column-reverse",
                textAlign: "center",
            },
            "& h1":{
                textAlign: "center",
            }
        }
    },
    lang:{
        textAlign: "center",
        marginBottom: theme.spacing(3),
        "& select":{
            padding: 5,
            fontSize: "1em",
        }
    },
    webview:{
        boxShadow:"none",
        margin:0,
        padding:0,
        "&>div":{
            padding:theme.spacing(1),
        }
    },
    typeCont:{
        paddingTop: theme.spacing(2),
        padding: theme.spacing(1),
        textAlign:"center",
        "&>p":{
            color:theme.palette.secondary.main,
        },
        "&>div":{
            padding: "29px 12px",
            display: "flex",
            width: 250,
            justifyContent: "space-between",
            margin: "0 auto",
            "& .btn":{
                width: 64,
                padding: "10px 15px 0px 15px",
                background: "#efefef",
                border: "1px solid #c7c7c7",
                cursor:"pointer",
                transition: "all .5s",
                "&>p":{
                    fontSize:"1em",
                },
                "& img":{
                    width: "100%"
                },
                "&:hover":{
                    border: "1px solid "+theme.palette.secondary.main,
                }
            }
        }
    }
}));
const style = {
    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            margin: '10px 5px 2px 1px',
            borderRadius: '0px',
            boxShadow:"none",
        },
    },
    Dismiss: {
        DefaultStyle: {
            backgroundColor: "none",
            color: '#bbbbbb',
        },
    }
};
let generales = {
    genEmpresaNombre:"",
    genEmpresaRNC:"",
    genEmpresaTelefono:"",
    genEmpresaTelefono2:"",
    genEmpresaDireccion:"",
    genEmpresaLogo:""
};
function PlublicPacienteForm(){
    let navigate =useNavigate();
    const notificaciones = useAppSelector(state=>state.notifications);
    const {pathname}:{state:any,pathname:string} = useLocation();

    const classes = useStyles();
    let {tipo} = useParams()

    return(
        <>
            <Box bgcolor="primary.main" width={"100%"} height={138} />
            <div className={classes.root}>
                <>
                    <div className={classes.topHead}>
                        <Button onClick={()=>navigate("/login")}
                        sx={{
                            position: "absolute",
                            top: 5,
                            left: 9,
                        }}
                        startIcon={<ArrowBack/>}>Iniciar sesión</Button>
                        <h1>Formulario de Registro</h1>
                        <div className="info">
                            <div>
                                <h2>{generales?.genEmpresaNombre}</h2>
                                <span className="tel">
                                            <i>
                                                {!!generales?.genEmpresaTelefono && <span> Tel: {generales?.genEmpresaTelefono}</span>}
                                                {!!generales?.genEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales?.genEmpresaTelefono2}</span>}
                                            </i>
                                        </span>
                                <br/>
                                <span className="dir">{generales?.genEmpresaDireccion}</span>
                            </div>
                            {!!generales?.genEmpresaLogo &&
                                <div className="logo">
                                    <img alt="logo" src={generales?.genEmpresaLogo} />
                                </div>
                            }
                        </div>
                    </div>
                </>
                {!tipo && <div className={classes.typeCont}>
                    <Typography>¿CÓMO QUIERES SER RECONOCIDO EN NUESTRO PROGRAMA?</Typography>
                    <div>
                        <Box className={"btn"} onClick={()=>navigate(pathname+"/1")}>
                            <div>
                                <img src={healthcare}  alt=""/>
                            </div>
                            <Typography>Paciente</Typography>
                        </Box>
                        <Box className={"btn"} onClick={()=>navigate(pathname+"/2")}>
                            <div>
                                <img src={family} alt=""/>
                            </div>
                            <Typography>Pariente</Typography>
                        </Box>
                    </div>
                </div>}
                {!!tipo &&
                <CrearPaciente/>}
            </div>
            <Notifications notifications={notificaciones} style={style} />
        </>
    );
}

export default PlublicPacienteForm;
