import React from 'react';
import {Button, Typography, Box, ButtonGroup, Theme} from '@mui/material';
import {toggleViewTable} from "../redux/reducers/themeReducer";
import {FormatListBulleted, ListAlt} from "@mui/icons-material";
import Strings from "../assets/strings";
import makeStyles from '@mui/styles/makeStyles';
import {useAppDispatch, useAppSelector} from "../redux/hooks";
const useStyles = makeStyles((theme:Theme) => ({
    root:{
        width: "100%",
        height: "33px",
        margin: "0",
        display: "flex",
        padding: "0px 16px",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#f6f6f6",
        [theme.breakpoints.up('md')]: {
            marginTop: "15px",
        }
    }
}));


function ListViewMode({lg,noView,pageRows,pageTotalRows,children}:{lg?:boolean,noView?:boolean,pageRows?:number,pageTotalRows?:number,children?:any}){
    const viewTableList = useAppSelector(state=>state.theme.viewTableList);
    const dispatch = useAppDispatch();

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Typography variant={"body2"}>{!!pageRows && <>{pageRows} {Strings.de} {pageTotalRows}</>}</Typography>
           <Box alignItems={"center"} display={"flex"}>
               {children}
               {!noView &&
                   <Box sx={  !lg? { display: { xs: 'block', md: 'none' }  }:{} } >
                       <ButtonGroup size="small" aria-label="small outlined button group">
                           <Button style={{marginLeft: 5}} color={viewTableList ? "primary":"inherit"}  onClick={()=> !viewTableList && dispatch(toggleViewTable())}><FormatListBulleted fontSize={"small"}/></Button>
                           <Button color={!viewTableList ? "primary":"inherit"}  onClick={()=>viewTableList && dispatch(toggleViewTable())} ><ListAlt fontSize={"small"}/></Button>
                       </ButtonGroup>
                   </Box>
               }

           </Box>
        </div>)
}
export default ListViewMode;

