import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarPacientes} from "../../redux/reducers/pacientesReducers";

import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider, FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  ButtonResponsive,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IPaciente} from "../../redux/types/IPacientes";
import {FilterList} from "@mui/icons-material";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  planId:'',
  plan:'',
  verInactivos: false,
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Pacientes(){
  let navigate = useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.pacientes)

  const isEmpty = (!list?.results || list?.results.length === 0);


  useEffect(() => {
    Dispatch(mostrarPacientes());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarPacientes({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter(prevState => ({...prevState,[name]:type === "checkbox"?checked:value}))
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarPacientes({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarPacientes(filter))
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
     onClick:()=>Dispatch(mostrarPacientes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Pacientes"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <SelectSearchPlan
                  value={{
                    plan:filter.plan,
                    planId: (filter.planId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    planId:v.planId?v.planId:"",
                    plan: v.plan
                  })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel sx={{m: 0,mt:2}}
                                control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                                label={Strings.ver+" "+Strings.inactivo}
                                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((pac) =>
                <React.Fragment key={pac.id}>
                  <ListItem button onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.parientesActualizar)) return false;
                              navigate("/editar/paciente/"+pac.id)}}>
                    <ListItemText primary={pac.pacNombres} secondary={pac.pacDocIdentidad}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"pacNombres",text:Strings.nombres},
            // {pro:"pacTipoDocumentoString",text:"Tipo Doc."},
            // {pro:"pacDocIdentidad",text:Strings.cedula},
            {pro:"pacPuntos",text:Strings.punto},
            {pro:"plan",text:Strings.plan},
            {pro:"pacEmail",text:Strings.email},
            {pro:"pacTelefono",text:Strings.telefono},
            {pro:"pacTelefono2",text:Strings.celular},
            {pro:"paisId",text:Strings.pais},
            // {pro:"provinciaId",text:Strings.provincia},
            // {pro:"municipioId",text:Strings.municipio},
            // {pro:"sector",text:Strings.sector},
            // {pro:"pacDireccion",text:Strings.direccion},
            //{pro:"pacComentario",text:Strings.comentario},
            //{pro:"pacFechaNacimiento",text:Strings.fechaNacimiento},
            // {pro:"pacSexo",text:Strings.sexo},

            {pro:"pacInactivo",text:Strings.estado},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((paciente:IPaciente) =>
                  <TableRow key={paciente.id} onClick={()=>navigate("/detalles/paciente/"+paciente.id)} className={paciente.pacInactivo ? "text-red-deep":""}>
                    <TableCell>{paciente.pacNombres} {paciente.pacApellidos}</TableCell>
                    {/*<TableCell>{paciente.pacTipoDocumentoString}</TableCell>*/}
                    {/*<TableCell>{paciente.pacDocIdentidad}</TableCell>*/}
                    <TableCell>{paciente.pacPuntos}</TableCell>
                    <TableCell>{paciente.plan}</TableCell>
                    <TableCell>{paciente.pacEmail}</TableCell>
                    <TableCell>{paciente.pacTelefono}</TableCell>
                    <TableCell>{paciente.pacTelefono2}</TableCell>
                    <TableCell>{paciente.pais}</TableCell>
                    {/*<TableCell>{paciente.provincia}</TableCell>*/}
                    {/*<TableCell>{paciente.municipio}</TableCell>*/}
                    {/*<TableCell>{paciente.sector}</TableCell>*/}
                    {/*<TableCell>{paciente.pacDireccion}</TableCell>*/}
                    {/*<TableCell>{paciente.pacComentario}</TableCell>*/}
                    {/*<TableCell>{formatDate(paciente.pacFechaNacimiento)}</TableCell>*/}
                    {/*<TableCell>{paciente.pacSexo}</TableCell>*/}

                    <TableCell>{paciente.pacInactivo?Strings.inactivo:Strings.activo}</TableCell>

                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarPacientes({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.pacientesCrear) &&
    <PlusFab component={Link} target="_blank" to="/pacienteForm" />
    }

  </>;
}

export default Pacientes;

