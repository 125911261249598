import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import Strings from "../../assets/strings";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CircularProgress, IconButton, InputAdornment, TextField} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getLSLang} from "../../helpers";
import {API_PUBLIC} from "../../services";
import {mostrarPerfil} from "../../redux/reducers/authReducer";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import LayOutLogin from "./LayOutLogin";
import {store} from "../../redux/store";
import Notifications from "react-notification-system-redux";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";


export default function SignInSide() {
    const navigateTo =useNavigate();
    const {state:urlState}:{state:any} = useLocation();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth)
    const backurl = (urlState?.backurl) ? urlState.backurl:"/inicio";
    const [showPass, setShowPass] = useState(false);

    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [state, setState] = useState({
        lang:getLSLang("l"),
        usuario:"",
        contrasena:"",
        recordar:false,
    });
    useEffect(()=>{
        return ()=> {
            store.dispatch(Notifications.removeAll())
        };
    },[])
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };
    const handleChangeBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, recordar: event.target.checked });
    };
    const submitForm = () =>{
        setLoading(true);
        setError("");
        API_PUBLIC.post('LogIn',{
            usuario: state.usuario,
            contrasena: state.contrasena,
           // recordar: state.recordar,
        })
        .then((response) =>{
            localStorage.setItem("_token", response.data.accessToken);

            // to get the color en currency
            dispatch(mostrarPerfil(response.data));
            navigateTo(backurl)
        })
        .catch(({response}) => {
            if(response?.data?.title)
                setError(response?.data?.title);
            else
                setError("Error");
            setLoading(false)
        });
    };


    if ((auth.isAuthenticated && localStorage.getItem("_token"))) {
        return <Navigate replace to={backurl}/>;
    }


    return (
        <LayOutLogin>
            <Typography component="h1" variant="h5">
                {Strings.iniciarSesion}
            </Typography>
            {!!error &&
                <Typography color="error" variant="caption">
                    {error}
                </Typography>
            }
            <Box sx={{ mt: 1 , width:"100%"}}>
                <ValidatorFormCustom onSubmit={submitForm} >
                    <TextField
                        label={Strings.nombreDeUsuario}
                        // validators={['required',"matchRegexp:^[A-z0-9 \\/-@._]*$"]}
                        margin="normal"
                        name="usuario"
                        value={state.usuario}
                        onChange={handleChange}
                        fullWidth
                        required
                        // className={"noAsterisk"}
                    />
                    <TextValidatorCustom
                        label={Strings.contrasena}
                        validators={['required']}
                        margin="normal"
                        name="contrasena"
                        type={showPass?"text":"password"}
                        value={state.contrasena}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment:  <InputAdornment position="end" style={{position: "absolute",right: 0}}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    //  onMouseDown={handleMouseDownPassword}
                                    onClick={()=>setShowPass(!showPass)}
                                    size="large">
                                    {showPass ? <Visibility fontSize={"small"}/> : <VisibilityOff fontSize={"small"} />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        className={"noAsterisk"}
                        fullWidth
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.recordar}
                                onChange={handleChangeBox}
                                value="recordar"
                            />
                        }
                        label={Strings.recodarUsuario}
                    />

                    <Button
                        disabled={isLoading || !state.usuario}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isLoading? (
                            <span><CircularProgress size={14} color="inherit" /> &nbsp; Cargando</span>
                        ):(
                            <span>{Strings.entrar}</span>
                        )}
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Typography component={Link} to="/forgotPassword" variant="body2" color={"inherit"}>
                                {Strings.olvideMicon}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component={Link}  to="/pacienteForm" variant="body2" color={"inherit"}>
                                Formulario de Registro
                            </Typography>
                        </Grid>
                    </Grid>
                </ValidatorFormCustom>
            </Box>
        </LayOutLogin>
    );
}
