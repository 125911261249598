import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarCanje, mostrarCanjes} from "../../redux/reducers/canjesReducers";

import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl, FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';
import {Close, FilterList} from '@mui/icons-material';
import {DateInput, SelectSmart} from "../../components/CustomTextField";

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ICanje} from "../../redux/types/ICanje";
import PlusFab from "../../components/PlusFab";
import Crear from "./Crear";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchFarmacia from '../../components/selects/SelectSearchFarmacia';
import SelectSearchPaciente from '../../components/selects/SelectSearchPaciente';
import DetalleCanje from "./DetalleCanje";
import {ESTADO_AUTORIZACION, getValueString, objToKeyValue, TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import {ShieldKeyOutline} from "mdi-material-ui";
import AutorizacionDetalle from "../Autorizaciones/AutorizacionDetalle";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    background: "#eff8ff !important",

    padding:theme.spacing(1),
    "& tr.aprobada":{
      background: "#eff8ff !important"
    },
    "& tr.rechazada":{
      background: "#fff3f3 !important"
    },
  }
}));

const initialState={
  name: '',
  planId: '',
  plan:'',
  farmaciaId:'',
  farmacia:'',
  pacienteId:'',
  paciente:'',
  estado: "",
  verInactivos:false,
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Canjes(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.canjes)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarCanjes());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarCanjes({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarCanjes({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarCanjes(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarCanjes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };


  return <>
    <AppBar title={"Canjes"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.estado}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.estado ?? ""}
                  onChange={handleFilter}
                  name="estado"
                  opciones={[
                    {key: '', value: Strings.todo},
                    ...objToKeyValue(ESTADO_AUTORIZACION)
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaDesde"}
                    label={Strings.fechaDesde}
                    value={filter.fechaDesde}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaHasta"}
                    label={Strings.fechaHasta}
                    value={filter.fechaHasta}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchPlan
                  value={{
                    plan:filter.plan,
                    planId: (filter.planId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    planId:v.planId?v.planId:"",
                    plan: v.plan
                  })}
              />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchPaciente
                    label={Strings.paciente}
                    value={{
                        id: filter.pacienteId ? parseInt(filter.pacienteId):undefined,
                        pacNombres: filter.paciente ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        paciente:v.pacNombres??"",
                        pacienteId:v.id?.toString() ?? ""
                    }))}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchFarmacia
                    value={{
                        farmaciaId: (filter.farmaciaId ?? "").toString(),
                        farmacia: filter.farmacia ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        farmacia:v.farmacia,
                        farmaciaId:v.farmaciaId?v.farmaciaId:""
                    }))}
                />

              <FormControlLabel sx={{m: 0,mt:2}}
                                control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                                label={Strings.ver+" "+Strings.inactivo}
                                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount} noView>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <TableSort data={[
        {pro:"",text:""},
        {pro:"id",text:"ID"},
        {pro:"fechaCreacion",text:Strings.fecha},
        {pro:"farmaciaId",text:Strings.farmacia},
        {pro:"pacienteId",text:Strings.paciente},
        {pro:"planId",text:Strings.plan},
        {pro:"articuloId",text:Strings.articulo},
        {pro:"canEstado",text:Strings.estado},
      ]} onSort={tableSortProps} lessVh={155}>
        <TableBody>
          {!isEmpty && list?.results.map((can:ICanje) =>
              <TableRow key={can.id}
                        onClick={()=> {
                            navigate(location.pathname, {state: {canje: can}})}
                        }
                        className={getValueString(ESTADO_AUTORIZACION,can?.autorizacionEstado)}
              >
                <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                  {!can.canInactivo && hasPermissionTo(PERSMISOS.canjesEliminar) &&
                      <HtmlTooltip title={Strings.anular}>
                        <IconButton size={"small"}
                                    onClick={(e)=> {
                                      navigate(location.pathname, {state: {confirmDialog: can.id}})
                                    }}>
                          <Close fontSize={"small"} className="text-red"/>
                        </IconButton>
                      </HtmlTooltip>
                  }
                  {can.autorizacionEstado === 1 && hasPermissionTo(PERSMISOS.autorizaciones) &&
                      <HtmlTooltip title={Strings.autorizacion}>
                        <IconButton  onClick={()=> navigate(location.pathname, {state: {autdetalle: {autEstado:1, id:can.autorizacionId,autTipo:TIPO_AUTORIZACION.canje,autTransacionId:can.id}}})}  size={"small"}>
                          <ShieldKeyOutline fontSize={"small"} className="text-violet"/>
                        </IconButton>
                      </HtmlTooltip>
                  }
                </TableCell>
                <TableCell>{can.id}</TableCell>

                <TableCell>{formatDate(can.fechaCreacion)}</TableCell>
                <TableCell>{can.farmacia}</TableCell>
                <TableCell>{can.paciente}</TableCell>
                <TableCell>{can.plan}</TableCell>
                <TableCell>{can.articulo}</TableCell>
                <TableCell>{can.autorizacionEstadoString}</TableCell>
              </TableRow>
          )}
        </TableBody>
      </TableSort>

      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarCanjes({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>

    {hasPermissionTo(PERSMISOS.canjesCrear) &&
        <PlusFab component={Button} onClick={()=>navigate(location.pathname,{state:{id:null}})} />
    }
    {hasPermissionTo(PERSMISOS.autorizaciones) &&
      <AutorizacionDetalle />
    }

    <DetalleCanje/>
    <Crear/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarCanje(urlState.confirmDialog))}/>
    }
  </>;
}

export default Canjes;

