import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarCentrosMedicos} from "../../redux/reducers/centrosMedicosReducers";

import {
  Divider,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ICentroMedico} from "../../redux/types/ICentroMedico";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function CentrosMedicos(){
  let navigate =useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.centrosMedicos)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarCentrosMedicos());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarCentrosMedicos({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:value})
  };
  const resetFilter = () => {
    setFilter(initialState);
    Dispatch(mostrarCentrosMedicos({}))
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
     onClick:()=>Dispatch(mostrarCentrosMedicos({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"CentrosMedicos"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
    </AppBar>
    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((cenMe) =>
                <React.Fragment key={cenMe.id}>
                  <ListItem button
                            onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.centrosMedicosActualizar)) return false;
                              navigate("/editar/centroMedico/"+cenMe.id)}}>
                    <ListItemText primary={cenMe.cenNombre} secondary={cenMe.cenCodigo}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"cenCodigo",text:Strings.codigo},
            {pro:"cenNombre",text:Strings.nombres},
            {pro:"pais",text:Strings.pais},
            {pro:"provincia",text:Strings.provincia},
            {pro:"municipio",text:Strings.municipio},
            {pro:"sector",text:Strings.sector},
            {pro:"cenDireccion",text:Strings.direccion},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((cenMe:ICentroMedico) =>
                  <TableRow key={cenMe.id} onClick={()=> {
                    if(!hasPermissionTo(PERSMISOS.centrosMedicosActualizar)) return false;
                    navigate("/editar/centroMedico/" + cenMe.id)
                  }}>
                    <TableCell>{cenMe.cenCodigo}</TableCell>
                    <TableCell>{cenMe.cenNombre}</TableCell>
                    <TableCell>{cenMe.pais}</TableCell>
                    <TableCell>{cenMe.provincia}</TableCell>
                    <TableCell>{cenMe.municipio}</TableCell>
                    <TableCell>{cenMe.sector}</TableCell>
                    <TableCell>{cenMe.cenDireccion}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarCentrosMedicos({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.centrosMedicosCrear) &&
    <PlusFab component={Link} to="/crear/centroMedico" />
    }

  </>;
}

export default CentrosMedicos;

