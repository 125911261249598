import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarPlanes} from "../../redux/reducers/planesReducers";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import {FilterList} from "@mui/icons-material";

import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  FilteredAlert,
  ButtonResponsive,
  ListViewMode,
  LoadingProgress,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IPlan} from "../../redux/types/IPlanes";
import { ValidatorFormCustom } from '../../helpers/form-validator';

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  verInactivos: false,
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Planes(){
  let navigate =useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.planes)

  const isEmpty = (!list || list.length === 0);

  useEffect(() => {
    Dispatch(mostrarPlanes());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarPlanes({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter(prevState => ({...prevState,[name]:type === "checkbox"?checked:value}))
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarPlanes({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarPlanes(filter))

};
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
     onClick:()=>Dispatch(mostrarPlanes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Planes"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar> 

           <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                <DialogContent>
                    <ValidatorFormCustom onSubmit={()=>{}}>
                        <FormControlLabel sx={{m: 0,mt:2}}
                            control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                            label={Strings.ver+" "+Strings.inactivo}
                            labelPlacement="start"
                        />
                    </ValidatorFormCustom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>



    <ListViewMode pageRows={list?.length}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.map((pla) =>
                <React.Fragment key={pla.id}>
                  <ListItem button onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.planesActualizar)) return false;
                              navigate("/editar/plan/"+pla.id)}}>
                    <ListItemText primary={pla.plaNombre} secondary={pla.plaDescripcion}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"plaNombre",text:Strings.nombres},
            {pro:"plaDescripcion",text:Strings.descripcion},
            {pro:"plaFechaInicio",text:Strings.fechaInicio},
            {pro:"plaFechaVencimiento",text:Strings.fechaVencimiento},
            {pro:"plaInactivo",text:Strings.estado},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.map((plan:IPlan) =>
                  <TableRow key={plan.id} onClick={()=> {
                    if(!hasPermissionTo(PERSMISOS.planesActualizar)) return false;
                    navigate("/editar/plan/" + plan.id)
                  }} className={plan.plaInactivo ? "text-red-deep":""}>
                    <TableCell>{plan.plaNombre}</TableCell>
                    <TableCell>{plan.plaDescripcion}</TableCell>
                    <TableCell>{formatDate(plan.plaFechaInicio)}</TableCell>
                    <TableCell>{formatDate(plan.plaFechaVencimiento)}</TableCell>
                    <TableCell>{plan.plaInactivo?Strings.inactivo:Strings.activo}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      {/*<Pagination*/}
      {/*    empty={Boolean(isEmpty && status !== "loading")}*/}
      {/*    count={list?.totalRecordCount || 0}*/}
      {/*    rowsPerPage={Number(filter.pageSize)}*/}
      {/*    page={(list?.pageNo ?? 0) -1}*/}
      {/*    onPageChange={(_,page:number)=>Dispatch(mostrarArticulos({...filter,page:(page + 1).toString()}))}*/}
      {/*    onRowsPerPageChange={handleFilter}*/}
      {/*/>*/}
    </section>
    {hasPermissionTo(PERSMISOS.planesCrear) &&
    <PlusFab component={Link} to="/crear/plan" />
    }

  </>;
}

export default Planes;

