import React, {useEffect, useState} from 'react';
import {mostrarRoles,eliminarRol} from '../../redux/reducers/rolesReducers';
import Crear from './crear';
import Strings from "../../assets/strings";
import {Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import AppBar from "../../components/appBar";
import PlusFab from "../../components/PlusFab";
import {Close} from "@mui/icons-material";
import LoadingProgress from "../../components/LoadingProgress";
import {useLocation, useNavigate} from "react-router-dom";
import Empty from "../../components/Empty";
import {ConfirmDialog, SeacrhAppBar} from "../../components";
import {hasPermissionTo, noTilde, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IRol} from "../../redux/types/IRoles";

function Roles() {
    const navigate =useNavigate();
    const location = useLocation();
    const { list, status } = useAppSelector(state => state.roles);
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const [searchBox, setSearchBox] = useState("");


    useEffect(() => {
        Dispatch(mostrarRoles());
    }, [Dispatch]);

    const isEmpty = !list || list.length === 0;
    function filterItems():IRol[]|undefined{


        return list?.filter(item=> noTilde(item.rolDescripcion).indexOf(noTilde(searchBox)) !== -1);
    }
    return <>
        <AppBar title={Strings.roles}>
            <SeacrhAppBar name="search" value={searchBox} onChange={({target}:{target:any})=>setSearchBox(target.value)}/>
        </AppBar>
        <section className="contentInner" style={{maxWidth:600}}>
            {status === "loading"  && !list?.length && <LoadingProgress vh/>}
            <List >
                {filterItems()?.map(item =>
                    <ListItem role={undefined} button key={item.id} onClick={()=> {
                        if(!hasPermissionTo(PERSMISOS.rolesActualizar)) return false;
                        navigate(location.pathname, {state: item})
                    }}>
                        <ListItemText primary={item.rolDescripcion}  />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={()=>navigate(location.pathname, {state:{confirmDialog:item.id}})}
                                size="large">
                                <Close className="text-red" fontSize={"small"}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </List>
            {isEmpty && <Empty/>}
            {hasPermissionTo(PERSMISOS.rolesCrear) &&
                <PlusFab component={Button} onClick={()=>navigate(location.pathname, {state:{id:""}})} />
            }
        </section>
        <Crear/>
        {urlState?.hasOwnProperty("confirmDialog") &&
            <ConfirmDialog onConfirm={()=>Dispatch(eliminarRol(urlState.confirmDialog))}/>
        }

    </>;
}
export default Roles;
