import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress, ListItem, ListItemText} from '@mui/material';
import Strings from "../../assets/strings";
import {getArticulosCanjePlanId, getArticulosPlanId} from "../../services";
import {TextValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    value:TValue,
    required?:boolean,
    ventaLibre?:boolean,
    disabled?:boolean,
    planId?:number,
    canje?:boolean,
    onChange: (val:TValue)=>void

}
type TValue = {
    codigo?:string,
    codigoBarra?:string,
    comprimidos?:number
    id?:number
    indicadorVentaLibre?: boolean
    nombre?:string,
    puntosCanje?:number
    puntosCompra?:number
}

let emptyValue:TValue = {
    codigo:"",
    comprimidos:undefined,
    id:undefined,
    nombre:"",
}





export default function SelectSearchArticuloByPacienteId({ value,label=Strings.articulo,planId,ventaLibre, canje,required,disabled, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)


     useEffect(() => {
        if(planId){
            setLoading(true)
            let fetch = canje ? getArticulosCanjePlanId(planId) :getArticulosPlanId(planId,ventaLibre)
            fetch.then(res=>{
                let result = res.data as TValue[];
                setOptions(result)
            }).finally(()=>setLoading(false))
        }
    },[canje,planId,setLoading,setOptions])

    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                disabled={disabled}
                loading={loading}
                aria-required={required}
                options={[value,...options.filter((r) =>r.id!==value.id)]}
                getOptionLabel={(option) => option.nombre??""}
                value={value}
                isOptionEqualToValue={(option, value) => option.id === value.id}

                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                renderOption={(props, option) => (
                    <ListItem  {...props}>
                        <ListItemText primary={option.nombre} secondary={option.codigo !== undefined? Strings.codigo+": "+option.codigo:""} />
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.id}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
