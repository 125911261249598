import React, {useEffect, useState} from 'react';
import {Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {nullToEmptyString, TIPOUSUARIO} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ICanje} from "../../redux/types/ICanje";
import {agregarEditarCanje} from "../../redux/reducers/canjesReducers";
import SelectSearchPaciente from "../../components/selects/SelectSearchPaciente";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import {getPacienteById} from "../../services";
import Typography from "@mui/material/Typography";
import {getTokenInfo} from "../../services/auth";
import SelectSearchArticuloByPacienteId from "../../components/selects/SelectSearchArticuloByPacienteId";

const initialState:ICanje = {
    farmaciaId: undefined,
    farmacia: "",
    pacienteId: undefined,
    paciente: "",
    planId: undefined,
    plan: "",
    canNota: "",
    articuloId: undefined,
    articulo: "",
};
export default function Crear() {
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const [loading, setLoading] =  React.useState<boolean>(false);
    const {status} = useAppSelector(state => state.canjes);
    const user = useAppSelector(state=>state.auth.user)
    const [puntos, setPuntos] = useState<{pacPuntos?:number,artPunto?:number}>({
        pacPuntos:0,
        artPunto:0
    });


    const [state, setState] = useState(initialState);

    useEffect(() => {
        if(!!urlState && urlState.id) {
            setState(prevState => ({...prevState, ...nullToEmptyString(urlState)}));
        }else {
            //dejar plan seleccionado ideal cuando el usuario no es admin
            setState(prevState => ({...prevState,
                paciente:prevState.paciente,
                pacienteId:prevState.pacienteId,
                planId:prevState.planId,
                plan:prevState.plan}))
        }
    }, [setState, urlState]);

    useEffect(()=>{
        if(state.pacienteId){
            //setLoading(true)
            getPacienteById(state.pacienteId).then(({data})=>{
                setState(prevState => ({...prevState,articuloId:undefined,articulo:"", planId:data.planId,plan:data.plan}))
                setPuntos(prev=>({...prev, pacPuntos:data.pacPuntos}))
            }).finally(()=>setLoading(false))
        }else{
            setState(prevState => ({...prevState,articuloId:undefined,articulo:"",planId:undefined,plan:""}))
        }
    },[state.pacienteId,setState])

    useEffect(()=>{
        if(user?.usuTipo === TIPOUSUARIO.farmacia){
            setState(prevState => ({...prevState,farmaciaId:(getTokenInfo() as any)["RelatedId"],farmacia:user.usuNombre}))
        }else if(user?.usuTipo === TIPOUSUARIO.paciente){
            setState(prevState => ({...prevState,pacienteId:(getTokenInfo() as any)["RelatedId"],paciente:user.usuNombre}))
        }
    },[user, setState])

    const handleSubmit = () => {

        if((puntos.pacPuntos?? 0 ) >= (puntos.artPunto ?? 0)){
            Dispatch(agregarEditarCanje(state))
        }
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))} onClose={()=>navigate(-1)} maxWidth={"xs"} fullWidth={true}>
                <TopBarDialog title={Strings.canje} onClose={()=>navigate(-1)}/>
                <Box padding={"5px 16px"} bgcolor={"#ececec"}><Typography color={"secondary"} variant={"body2"}>{Strings.plan}: {state?.plan || "--"}</Typography></Box>
                <DialogContent>
                        {(status === "loading" || loading) && <LoadingProgress />}
                            <ValidatorFormCustom onSubmit={handleSubmit} id="form1">

                                    <Box sx={{mb:2}}>
                                            <SelectSearchPaciente
                                                required
                                                label={Strings.paciente}
                                                disabled={user?.usuTipo === TIPOUSUARIO.paciente}
                                                value={{
                                                    id: state.pacienteId,
                                                    pacNombres: state.paciente
                                                }}
                                                onChange={(v)=> {
                                                    setState(prevState => ({
                                                        ...prevState,
                                                        paciente: v.pacNombres??"",
                                                        pacienteId:v.id,
                                                    }))
                                                    setPuntos(prev=>({...prev, pacPuntos: v.pacPuntos}))
                                                }}
                                            />
                                        {!!state.pacienteId && <Typography color={"secondary"} align={"right"} variant={"body2"}>Puntos disponible: {puntos.pacPuntos}</Typography>}
                                    </Box>
                                <Box sx={{mb:2}}>
                                    <SelectSearchFarmacia
                                        required
                                        value={{
                                            farmaciaId: (state.farmaciaId ?? "").toString(),
                                            farmacia: state.farmacia ?? ""
                                        }}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            farmacia:v.farmacia,
                                            farmaciaId:v.farmaciaId?parseInt(v.farmaciaId):undefined
                                        }))}
                                    />
                                </Box>
                                <Box sx={{mb:1}}>
                                    <SelectSearchArticuloByPacienteId
                                        pacId={state.pacienteId}
                                        disabled={!state.pacienteId}
                                        required
                                        canje
                                        value={{
                                            id: state.articuloId,
                                            nombre: state.articulo ?? ""
                                        }}
                                        onChange={(v)=> {
                                            setState(prevState => ({
                                                ...prevState,
                                                articulo: v.nombre,
                                                articuloId: v.id
                                            }))
                                            setPuntos(prev=>({...prev, artPunto: v.puntosCanje}))
                                        }}
                                    />
                                    {!!state.articuloId && <Typography color={"secondary"} align={"right"} variant={"body2"}>Puntos requeridos: {puntos.artPunto}</Typography>}
                                </Box>
                                <TextField
                                    multiline
                                    value={state.canNota}
                                    name={"canNota"}
                                    onChange={(e:any) => setState({...state, canNota: e.target.value})}
                                    label={Strings.comentario}
                                    sx={{mt:2,mb:2}}
                                    fullWidth
                                />
                            </ValidatorFormCustom>
                            {state.articuloId && (puntos.artPunto ?? 0) - (puntos.pacPuntos ?? 0) > 0 &&
                                <Alert severity={"warning"}>Necesitas {(puntos.artPunto ?? 0) - (puntos.pacPuntos ?? 0)} puntos mas para este canje.</Alert>
                            }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
