import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent, List, ListItem, ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings"
import {IAutorizacion} from "../../redux/types/IAutorizacion";
import {ICompra} from "../../redux/types/ICompra";
import {IIndicaciones} from "../../redux/types/IPacientes";
import {IFarmacia} from "../../redux/types/IFarmacia";
import {getCanjeById, getCompraById, getFarmaciaById, getIndicacionesById} from "../../services";
import {TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import AutorizacionActions from "./AutorizacionActions";
import makeStyles from "@mui/styles/makeStyles";
import Divider from "@mui/material/Divider";
import {ICanje} from "../../redux/types/ICanje";

const useStyles = makeStyles((theme:Theme) => ({
    dialogContent:{
        padding:theme.spacing(2),
        "& table td":{
            padding:theme.spacing(1)+" 0",
        }
    },
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
}));

const initialState = {
    id: undefined,
    autTipo: undefined,
    autTipoString: "",
    autTransacionId: undefined,

};

export default function AutorizacionDetalle({verDetalle}:{verDetalle?:boolean}) {
    const classes = useStyles();

    const {state:urlState}:any = useLocation();
    const autdetalle = urlState?.autdetalle as IAutorizacion;


    const navigate =useNavigate();
    const location =useLocation();

    const goBack = () => {
        navigate(-1)
    }
    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState("");

    const [state, setState] = useState<IAutorizacion>(initialState);
    const [farmacia, setFarmacia] = useState<IFarmacia>();
    const [compra, setCompra] = useState<ICompra>();
    const [canje, setCanje] = useState<ICanje>();
    const [indicacion, setIndicacion] = useState<IIndicaciones>();


    useEffect(() => {
        (async ()=>{
            setError("")
            if(!!autdetalle?.id) {
                setState(state => ({...state, ...nullToEmptyString(autdetalle)}));
                setLoading(true)
                let response;
                try {
                    switch (autdetalle.autTipo) {
                        case TIPO_AUTORIZACION.pacienteIndicacion:
                            response  = await getIndicacionesById(autdetalle.autTransacionId)
                            setIndicacion(response.data)
                            break
                        case TIPO_AUTORIZACION.creacionSucursal:
                            response  = await getFarmaciaById(autdetalle.autTransacionId)
                            setFarmacia(response.data)
                            break
                        case TIPO_AUTORIZACION.compra:
                            response  = await getCompraById(autdetalle.autTransacionId)
                            setCompra(response.data)
                            break
                        case TIPO_AUTORIZACION.canje:
                            response  = await getCanjeById(autdetalle.autTransacionId)
                            setCanje(response.data)
                            break
                        default:
                            response = undefined
                    }
                }catch (e) {
                   if(e.response.status===404){
                       setError("Registro no encontrado.")
                   }
                }
                setLoading(false)

            }else {
                setIndicacion(undefined);
                setFarmacia(undefined);
                setCompra(undefined);
                setCanje(undefined);

            }
        })()
    }, [setState, autdetalle]);
    const [openImg, setOpenImg] = useState("");

    const viewFileClick = (src?:string) =>{
        if(src?.indexOf("pdf") !== -1) return src?.indexOf("base64") === -1 ? window.open(encodeURI(src??"")) : false;
        if(src) setOpenImg(src);
    }

    return (
        <>
            <Dialog open={Boolean(autdetalle?.hasOwnProperty("id"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={state.autTipoString || Strings.autorizacion+" "+Strings.pendiente} nofullScreen onClose={goBack}/>
                <DialogContent className={classes.dialogContent}>
                    {loading && <LoadingProgress />}
                    {!!error &&
                        <Alert severity="warning">{error}</Alert>
                    }
                    {!!indicacion?.id &&
                        <>
                            <Table className={"noHover"} size={"small"}>
                                <TableBody >
                                    {/*onClick={()=>navigate("/detalles/paciente/"+indicacion?.pacienteId)}*/}
                                    <TableRow>
                                        <TableCell>{Strings.paciente}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.paciente}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.articulo}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.articulo}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.centroMedico}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.centroMedico}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.medico}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.medico}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.especialidad}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.especialidad}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.licencia}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.medNumLicencia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.dosis}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.pacCicloUsoString}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.estado}:</TableCell>
                                        <TableCell align={"right"}>{indicacion.autorizacionEstadoString}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {indicacion.pacIndicacion &&
                                <Button variant={"contained"} color={"inherit"} disableElevation size={"small"} onClick={(e)=> {
                                    e.stopPropagation();
                                    viewFileClick(indicacion.pacIndicacion)
                                }} >{Strings.ver} {Strings.adjunto}</Button>
                            }
                        </>
                    }
                    {!!farmacia?.id &&
                            <Table className={"noHover"} size={"small"}>
                                {/*onClick={()=>navigate("/detalles/farmacia/"+farmacia.id)}*/}
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{Strings.codigo}:</TableCell>
                                        <TableCell>{farmacia.farCodigo}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.nombre}:</TableCell>
                                        <TableCell>{farmacia.farNombre}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>{Strings.telefono}</TableCell>
                                        <TableCell>{farmacia.farTelefono}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.celular}:</TableCell>
                                        <TableCell>{farmacia.farTelefono2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.email}:</TableCell>
                                        <TableCell>{farmacia.farEmail}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.autorizacion}:</TableCell>
                                        <TableCell>{farmacia.autorizacionEstadoString}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.direccion}:</TableCell>
                                        <TableCell>{farmacia.pais}, {farmacia.provincia}, {farmacia.municipio}, {farmacia.sector}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>{farmacia.farDireccion} {farmacia.farDireccion2} {!!farmacia.farDireccion2 && ", "+farmacia.farDireccion2}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                    }

                    {!!compra?.id &&
                        <>
                            <Table className={"noHover"} size={"small"}>
                                {/*onClick={()=> navigate("/compras", {state:compra})}*/}
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{Strings.fecha}:</TableCell>
                                        <TableCell>{formatDate(compra.fechaCreacion)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.paciente}:</TableCell>
                                        <TableCell>{compra.paciente}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.farmacia}:</TableCell>
                                        <TableCell>{compra.farmacia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.numeroFactura}:</TableCell>
                                        <TableCell>{compra.comNumeroDoc}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.plan}:</TableCell>
                                        <TableCell>{compra.plan}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.puntos}:</TableCell>
                                        <TableCell>{compra.comPuntosGenerados}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{Strings.autorizacion}:</TableCell>
                                        <TableCell>{compra.autorizacionEstadoString}</TableCell>
                                    </TableRow>
                                    {compra.comNota &&
                                        <TableRow>
                                            <TableCell>{Strings.comentario}:</TableCell>
                                            <TableCell>{compra.comNota}</TableCell>
                                        </TableRow>
                                    }

                                </TableBody>
                            </Table>
                            {compra.comAdjunto &&
                                    <Button variant={"contained"} color={"inherit"} disableElevation size={"small"} onClick={(e)=> {
                                        e.stopPropagation();
                                        viewFileClick(compra.comAdjunto)
                                    }} >{Strings.ver} {Strings.adjunto}</Button>
                            }

                            {compra.detalle &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{compra.detalle?.length} {Strings.articulos}</Typography>
                                    </div>
                                    <div>
                                        <List dense>
                                            {compra.detalle?.map((p)=>
                                                <React.Fragment key={p.articuloId}>
                                                    <ListItem>
                                                        <ListItemText
                                                            sx={p.accion===3?{textDecoration: "line-through",color: "#a91a1a"}:{}}
                                                            secondary={`${Strings.cantidad}: `+p.comCantidad+(!!p.comCantidadDetalle? ` / ${Strings.comprimidos}: `+p.comCantidadDetalle:" ")
                                                                +(!!p.comPuntos? ` / ${Strings.puntos}: `+p.comPuntos:"")
                                                                +(!!p.comIndicadorVentaLibre? " ("+Strings.ventaLibre+")":"")}
                                                        >
                                                            {p.articulo?.replace(/(.{35})..+/, "$1…")}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {!!canje?.id &&
                        <Table className={"noHover"} size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{Strings.fecha}</TableCell>
                                    <TableCell>{formatDate(canje.fechaCreacion)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Strings.paciente}</TableCell>
                                    <TableCell>{canje.paciente}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Strings.farmacia}</TableCell>
                                    <TableCell>{canje.farmacia}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{Strings.plan}</TableCell>
                                    <TableCell>{canje.plan}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Strings.estado}</TableCell>
                                    <TableCell>{!canje.canInactivo ? Strings.inactivo : Strings.activo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Strings.articulo}</TableCell>
                                    <TableCell>{canje.articulo}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{Strings.autorizacion}</TableCell>
                                    <TableCell>{canje.autorizacionEstadoString}</TableCell>
                                </TableRow>
                                {canje.canNota &&
                                    <TableRow>
                                        <TableCell>{Strings.comentario}</TableCell>
                                        <TableCell>{canje.canNota}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    }
                </DialogContent>
                {state.autEstado === 1 &&
                    <DialogActions>
                        {(farmacia || indicacion) && verDetalle &&
                            <Button color="inherit" sx={{mr:"auto"}} onClick={()=> {
                                if(indicacion){
                                    navigate("/detalles/paciente/"+indicacion.pacienteId)
                                }
                                if(farmacia){
                                    navigate("/detalles/farmacia/"+farmacia.id)
                                }
                            }}>{Strings.ver} {farmacia ? Strings.farmacia: Strings.paciente}</Button>
                        }

                        <Button color="secondary" onClick={()=> {
                            navigate(location.pathname, {replace:true})
                            navigate(location.pathname, {state: {autActions: {id: state.id, action: "rechazar"}}})
                        }}>{Strings.rechazar}</Button>
                        <Button color="primary"
                                onClick={()=> {
                                    navigate(location.pathname, {replace:true})
                                    navigate(location.pathname, {state: {autActions: {id: state.id, action: "aprobar"}}})
                                }}
                        >{Strings.aprobar}</Button>
                    </DialogActions>
                }
            </Dialog>
            <Dialog
                open={!!openImg}
                onClose={()=>setOpenImg("")}
            >
                <TopBarDialog title={"Adjunto"} nofullScreen onClose={()=>setOpenImg("")}/>

                <DialogContent  sx={{p:0}}>
                    <img src={openImg} alt="adjunto" style={{width:"100%"}}/>
                </DialogContent>
            </Dialog>


            <AutorizacionActions/>

        </>
    );

}
