import React, {useState, useEffect, useCallback} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    Box,
    DialogActions,
    Grid,
    useMediaQuery,
    Typography,
    Menu,
    MenuItem,
    FormControl, InputLabel, Select
} from "@mui/material";
import {agregarEditarUsuario,toggleLock,eliminarUsuario} from "../../redux/reducers/usuariosReducers";
import Strings from "../../assets/strings"
import {SelectRol} from "../../components/selects";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {SlideLeft} from "../../components/transitions";
import {useNavigate,useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {MostrarError,LoadingProgress,ConfirmDialog} from "../../components";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {TIPO_USUARIO} from "../../helpers/CONSTANTES";
import {editPWUsuario} from "../../services";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";
import {generarUsuarioFarmacia} from "../../redux/reducers/farmaciaReducers";

const initialState={
    usuInicioSesion: "",
    usuClave: "",
    usuClave2:"",
    usuNombre: "",
    usuEmail: "",
    rolId: "",
    usuUIcolor: "",
    cajId: "",
    usuRutas: "",
    usuTipo:TIPO_USUARIO.agente,
};
export default function CrearUsuario() {
    const navigate = useNavigate();
    const location = useLocation();
    const {status, error} = useAppSelector(state => state.usuarios);
    const Dispatch = useAppDispatch();

    const {state:urlState} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const [showMenu, setShowMenu] = useState(null);
    const [showFormPass, setShowFormPass] = useState(false);
    const [errorPass, setErrorPass] = useState("");

    const [state, setState] = useState(initialState);
    const resetBack = useCallback(
        () => {
            setErrorPass("");
            setShowFormPass(false);
            navigate(-1);
        },
        [setShowFormPass,navigate],
    );
    useEffect(() => {
        if(urlState?.usuario)
            setState(state=>({...state,...nullToEmptyString(urlState.usuario)}));
        else
            setState(initialState);

    }, [setState, urlState,resetBack]);

    const eliminar  = () =>{
        Dispatch(eliminarUsuario(state.id))
    };
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = () => {
        setErrorPass("");
        let usuClave = null;
        if(showFormPass || !state.id){
            usuClave = state.usuClave;
            if(usuClave !== state.usuClave2){
                return setErrorPass(Strings.contrasenaNoMatchMjs)
            }
        }
        if(showFormPass) {
            editPWUsuario({id: state.id, value: usuClave}).then(res => {
                setShowFormPass(false);
                setErrorPass("Se edito la contraseña")
                setState(state => ({...state, ...nullToEmptyString(urlState?.usuario)}));
            })
        } else {
            if(urlState?.farmaciaId && !state.id) {
                Dispatch(generarUsuarioFarmacia({
                    usuInicioSesion: state.usuInicioSesion,
                    usuClave: state.usuClave,
                    usuNombre: state.usuNombre,
                    usuEmail: state.usuEmail,
                    farmaciaId: urlState?.farmaciaId
                }));
            }else{

                Dispatch(agregarEditarUsuario({...state, usuClave, usuClave2: null,}));

            }
        }

    };

    return (
        <>
            <Dialog open={urlState?.hasOwnProperty("usuario")} onClose={resetBack}
                    {...(smVewport ? {TransitionComponent: SlideLeft}:{})}
                    fullScreen={smVewport}>

                <TopBarDialog title={Strings.usuario} onClose={resetBack}>
                    {!showFormPass && !!state.id &&
                        <>
                    <Button size={"small"} onClick={(e)=>setShowMenu(e.currentTarget)} color="inherit">
                        Opciones
                    </Button>
                    <Menu
                        id="menu-appbar"
                        anchorEl={showMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(showMenu)}
                        onClose={()=>setShowMenu(null)}
                    >
                        <MenuItem onClick={()=>{setShowMenu(null);Dispatch(toggleLock(state.id))}}>{!state.usuInactivo ? Strings.bloquear:Strings.desbloquear} {Strings.usuario}</MenuItem>
                        <MenuItem onClick={()=>{setShowMenu(null);setShowFormPass(true)}}>{"Restablecer "+ Strings.contrasena}</MenuItem>
                        <MenuItem onClick={()=>{
                            setShowMenu(null);
                            navigate(location.pathname, {state:{...state,confirmDialog:true}})
                        }}>{Strings.eliminar} {Strings.usuario}</MenuItem>
                    </Menu></>}
                </TopBarDialog>
                {!!state.id &&
                    <Box padding={"5px 16px"} bgcolor={"#ececec"}>
                        <Typography color={!!state.usuInactivo ? "secondary":"primary"} variant={"body2"}>{Strings.estado}: {!!state.usuInactivo ? Strings.inactivo:Strings.activo}</Typography>
                    </Box>
                }

                    <DialogContent>
                        <MostrarError errors={errorPass?errorPass:error}/>
                        {status === "loading" && <LoadingProgress/>}
                        <br/>
                        <ValidatorFormCustom id="form1" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                {!showFormPass && <>
                                <Grid item xs={6}>
                                    <TextValidatorCustom
                                        name="usuInicioSesion"
                                        value={state.usuInicioSesion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required',"matchRegexp:^[a-zA-Z0-9_ \\-]*$"]}
                                        label={Strings.nombreDeUsuario}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidatorCustom
                                        name="usuNombre"

                                        value={state.usuNombre}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required',"matchRegexp:^[A-zÀ-ú \\.]*$"]}
                                        label={Strings.primerNombre}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={!urlState?.farmaciaId ? 6 : 12}>
                                    <TextValidatorCustom
                                        name="usuEmail"
                                        type="email"
                                        value={state.usuEmail}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        label={Strings.email}
                                        validators={['isEmail']}
                                        fullWidth
                                    />
                                </Grid>
                                    {!urlState?.farmaciaId && <>
                                        <Grid item xs={6}>
                                            <SelectRol
                                                name="rolId"
                                                value={state.rolId}
                                                required
                                                onChange={handleChange}
                                                label={Strings.rol}
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <FormControl  fullWidth disabled>
                                                <InputLabel>{Strings.tipo}</InputLabel>
                                                <Select
                                                    disabled={!!state.id}
                                                    value={state.usuTipo}
                                                    onChange={({target})=>setState({...state,usuTipo:target.value})}
                                                    displayEmpty
                                                >
                                                    {Object.keys(TIPO_USUARIO).map((pro,i)=>
                                                        <MenuItem key={i} value={TIPO_USUARIO[pro]}>{Strings[pro]}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>}
                                </>}
                                {(showFormPass || !state.id) &&
                                    <>
                                    <Grid item xs={6}>
                                        <TextValidatorCustom
                                            name="usuClave"
                                            value={state.usuClave}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            label={Strings.contrasena}
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextValidatorCustom
                                            name="usuClave2"
                                            value={state.usuClave2}
                                            onChange={handleChange}
                                            validators={['required']}
                                            label={Strings.confirmar+" "+Strings.contrasena}
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid>
                                    </>
                                }
                            </Grid>
                        </ValidatorFormCustom>
                        <br/>
                    </DialogContent>
                    <DialogActions>
                    {/* {!showFormPass && <>
                        <Button onClick={()=>setShowFormPass(false)} color="primary">{Strings.eliminar}</Button>
                        <Button onClick={()=>setShowFormPass(false)} color="primary">{!state.usuInactivo ? Strings.bloquear:Strings.desbloquear}</Button>
                    </>}
 */}

                        {showFormPass && <Button onClick={()=>setShowFormPass(false)} color="primary">{Strings.cancelar}</Button>}

                        <Button type={"submit"} form={"form1"}  color="primary" disabled={status === "loading"}>
                            {!!state.id ? Strings.actualizar : Strings.guardar}
                        </Button>
                    </DialogActions>

            </Dialog>
            {!!urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog replace={"/usuarios"} onConfirm={eliminar}/>
            }
        </>
    );

}
