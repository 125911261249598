import React, {useEffect, useState} from 'react';
import {
    eliminarArticuloCategoria,
    mostrarArticulosCategorias
} from '../../../redux/reducers/articulosCategoriaReducers';
import AppBar from "../../../components/appBar";
import Crear from './crear';
import {Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import PlusFab from "../../../components/PlusFab";
import {Close} from "@mui/icons-material";
import LoadingProgress from "../../../components/LoadingProgress";
import {useLocation, useNavigate} from "react-router-dom";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {MostrarError} from "../../../components/MostrarError";
import Strings from "../../../assets/strings";
import {FilteredAlert, ListViewMode, Pagination, SeacrhAppBar,} from "../../../components";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

const initialState={
    name:"",
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}

function Categorias() {
    const navigate = useNavigate();
    const location = useLocation();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();


    const { list, status,error,filtered} = useAppSelector(state => state.articulosCategorias)
    const [filter, setFilter] = useState(initialState);

     useEffect(() => {
         Dispatch(mostrarArticulosCategorias());
     }, [Dispatch]);


     const isEmpty = !list?.results.length;


    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return Dispatch(mostrarArticulosCategorias({...filter,page:null,[name]:value}));

        setFilter({...filter,page:0,[name]:value});
    };
    const resetFilter = () => {
        Dispatch(mostrarArticulosCategorias());
    };
    return (
        <>
            <AppBar title={Strings.categoria}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
            </AppBar>

            <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount} noView>
                <FilteredAlert onClose={resetFilter} filter={filtered} />
            </ListViewMode>
            <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
                {status === "loading" && <LoadingProgress vh/>}
                {!urlState && <MostrarError errors={error}/>}


                <List >
                {!isEmpty && list?.results.map((cat:any) =>
                    <React.Fragment key={cat.id}>
                        <ListItem button
                                  onClick={()=>{
                                      navigate(location.pathname, {state: cat})}}>

                            <ListItemText primary={cat.catNombre}/>
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    onClick={()=>navigate(location.pathname, {state:{confirmDialog:Number(cat.id)}})}
                                    size="large">
                                    <Close className="text-red" fontSize={"small"}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                )}
                </List >


                {!isEmpty &&
                    <Pagination
                        empty={Boolean(isEmpty && status !== "loading")}
                        count={list?.totalRecordCount || 0}
                        rowsPerPage={Number(filter.pageSize)}
                        page={(list?.pageNo ?? 0) -1}
                        onPageChange={(_,page:number)=>Dispatch(mostrarArticulosCategorias({...filter,page:(page + 1).toString()}))}
                        onRowsPerPageChange={handleFilter}
                    />
                }

            </section>
            <PlusFab component={Button} onClick={()=>navigate(location.pathname,{state:{id:""}})} />
            {urlState?.hasOwnProperty("id") &&
                <Crear/>
            }

            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarArticuloCategoria(urlState.confirmDialog))}/>
            }
        </>
    );

}

export default Categorias;
