import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {mostrarOfertas} from "../../redux/reducers/ofertasReducers";

import {
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent,
  Divider, FormControlLabel, Grid,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort,
  ButtonResponsive
} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IOferta} from "../../redux/types/IOferta";
import {FilterList} from "@mui/icons-material";
import SelectList from "../../components/selects/SelectList";
import {ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  tipo:0,
  verInactivos:false,
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Ofertas(){
  let navigate =useNavigate();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.ofertas)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarOfertas());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])
  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, checked, type } = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarOfertas({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,page:0,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarOfertas({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarOfertas(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarOfertas({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Ofertas"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectList
                  label={Strings.tipo}
                  list={[{id: 1, name: "Vencimiento"}, {id: 2, name: "Promoción"}]}
                  value={{id:filter.tipo, name:""}}
                  onChange={(val)=>setFilter({...filter,tipo: val.id ?? 0  })}
              />
            </Grid>
          </Grid>
          <FormControlLabel sx={{m: 0,mt:2}}
                            control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                            label={Strings.ver+" "+Strings.inactivo}
                            labelPlacement="start"
          />
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((ofe) =>
                <React.Fragment key={ofe.id}>
                  <ListItem button onClick={()=>{
                              if(!hasPermissionTo(PERSMISOS.ofertasActualizar)) return false;
                              navigate("/editar/oferta/"+ofe.id)}}>
                    <ListItemText primary={`${ofe.ofeDescripcion}`} secondary={Strings.fechaVencimiento+": "+formatDate(ofe.ofeFechaVencimiento)}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"ofeDescripcion",text:Strings.descripcion},
            {pro:"ofeFechaDesde",text:Strings.fechaDesde},
            {pro:"ofeFechaVencimiento",text:Strings.fechaVencimiento},
            {pro:"ofeTipo",text:Strings.tipo},
            {pro:"ofeInactivo",text:Strings.estado},

          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((item:IOferta) =>
                  <TableRow key={item.id} onClick={()=> {
                    if(!hasPermissionTo(PERSMISOS.ofertasActualizar)) return false;
                    navigate("/editar/oferta/" + item.id)
                  }}>
                    <TableCell>{item.ofeDescripcion}</TableCell>
                    <TableCell>{formatDate(item.ofeFechaDesde)}</TableCell>
                    <TableCell>{formatDate(item.ofeFechaVencimiento)}</TableCell>
                    <TableCell>{item.ofeTipoString}</TableCell>
                    <TableCell>{item.ofeInactivo?Strings.inactivo:Strings.activo}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarOfertas({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.ofertasCrear) &&
    <PlusFab component={Link} to="/crear/oferta" />
    }

  </>;
}

export default Ofertas;

