import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {errorToNotifObj, formatDate, hasPermissionTo, PERSMISOS, removeSpecialCaracter} from '../../helpers';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Paper,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Theme
} from '@mui/material';
import {store} from '../../redux/store';
import Notifications from "react-notification-system-redux";
import makeStyles from '@mui/styles/makeStyles';
import {AppBar, LoadingProgress} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch} from "../../redux/hooks";
import {eliminarPaciente} from "../../redux/reducers/pacientesReducers";
import {IPaciente} from "../../redux/types/IPacientes";
import {IPariente} from "../../redux/types/IPariente";
import ConfirmDialog from "../../components/ConfirmDialog";
import {SEXO, TIPO_DOCUMENTO} from "../../helpers/CONSTANTES";
import Indicaciones from "./Indicaciones";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import SelectPaiProMun from "../../components/selects/SelectPaiProMun";
import moment from "moment";
import {CedulaTextField, DateInput, PhoneTextField} from "../../components/CustomTextField";
import {addPacientePublico} from "../../services"
import {
    errorMessagesC,
    SelectValidatorCustom,
    TextValidatorCustom,
    ValidatorFormCustom
} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(2)}`,
        marginBottom:theme.spacing(1),
        "& h6":{
            marginTop:-10,
            marginBottom:10
        }
    }
}));

let initialState:IPaciente = {
    pacNombres: "",
    pacApellidos: "",
    pacTipoDocumento: undefined,
    pacTipoDocumentoString: "",
    pacDocIdentidad: "",
    tarjetaCodigo:"",
    pacEmail: "",
    pacTelefono: "",
    pacTelefono2: "",
    paisId: 0,
    provinciaId: 0,
    municipioId: 0,
    sector:"",
    sectorId:undefined,
    pacDireccion: "",
    pacComentario: "",
    pacFechaNacimiento: "",
    pacSexo: "",
    pacPuntos: 0,
    planId: 0,
    plan: "",
    usuarioId: 0,
    pacInactivo: true,
    indicaciones: [],
}
const initialPariente:IPariente = {
    accion: 1,
    id: undefined,
    pacienteId: undefined,
    parentescoId: undefined,
    parentesco: "",
    parNombres: "",
    parApellidos: "",
    parSexo: "",
    parTipoDocumento: undefined,
    parTipoDocumentoString: "",
    parDocIdentidad: "",
    parEmail: "",
    parTelefono: "",
    parFechaNacimiento: "",
    parTelefono2: "",
    paisId: undefined,
    pais: "",
    provinciaId: undefined,
    provincia: "",
    municipioId: undefined,
    municipio: "",
    sector:"",
    sectorId:undefined,
    parDireccion: "",
}

function CrearPaciente(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let {tipo} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(initialState);
    const [pariente, setPariente] = useState<IPariente>(initialPariente);


    useEffect(()=> {
        //remove all notificacion
        return ()=> {
            store.dispatch(Notifications.removeAll())
        };
    },[Dispatch])


    useEffect(()=>{
        setState(initialState)
        setPariente(initialPariente)
    },[setState])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: typeof value === 'string'? removeSpecialCaracter(value):value});
    }

    const handleChangePar = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setPariente({...pariente,[name]:  typeof value === 'string'? removeSpecialCaracter(value):value});
    }

    const pacienteData = (
            <Grid container spacing={3}>
                <Grid item xs={6} sm={3}><TextValidatorCustom name={"pacNombres"} label={Strings.nombre} value={state.pacNombres} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                <Grid item xs={6} sm={3}><TextValidatorCustom name={"pacApellidos"} label={Strings.apellidos} value={state.pacApellidos} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                <Grid item xs={6} sm={4}>
                    <Box display={"flex"}>
                        <Box sx={{width: 170, mr: "-1px"}}>
                            <SelectValidatorCustom
                                value={state.pacTipoDocumento}
                                // validators={!!state.pacDocIdentidad?['required']:[]}
                                validators={['required']}
                                name="pacTipoDocumento"
                                errorMessages={errorMessagesC(['required'])}
                                label={Strings.tipo}
                                onChange={handleChange}
                            >
                                <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                {Object.keys(TIPO_DOCUMENTO).filter(i=> i !== "menor")?.map((pro,i)=>
                                    <MenuItem key={i} value={(TIPO_DOCUMENTO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                )}
                                {tipo === "2" &&
                                    <MenuItem value={TIPO_DOCUMENTO.menor}>{Strings.menor}</MenuItem>
                                }
                            </SelectValidatorCustom>
                        </Box>
                        {/*(!!state.pacFechaNacimiento && moment().diff(state.pacFechaNacimiento, 'years') >= 18) ||*/}

                        <CedulaTextField noMasked={state.pacTipoDocumento!==1} name={"pacDocIdentidad"} label={Strings.noIdentificacion}
                                   value={state.pacDocIdentidad}
                                   onChange={handleChange} inputProps={{maxLength: 20}}
                                    validators={ state.pacTipoDocumento !== TIPO_DOCUMENTO.menor? ["required"]:[]}
                                   fullWidth
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <SelectValidatorCustom
                        value={state.pacSexo}
                        validators={['required']}
                        name="pacSexo"
                        errorMessages={errorMessagesC(['required'])}
                        label={Strings.sexo}
                        onChange={handleChange}
                    >
                        {Object.keys(SEXO).map((pro,i)=>
                            <MenuItem key={i} value={(SEXO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                        )}
                    </SelectValidatorCustom>
                </Grid>
                <Grid item xs={6} sm={3}><TextValidatorCustom type={"email"}  name={"pacEmail"}  validators={['isEmail']} label={Strings.email} value={state.pacEmail} onChange={handleChange} inputProps={{maxLength: 30}}  fullWidth /></Grid>
                <Grid item xs={6} sm={!tipo?2:3}><PhoneTextField name={"pacTelefono"} validators={['required']} label={Strings.telefono} value={state.pacTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                <Grid item xs={6} sm={!tipo?2:3}><PhoneTextField name={"pacTelefono2"} label={Strings.celular} value={state.pacTelefono2} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                <Grid item xs={12} sm={!tipo?2:3}><TextField name={"tarjetaCodigo"} label={Strings.codigo+" "+Strings.tarjeta} value={state.tarjetaCodigo} onChange={handleChange} inputProps={{maxLength: 40}}  fullWidth /></Grid>
                <Grid item xs={6} sm={3}>
                    <DateInput
                     maxDate={tipo === "1" ? moment().add(-18,"years") : moment()}
                    name={"pacFechaNacimiento"} label={Strings.fechaNacimiento}
                    value={state.pacFechaNacimiento} onChange={handleChange} validators={['required']} fullWidth />
                </Grid>

                {!tipo && <Grid item xs={6} sm={2}><TextField label={Strings.punto} defaultValue={state.pacPuntos} disabled fullWidth /></Grid>}
                <Grid item xs={6} sm={tipo?3:4}>
                    <SelectSearchPlan
                        required
                        value={{
                            planId: (state.planId ?? "").toString(),
                            plan: state.plan ?? ""
                        }}
                        onChange={(v)=>setState(prevState => ({
                            ...prevState, indicaciones:[],
                            plan:v.plan,
                            planId:v.planId?parseInt(v.planId):undefined
                        }))}
                    />
                </Grid>
                <SelectPaiProMun required value={{
                    pais:state.pais,
                    paisId:state.paisId,
                    provincia:state.provincia,
                    provinciaId:state.provinciaId,
                    municipio:state.municipio,
                    municipioId:state.municipioId,
                    sector:state.sector,
                    sectorId:state.sectorId,
                }} onChange={(val)=>setState(prev=>({...prev,...val}))} />

                <Grid item xs={12} sm={6}><TextValidatorCustom name={"pacDireccion"} validators={['required',"matchRegexp:^[A-zÀ-ú0-9 \\/#,.]*$"]} label={Strings.direccion} value={state.pacDireccion} onChange={handleChange} inputProps={{maxLength: 100}}  fullWidth /></Grid>
                <Grid item xs={12} sm={6}><TextField name={"pacComentario"} label={Strings.comentario} value={state.pacComentario} onChange={handleChange} inputProps={{maxLength: 200}}  fullWidth /></Grid>

            </Grid>
    )
    const parienteData = (
            <Grid container spacing={2} >
                <Grid item xs={6} sm={2} sx={{mb:2}}>
                    <SelectUsoMultiples
                        label={Strings.parentesco}
                        value={pariente.parentescoId?.toString() ?? ""}
                        onChange={(v)=>setPariente(prevpariente => ({
                            ...prevpariente,
                            parentescoId:v.key,
                            parentesco:v.value
                        }))}
                        group={"Parentescos"}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={3}><TextValidatorCustom name={"parNombres"} label={Strings.nombre} value={pariente.parNombres} onChange={handleChangePar} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                <Grid item xs={6} sm={3}><TextValidatorCustom name={"parApellidos"} label={Strings.apellidos} value={pariente.parApellidos} onChange={handleChangePar} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                <Grid item xs={6} sm={4}>
                    <Box display={"flex"}>
                        <Box sx={{width: 170, mr: "-1px"}}>
                            <SelectValidatorCustom
                                value={pariente.parTipoDocumento}
                                validators={['required']}
                                name="parTipoDocumento"
                                errorMessages={errorMessagesC(['required'])}
                                label={Strings.tipo}
                                onChange={handleChangePar}
                            >
                                <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                {Object.keys(TIPO_DOCUMENTO).filter(i=> i !== "menor")?.map((pro,i)=>
                                    <MenuItem key={i} value={(TIPO_DOCUMENTO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                )}
                            </SelectValidatorCustom>
                        </Box>

                        <CedulaTextField noMasked={pariente.parTipoDocumento!==1}
                                         name={"parDocIdentidad"} label={Strings.cedula} value={pariente.parDocIdentidad}
                                         onChange={handleChangePar} inputProps={{maxLength: 20}}
                                         validators={['required']}
                                         fullWidth />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <SelectValidatorCustom
                                           value={pariente.parSexo}
                                           validators={['required']}
                                           name="parSexo"
                                           errorMessages={errorMessagesC(['required'])}
                                           label={Strings.sexo}
                                           onChange={handleChangePar}
                    >
                        <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                        {Object.keys(SEXO).map((pro,i)=>
                            <MenuItem key={i} value={(SEXO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                        )}
                    </SelectValidatorCustom>
                </Grid>
                <Grid item xs={6} sm={3}><TextValidatorCustom type={"email"} name={"parEmail"} label={Strings.email} value={pariente.parEmail} onChange={handleChangePar} inputProps={{maxLength: 30}} validators={['isEmail']}  fullWidth /></Grid>
                <Grid item xs={6} sm={3}><PhoneTextField name={"parTelefono"} label={Strings.telefono} value={pariente.parTelefono} onChange={handleChangePar} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                <Grid item xs={6} sm={3}><PhoneTextField name={"parTelefono2"} label={Strings.celular} value={pariente.parTelefono2} onChange={handleChangePar} inputProps={{maxLength: 15}}  fullWidth /></Grid>



                <Grid item xs={6} sm={3}>
                    <DateInput
                        maxDate={moment().add(-18,"years")}
                        name={"parFechaNacimiento"} label={Strings.fechaNacimiento}
                        value={formatDate(pariente.parFechaNacimiento,"yyyy-MM-DD")}
                        onChange={handleChangePar} validators={['required']} fullWidth />
                </Grid>
                <SelectPaiProMun required value={{
                    pais:pariente.pais,
                    paisId:pariente.paisId,
                    provincia:pariente.provincia,
                    provinciaId:pariente.provinciaId,
                    municipio:pariente.municipio,
                    municipioId:pariente.municipioId,
                    sector:pariente.sector,
                    sectorId:pariente.sectorId,
                }} onChange={(val)=>setPariente(prev=>({...prev,...val}))} />

                <Grid item xs={12} sm={6}><TextValidatorCustom name={"parDireccion"} validators={['required',"matchRegexp:^[A-zÀ-ú0-9 \\/#,.]*$"]} label={Strings.direccion} value={pariente.parDireccion} onChange={handleChangePar} inputProps={{maxLength: 100}}  fullWidth /></Grid>

            </Grid>

    )


    const getSteps = ()=>{
        let steps = ['Paciente', 'Pariente', 'Indicaciones'];

        if(tipo === "1"){
            steps = ['Paciente', 'Indicaciones']
        }else if(tipo === "2"){
            steps = ['Pariente','Paciente', 'Indicaciones'];
        }
        return steps;
    }



    const render = ()=>{
        if(tipo === "1"){
            return(
                <>{activeStep === 0 && <>{pacienteData}</>}</>
            )

        }else if(tipo === "2"){
            return(
                <>
                    {activeStep === 0 &&
                        <>{parienteData}</>
                    }
                    {activeStep === 1 &&
                        <>{pacienteData}</>
                    }
                </>
            )
        }else{
            return(
                <>
                    {activeStep === 0 &&
                        <>{pacienteData}</>
                    }
                    {activeStep === 1 &&
                        <>{parienteData}</>
                    }
                </>
            )
        }
    }





    //.....

    const [activeStep, setActiveStep] = React.useState(0);


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const submit = () =>{
        if(activeStep === getSteps().length - 1){
            setLoading(true)
            let pacienteObj = {...state}
            if(tipo !== "1") pacienteObj.pariente = pariente;
            addPacientePublico({...pacienteObj,tipoRegistro: parseInt(tipo??"1")}).then(()=>{
                store.dispatch(Notifications.success({
                    title:"Registrado con éxito",
                    message:"Le hemos enviado más información de su registro por correo electrónico.",
                    position: 'tc',
                    autoDismiss: 10,
                    action: {
                        label: 'Ir al Inicio',
                        callback: () => navigate("/login")
                      }
                    }))
                setState(initialState)
                setPariente(initialPariente)
            }).catch(error=>{
                store.dispatch(Notifications.error(errorToNotifObj(error.response?.data)));
            }).finally(()=>setLoading(false))
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }


    return(
        <Fragment>
            {!pathname.includes("pacienteForm") &&
             <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.paciente}/>
            }

            <div className={"contentInner "+classes.root} >
                {loading && <LoadingProgress vh/>}

                <div className={"content"}>

                    <Stepper sx={{mb:3}} activeStep={activeStep}>
                        {getSteps().map((label, index) => {
                            const stepProps:{completed?:boolean} = {};
                            const labelProps:{optional?:any} = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>


                        <Paper  className={classes.paper}>
                            <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                                {render()}
                            </ValidatorFormCustom>
                        {Boolean(activeStep === getSteps().length - 1 && state.planId) &&

                            <Indicaciones indicaciones={state.indicaciones}
                                          onChange={v => setState({...state, indicaciones: v})} planId={state.planId}/>
                        }

                        </Paper>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Ir Atras
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button disabled={loading} type="submit" form="form1" value="Submit">
                            {loading? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{activeStep === getSteps().length - 1? Strings.guardar:"Siguiente"}</span>
                            )}
                        </Button>

                    </Box>
                    <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                        {!!state.id &&
                            <Button onClick={()=>{
                                if(!hasPermissionTo(PERSMISOS.pacientesActualizar)) return false;
                                navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                    color="inherit" sx={{marginRight:"auto"}}>Eliminar registro</Button>}
                        <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>

                    </Box>
                </div>
            <br/>
            </div>
            {!!urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarPaciente(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearPaciente;
