import React, {useEffect, useState,useCallback} from 'react';
import {MostrarError} from "../../components/MostrarError";
import {mostrarPaises} from '../../redux/reducers/paisesReducers';
import AppBar from "../../components/appBar";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Breadcrumbs,Typography} from "@mui/material";
import Strings from "../../assets/strings"
import LoadingProgress from "../../components/LoadingProgress";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {Empty} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/collaborators.svg";
import {getMunicipiosPorProvincias, getProvinciasPorPais, getSectoresPorMunicipios} from "../../services";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {IIdNameAccion} from "../../redux/types/Icommon";

function  Paises() {
    const Dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    let { paiId, proId, munId} = useParams()
    const [loading, setLoading] = useState<boolean>(false)

    const [listPro, setListPro] = useState<IIdNameAccion[]>()
    const [listMun, setListMun] = useState<IIdNameAccion[]>()
    const [listSec, setListSec] = useState<IIdNameAccion[]>()



    const {list, status, error} = useAppSelector(state => state.paises)
    useEffect(() => {
        Dispatch(mostrarPaises());
    }, [Dispatch])


    const getProvincias = useCallback(
        (id:string) => {
            setLoading(true)
            getProvinciasPorPais(id).then((res)=>{
                setListPro(res.data)
            }).finally(()=>setLoading(false))
        },
        [setLoading, setListPro],
    );


    const getMunicipios = useCallback(
        (id:string) => {
            setLoading(true)
            getMunicipiosPorProvincias(id).then((res)=>{
                setListMun(res.data)
            }).finally(()=>setLoading(false))
        },
        [setLoading, setListMun],
    );

    const getSectores = useCallback(
        (id:string) => {
            setLoading(true)
            getSectoresPorMunicipios(id).then((res)=>{
                setListSec(res.data)
            }).finally(()=>setLoading(false))
        },
        [setLoading, setListSec],
    );


    useEffect(()=>{
        if(paiId) {
            getProvincias(paiId);
        }else {
            setListPro(undefined)
        }


        if(proId) {
            getMunicipios(proId)
        }else {
            setListMun(undefined)
        }

        if(munId) {
            getSectores(munId)
        }else {
            setListSec(undefined)
        }

    },[paiId,proId,munId,setListMun,setListPro,setListSec,getProvincias,getMunicipios,getSectores])

    const isEmpty = list?.length === 0
    const title = () => {
        if(munId){
            return Strings["sectores"]
        }else if(proId){
            return Strings["municipios"]
        }else if(paiId){
            return Strings["provincias"]
        }else{
            return Strings["paises"]
        }
    }
    return(
        <>
            <AppBar title={title()}></AppBar>
            <section className="contentInner" style={(status==="loading")?{opacity:.8}:{opacity:1}}>
                {!!listPro?.length &&
                    <Breadcrumbs aria-label="breadcrumb">
                        {list?.length && <Typography  onClick={()=>navigate("/paises")} sx={{cursor:"pointer"}}>
                            {list.find(p=>p.id===Number(paiId))?.name}
                        </Typography>}
                        <Typography onClick={()=>navigate("/paises/" + paiId)} sx={{cursor:"pointer"}}>
                            {listPro.find(p=>p.id===Number(proId))?.name}
                        </Typography>
                        {!!listMun?.length &&
                        <Typography onClick={()=>navigate("/paises/" + paiId+"/"+proId)} sx={{cursor:"pointer"}}>
                            {listMun.find(p=>p.id===Number(munId))?.name}
                        </Typography>
                        }
                    </Breadcrumbs>
                }

                <MostrarError errors={error}/>
                {(status === "loading" || loading) && <LoadingProgress vh/>}
                {isEmpty && status !== "loading" ? (
                    <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.paises}>
                        <p>{Strings.agentesHelpMsj}</p>
                    </Empty>
                ):(
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>{Strings.descripcion}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isEmpty && (listSec || listMun || listPro || list)?.map(item =>
                                    <TableRow key={item.id} onClick={()=>{
                                        if (listSec?.length) return false;
                                        navigate(location.pathname+"/"+item.id)
                                    }} {...(!listSec ? {sx: {cursor: "pointer"}}:{})}>
                                        <TableCell> {item.id}</TableCell>
                                        <TableCell> {item.name}</TableCell>
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </section>
        </>
    )


}

export default Paises;
