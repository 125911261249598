import React, {useState, useEffect} from 'react';
import {agregarEditarArticuloMarca} from "../../../redux/reducers/articulosMarcaReducers";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../../helpers";
import LoadingProgress from "../../../components/LoadingProgress";
import Strings from "../../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {TextValidatorCustom, ValidatorFormCustom} from "../../../helpers/form-validator";

const initialState = {
    marNombre: "",
};
export default function CrearMarca() {
    const Dispatch = useAppDispatch();
    const navigate = useNavigate();
    const status = useAppSelector(state => state.articulosMarcas.status)
    const {state:urlState={}} = useLocation();
    const [state, setState] = useState(initialState);


    useEffect(() => {
        if(!!urlState && urlState.id) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = () => {
        let params = {id:state.id,name:state.marNombre,accion:1}
        if(params.id) params.accion = 2;
        Dispatch(agregarEditarArticuloMarca(params));
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))} onClose={()=>navigate(-1)} maxWidth={"xs"} fullWidth={true}>
                    <DialogContent>
                        {(status === "loading") && <LoadingProgress />}
                        <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                            <TextValidatorCustom
                                value={state.marNombre}
                                onChange={(e) => setState({ ...state, marNombre: e.target.value })}
                                inputProps={{maxLength: 50}}
                                autoFocus
                                autoComplete={"off"}
                                label={Strings.descripcion}
                                validators={['required']}
                                type="text"
                                fullWidth
                            />
                        </ValidatorFormCustom>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                        <Button type="submit" form={"form1"} color="primary" disabled={status === "loading"}>
                            {!!state.id ? Strings.actualizar : Strings.guardar}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
