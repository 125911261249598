import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    Box,
    Typography, List, ListItem, ListItemText, Theme, Grid
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ICompra} from "../../redux/types/ICompra";
import Strings from "../../assets/strings";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import {mostrarCompraById} from "../../redux/reducers/comprasReducers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
const useStyles = makeStyles((theme:Theme) => ({
    top:{
        display:"flex",
        justifyContent: "space-between",
        color:theme.palette.secondary.main,
        background:theme.palette.grey[200],
        alignItems: "center",
        marginTop:theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    clienteCont:{
        borderBottom:"1px solid "+theme.palette.grey[300],
        display:"flex",
        justifyContent:"space-between",
        margin:theme.spacing(0.5),
        padding:theme.spacing(1),
        "& .inputCli":{
            width:"100%",
            marginRight:theme.spacing(1),
        },
        "& .btnNew":{
            padding: "0px 15px",
            fontSize: "0.8em",
            "& svg":{
                marginLeft:5
            }
        }
    },
    total:{
        position:"absolute",
        bottom:0,
        width:"100%",
        background:theme.palette.grey[100],
        padding: 0,
    }
}));



export default function DetalleCompra() {
    const {byId,status} = useAppSelector(state => state.compras);

    const {state:urlState}:{state:any} = useLocation();
    const navigate =useNavigate();
    const classes = useStyles();
    const Dispatch = useAppDispatch()

    const goBack = () => {
        navigate(-1)
    }

    const [state, setState] = useState<ICompra|null>(null);

    useEffect(() => {
        if(!!urlState && urlState.compra) {
            setState(state => ({...state, ...(nullToEmptyString(urlState.compra) as ICompra)}));
        }else {
            setState(null);
        }
    }, [setState, urlState]);


    useEffect(()=>{
        if(urlState?.compra && urlState.compra.id !== byId?.id){
            Dispatch(mostrarCompraById(Number(urlState.compra.id)));
        }else if(byId){
            setState(state => ({...state, ...nullToEmptyString(byId) as ICompra}));
        }
    },[byId,setState,urlState,Dispatch])

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("compra"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title={Strings.compra} nofullScreen onClose={goBack}/>
                <DialogContent sx={{p:0.5}}>
                    {status === "loading" && <LoadingProgress />}
                    {!!state &&
                        <>
                            <Box sx={{p:1}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{formatDate(state.fechaCreacion)}</Typography>
                                        <Typography variant={"caption"}>{Strings.fecha}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.paciente}</Typography>
                                        <Typography variant={"caption"}>{Strings.paciente}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.farmacia}</Typography>
                                        <Typography variant={"caption"}>{Strings.farmacia}</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.plan}</Typography>
                                        <Typography variant={"caption"}>{Strings.plan}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.comInactivo?Strings.inactivo:Strings.activo}</Typography>
                                        <Typography variant={"caption"}>{Strings.estado}</Typography>
                                    </Grid>
                                    {state.autorizacionEstadoString &&
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{state.autorizacionEstadoString}</Typography>
                                            <Typography variant={"caption"}>{Strings.autorizacion}</Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={6}>
                                        <Typography variant="body2">{state.comPuntosGenerados}</Typography>
                                        <Typography variant={"caption"}>{Strings.puntos}</Typography>
                                    </Grid>
                                    {state.comNota &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{state.comNota}</Typography>
                                            <Typography variant={"caption"}>{Strings.comentario}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            {state.detalle &&
                                <>
                                    <div className={classes.top}>
                                        <Typography noWrap>{state.detalle?.length} {Strings.articulos}</Typography>
                                    </div>
                                    <div>
                                        <List dense>
                                            {state.detalle?.map((p)=>
                                                <React.Fragment key={p.articuloId}>
                                                    <ListItem>
                                                        <ListItemText
                                                            sx={p.accion===3?{textDecoration: "line-through",color: "#a91a1a"}:{}}
                                                            secondary={`${Strings.cantidad}: `+p.comCantidad+(!!p.comCantidadDetalle? ` / ${Strings.comprimidos}: `+p.comCantidadDetalle:" ")
                                                                +(!!p.comPuntos? ` / ${Strings.puntos}: `+p.comPuntos:"")
                                                                +(!!p.comIndicadorVentaLibre? " ("+Strings.ventaLibre+")":"")}
                                                        >
                                                            {p.articulo?.replace(/(.{35})..+/, "$1…")}
                                                        </ListItemText>
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>
                                            )}
                                        </List>
                                    </div>
                                </>
                            }

                        </>
                    }
                    <br/>
                </DialogContent>
            </Dialog>
        </>
    );

}

