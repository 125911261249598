import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Theme, useMediaQuery} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CounterItem from "./CounterItem";
import {AttachMoneyOutlined} from "@mui/icons-material";
import {getPacienteDashboard, getPacientesListSimple} from "../../services";
import {getTokenInfo} from "../../services/auth";
import {useAppSelector} from "../../redux/hooks";
import {getUsuTipo, TIPOUSUARIO, translateString} from "../../helpers";
import SelectSearchPaciente from "../selects/SelectSearchPaciente";
import Strings from "../../assets/strings";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme:Theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(1)
        },
    }
}));

type listProps = {
    key?:string,
    value?:string
}
type pacienteProps = {
    pacienteId?:number,
    paciente?:string
}
export default function HomeCounter() {
    const classes = useStyles();
    const xsVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('md'));
    const [list, setList] = useState<listProps[]>([]);
    const [paciente, setPaciente] = useState<pacienteProps>({
        pacienteId:undefined,
        paciente:""
    });

    const fetchData = useCallback(()=>{
        if(paciente.pacienteId){
            getPacienteDashboard(paciente.pacienteId).then(res=>{
                if(getUsuTipo() === TIPOUSUARIO.paciente){
                    let data:listProps[] = [];
                    (res.data ?? []).forEach((e:listProps)=>{
                        if(e.key === "Notificaciones"){
                            if(!!e.value){
                                return false
                            }
                        }
                        data.push(e)
                    })
                    setList(data)
                }else{
                    setList(res.data)
                }
            })
        }else{
            getPacientesListSimple().then(res=>{
                if(res.data?.results?.length){
                    let pa = res.data?.results[0]
                    setPaciente({
                        pacienteId:pa.id,
                        paciente:pa.pacNombres
                    })
                }
            })
        }
    },[setList, paciente, setPaciente])

    useEffect(()=>{
        fetchData()
    },[fetchData])

    const user = useAppSelector(state=>state.auth.user)

    useEffect(()=>{
        if(user?.usuTipo === TIPOUSUARIO.paciente){
            setPaciente({pacienteId:(getTokenInfo() as any)["RelatedId"],paciente:user.usuNombre})
        }
    },[user, setPaciente])

    return (
        <>
            {getUsuTipo() === TIPOUSUARIO.pariente &&
                <Box sx={{
                    mb: 1,
                    maxWidth: 350,
                }}>
                    <ValidatorFormCustom onSubmit={() => {
                    }}>
                        <SelectSearchPaciente
                            required
                            label={Strings.paciente}
                            disabled={user?.usuTipo === TIPOUSUARIO.paciente}
                            value={{
                                id: paciente.pacienteId,
                                pacNombres: paciente.paciente
                            }}
                            onChange={(v) => {
                                setPaciente(prevState => ({
                                    ...prevState,
                                    paciente: v.pacNombres ?? "",
                                    pacienteId: v.id,
                                }))
                            }}
                        />
                    </ValidatorFormCustom>
                </Box>
            }
            <Grid container spacing={xsVewport?1:2} className={"d-print-none " + classes.root}>
            {list?.map(item=>
                <Grid key={item.key} item xs={6} md={3}>
                    <CounterItem title={translateString(item.key)} subTitle={item.value} Icon={AttachMoneyOutlined}/>
                </Grid>
            )}

            {/*<Grid item xs={6} md={3}>*/}
            {/*    <CounterItem title={"Puntos canjeados"} subTitle={122} Icon={DescriptionOutlined} color={"#ffc36d"}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} md={3}>*/}
            {/*    <CounterItem title={"Puntos en tránsito"} subTitle={formatPeso(123)} Icon={AttachMoneyOutlined} color={"#54e69d"}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} md={3}>*/}
            {/*    <HtmlTooltip title={""}>*/}
            {/*        <div>*/}
            {/*            <CounterItem title={"Puntos en tránsito"}*/}
            {/*                     subTitle={formatPeso(123)} Icon={BarChart} color={"#796AEE"}/>*/}
            {/*        </div>*/}
            {/*    </HtmlTooltip>*/}
            {/*</Grid>*/}
        </Grid>
        </>
    )
}

