import { Box, Button, CircularProgress, Grid, MenuItem, Paper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Strings from "../../assets/strings";
import { AppBar, LoadingProgress } from "../../components";
import ConfirmDialog from "../../components/ConfirmDialog";
import { CedulaTextField, DateInput, PhoneTextField } from "../../components/CustomTextField";
import SelectPaiProMun from "../../components/selects/SelectPaiProMun";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import {formatDate, getUsuTipo, TIPOUSUARIO, hasPermissionTo, nullToEmptyString, removeSpecialCaracter} from '../../helpers';
import { PERSMISOS, SEXO, TIPO_DOCUMENTO } from "../../helpers/CONSTANTES";
import { errorMessagesC, SelectValidatorCustom, TextValidatorCustom, ValidatorFormCustom } from "../../helpers/form-validator";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { agregarEditarPariente, eliminarPariente as pacEliminarPariente } from "../../redux/reducers/pacientesReducers";
import { IPariente } from "../../redux/types/IPariente";
import {eliminarPariente, mostrarParienteById} from "../../redux/reducers/parientesReducers";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(2)}`,
        marginBottom:theme.spacing(1),
        "& h6":{
            marginTop:-10,
            marginBottom:10
        }
    }
}));

const initialState:IPariente = {
    accion: 1,
    id: undefined,
    pacienteId: undefined,
    parentescoId: undefined,
    parentesco: "",
    parNombres: "",
    parApellidos: "",
    parSexo: "",
    parTipoDocumento: undefined,
    parTipoDocumentoString: "",
    parDocIdentidad: "",
    parEmail: "",
    parTelefono: "",
    parFechaNacimiento: "",
    parTelefono2: "",
    paisId: undefined,
    pais: "",
    provinciaId: undefined,
    provincia: "",
    municipioId: undefined,
    municipio: "",
    sector:"",
    sectorId:undefined,
    parDireccion: "",
}

function CrearPariente(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const { id:urlParId, pacId:urlPacId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:pariente,status} = useAppSelector(state => state.parientes);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlParId) Dispatch(mostrarParienteById(Number(urlParId)));
    },[urlParId,Dispatch])

    useEffect(()=>{
        if(!!urlParId && pariente?.id === parseInt(urlParId)) {
            setState({...initialState, ...nullToEmptyString(pariente)} as IPariente);
        }else{
            setState(initialState)
        }
    },[urlParId,pariente])

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]:  typeof value === 'string'? removeSpecialCaracter(value):value});

    }

    const submit = () =>{
        Dispatch(agregarEditarPariente(state));
    }

    return(
        <Fragment>
             <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.pariente} backUrl={urlPacId?"/detalles/paciente/"+urlPacId:"/"}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <Paper  className={classes.paper}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} sm={3} sx={{mb:2}}>
                                    <SelectUsoMultiples
                                        label={Strings.parentesco}
                                        value={state.parentescoId?.toString() ?? ""}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            parentescoId:v.key,
                                            parentesco:v.value
                                        }))}
                                        group={"Parentescos"}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}><TextValidatorCustom name={"parNombres"} label={Strings.nombre} value={state.parNombres} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><TextValidatorCustom name={"parApellidos"} label={Strings.apellidos} value={state.parApellidos} onChange={handleChange} inputProps={{maxLength: 50}} validators={['required']} fullWidth /></Grid>
                                <Grid item xs={6} sm={3}>
                                    <Box display={"flex"}>
                                        <Box sx={{width: 170, mr: "-1px"}}>
                                            <SelectValidatorCustom
                                                disabled={getUsuTipo() !== TIPOUSUARIO.admin}
                                                value={state.parTipoDocumento}
                                                validators={!!state.parDocIdentidad?['required']:[]}
                                                name="parTipoDocumento"
                                                errorMessages={errorMessagesC(['required'])}
                                                label={Strings.tipo}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                                {Object.keys(TIPO_DOCUMENTO).map((pro,i)=>
                                                    <MenuItem key={i} value={(TIPO_DOCUMENTO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                                )}
                                            </SelectValidatorCustom>
                                        </Box>
                                        <Box width={"100%"}>

                                            <CedulaTextField noMasked={state.parTipoDocumento!==1} name={"parDocIdentidad"}
                                                             disabled={getUsuTipo() !== TIPOUSUARIO.admin}
                                                             label={Strings.cedula} value={state.parDocIdentidad} onChange={handleChange} inputProps={{maxLength: 20}} validators={['required']}  fullWidth />
                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <SelectValidatorCustom
                                        value={state.parSexo}
                                        validators={['required']}
                                        name="parSexo"
                                        errorMessages={errorMessagesC(['required'])}
                                        label={Strings.sexo}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>{Strings.seleccione}</MenuItem>
                                        {Object.keys(SEXO).map((pro,i)=>
                                            <MenuItem key={i} value={(SEXO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                        )}
                                    </SelectValidatorCustom>
                                </Grid>
                                <Grid item xs={6} sm={3}><TextValidatorCustom type={"email"} name={"parEmail"} label={Strings.email} value={state.parEmail} onChange={handleChange} inputProps={{maxLength: 30}} validators={['isEmail']}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><PhoneTextField name={"parTelefono"} label={Strings.telefono} value={state.parTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}><PhoneTextField name={"parTelefono2"} label={Strings.celular} value={state.parTelefono2} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth /></Grid>
                                <Grid item xs={6} sm={3}>
                                    <DateInput
                                        maxDate={moment().add(-18,"years")}
                                        name={"parFechaNacimiento"} label={Strings.fechaNacimiento}
                                        value={formatDate(state.parFechaNacimiento,"yyyy-MM-DD")}
                                        onChange={handleChange} validators={['required']} fullWidth />
                                </Grid>
                                <SelectPaiProMun required value={{
                                    pais:state.pais,
                                    paisId:state.paisId,
                                    provincia:state.provincia,
                                    provinciaId:state.provinciaId,
                                    municipio:state.municipio,
                                    municipioId:state.municipioId,
                                    sector:state.sector,
                                    sectorId:state.sectorId,
                                }} onChange={(val)=>setState(prev=>({...prev,...val}))} />
                                <Grid item xs={12} sm={6}><TextValidatorCustom name={"parDireccion"} validators={['required',"matchRegexp:^[A-zÀ-ú0-9 \\/#,.]*$"]} label={Strings.direccion} value={state.parDireccion} onChange={handleChange} inputProps={{maxLength: 100}}  fullWidth /></Grid>
                            </Grid>
                        </Paper>
                    </ValidatorFormCustom>
                    <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                        {!!state.id && hasPermissionTo(PERSMISOS.parientesActualizar) &&
                            <Button onClick={()=>navigate(pathname, {state: {"confirmDialog": {pacId:urlPacId,parId:state.id}}})}
                                    color="inherit" sx={{marginRight:"auto"}}>{Strings.eliminar}</Button>
                        }

                        <Button color="inherit" onClick={()=>navigate(-1)}>{Strings.cancelar}</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{Strings.guardar}</span>
                            )}
                        </Button>
                    </Box>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=> {
                    Dispatch(urlPacId ? pacEliminarPariente(urlState.confirmDialog) : eliminarPariente(state.id!))
                }}/>
            }
        </Fragment>
    );

}
export default CrearPariente;
