import React, {Suspense,lazy} from 'react';
import {Routes, Route, Router, BrowserRouterProps} from "react-router-dom";
import type { BrowserHistory} from "history";

import './App.scss';
import {useMediaQuery, useTheme} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import {getColor} from "./assets/colors";

import Login from "./views/cuenta/login";
import ForgotPassword from "./views/cuenta/ForgotPassword";

import PacienteForm from "./views/publicos/PacienteForm";

import LoadingProgress from "./components/LoadingProgress";
import {useAppSelector} from "./redux/hooks";
import historyR from "./helpers/history";

const Layouts = lazy(() => import('./layouts/index'));


function App() {
  const auth = useAppSelector(state => state.auth)

  const usuUIcolor = "#003789";
  const smVewport = useMediaQuery(useTheme().breakpoints.down('sm'));

  let theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
    },
    components: {
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      ...(!smVewport? {
        MuiInputLabel:{
          styleOverrides:{
            root: {
              "&:not([class*='-outlined'])":{
                lineHeight: 1,
                position: "initial !important",
                transform: "none !important",
                color: "inherit !important",
                marginBottom: "10px !important",

              },
              "&.Mui-error":{
                color:"#d32f2f !important",
                "&+div":{
                  borderColor: "#d32f2f !important",
                }
              }

            },
          }
        },
        MuiInputBase:{
          styleOverrides:{
            root:{
              background: "white",
              "&:not([class*='MuiOutlinedInput'])":{
                border: "1px solid #e6e6e6",
                marginTop: "0px !important",
                "& [class*='-input']:not([class*='Pagination'])":{ //:not(select)
                  padding: "6px 15px",
                },
                "&:before, &:after":{
                  display:"none !important",
                }
              }
            },
            adornedEnd:{
              paddingRight: 10
            }
          }
        }
      }:{}),
      MuiButton: {
        styleOverrides:{
          root: {
            borderRadius: "0"
          },
        }
      },
      MuiTableRow: {
        styleOverrides:{
          head: {
            height: 34,
          },
          root: {
            //height: 44,
          },
          footer:{
            cursor: "auto !important",
          }
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root:{
            "&:not([class*='sizeSmall'])":{
              height: 44,
              padding: ".5rem",
              boxSizing: "border-box",
            },
          },
          head: {
            color: usuUIcolor,
            background:"#f6f8fc",
            //borderBottom: usuUIcolor + " 2px solid"
          },
          body: {
          },
          footer:{
            fontSize: "0.85rem"
          }
        }
      },
    },
    palette: {
      // mode: 'dark',
      ...getColor(usuUIcolor),
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    mixins: {
      toolbar: {
        minHeight: 50,
      }
    },
  }, esES);

  return (
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider  theme={theme}>
            <Suspense fallback={<LoadingProgress/>}>
              <BrowserRouter>
                <Routes>

                  {auth.isAuthenticated? (
                      <Route path={"*"} element={<Layouts />}/>
                  ):(
                      <Route path="/" element={auth.status === "loading"? <LoadingProgress/>:<Login/>}/>
                  )}

                  <Route path="/login" element={<Login/>}/>

                  <Route path="/pacienteForm/:tipo" element={<PacienteForm/>}/>

                  <Route path="/pacienteForm" element={<PacienteForm/>}>
                    <Route path=":tipo" element={<PacienteForm/>}/>
                  </Route>


                  <Route path={"/forgotPasswordConfirm"} element={<ForgotPassword/>}/>
                  <Route path={"/forgotPassword"} element={<ForgotPassword/>}/>

                  {/*<Route*/}
                  {/*    path="*"*/}
                  {/*    element={<Navigate to="/" replace />}*/}
                  {/*/>*/}

                </Routes>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider >
        </StyledEngineProvider>
      </div>
  );
}

export default App;


export function BrowserRouter({basename, children,}: BrowserRouterProps) {

  let historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = historyR;
  }

  let history = historyRef.current;
  let [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
      <Router
          basename={basename}
          children={children}
          location={state.location}
          navigationType={state.action}
          navigator={history}
      />
  );
}
