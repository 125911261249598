import { Box, Button, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, Paper, Switch, Theme } from '@mui/material';
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Strings from "../../assets/strings";
import { AppBar, LoadingProgress } from "../../components";
import ConfirmDialog from "../../components/ConfirmDialog";
import { DateInput } from "../../components/CustomTextField";
import { formatDate, hasPermissionTo, nullToEmptyString, PERSMISOS } from '../../helpers';
import { TextValidatorCustom, ValidatorFormCustom } from "../../helpers/form-validator";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { agregarEditarPlan, eliminarPlan, mostrarPlanById } from "../../redux/reducers/planesReducers";
import { IPlan } from "../../redux/types/IPlanes";
import PlanDetalles from "./PlanDetalles";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IPlan = {
    plaNombre: "",
    plaDescripcion: "",
    plaFechaInicio: formatDate(new Date(),"YYYY-MM-DD"),
    plaFechaVencimiento: "",
    plaInactivo:false,
    consecutivo:{
        conPrefijo: "",
        conLongitud: 0,
        conSecuencia: 0,
    },
    detalle: [],
}
function CrearPlan(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlplaId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:plan,status} = useAppSelector(state => state.planes);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlplaId) Dispatch(mostrarPlanById(Number(urlplaId)));
    },[urlplaId,Dispatch])
    useEffect(()=>{
        if(!!urlplaId && plan?.id === parseInt(urlplaId)) {
            setState({...initialState, ...nullToEmptyString(plan)});
        }else{
            setState(initialState)
        }
    },[urlplaId,plan])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarPlan(state));
    }
    return(
        <Fragment>
            <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.plan}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        name={"plaNombre"}
                                        label={Strings.nombre}
                                        value={state.plaNombre}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        name={"plaDescripcion"}
                                        label={Strings.descripcion}
                                        value={state.plaDescripcion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {/*// @ts-ignore*/}
                                    <DateInput
                                        name={"plaFechaInicio"}
                                        label={Strings.fechaInicio}
                                        value={state.plaFechaInicio}
                                        onChange={handleChange}
                                        validators={['required']}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {/*// @ts-ignore*/}
                                    <DateInput
                                        name={"plaFechaVencimiento"}
                                        label={Strings.fechaVencimiento}
                                        value={state.plaFechaVencimiento}
                                        onChange={handleChange}
                                        validators={['required']}
                                    />
                                </Grid>
                                       
                            </Grid>

                                <Typography color={"secondary"} sx={{mt:1.5,mb:1}} variant={"body1"}>Consecutivo Tarjeta:</Typography>
                                <Divider sx={{mb:2}}/>

                                <Grid container spacing={3}>
                                    <Grid item xs={4} sm={2}>
                                        <TextValidatorCustom
                                            validators={['required']}
                                            name={"conPrefijo"}
                                            disabled={!!state.id}
                                            label={Strings.prefijo}
                                            value={state.consecutivo?.conPrefijo ?? ""}
                                            onChange={({target}:any)=>setState({...state,consecutivo:{...state.consecutivo,conPrefijo: target.value}})}
                                            inputProps={{maxLength: 10}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                    <TextValidatorCustom
                                            validators={['required']}
                                            name={"conLongitud"}
                                            disabled={!!state.id}
                                            type={"number"}
                                            label={Strings.longitud}
                                            value={state.consecutivo?.conLongitud ?? ""}
                                            onChange={({target}:any)=>setState({...state,consecutivo:{...state.consecutivo,conLongitud: parseInt(target.value)}})}
                                            inputProps={{max: 20,min:0}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                    <TextValidatorCustom
                                            validators={['required']}
                                            name={"conSecuencia"}
                                            disabled={!!state.id}
                                            type={"number"}
                                            label={Strings.secuencia}
                                            value={state.consecutivo?.conSecuencia ?? ""}
                                            onChange={({target}:any)=>setState({...state,consecutivo:{...state.consecutivo,conSecuencia: parseInt(target.value)}})}
                                            inputProps={{max: 20,min:0}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                            <FormGroup> 
                                                <FormControlLabel control={<Switch checked={state.plaInactivo} color={"secondary"}
                                                                                   onChange={({target})=>setState({...state, plaInactivo:target.checked})} />}
                                                                  labelPlacement="end" label={Strings.inactivo}/>
                                            </FormGroup>
                                        </Grid>
                                </Grid>

                        </Paper>
                    </ValidatorFormCustom>
                        <Paper className={classes.paper}>
                            <PlanDetalles detalles={state.detalle} onChange={v => setState({...state, detalle: v})}/>
                        </Paper>
                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {state.id !== undefined &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.planesEliminar)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="inherit" sx={{marginRight:"auto"}}>Eliminar</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>


                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarPlan(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearPlan;
