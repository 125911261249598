import React, {useEffect} from 'react';
import {MenuItem} from "@mui/material";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {mostrarArticulosCategorias} from "../../redux/reducers/articulosCategoriaReducers";
import {errorMessagesC, SelectValidatorCustom} from "../../helpers/form-validator";

export default function SelectProCategory({ value = "",disabled=false, required=false, name = "categoriaId",label="", onChange, all=false}) {
    const Dispatch = useAppDispatch();

    const { status} = useAppSelector(state => state.articulosCategorias)
    const articulosCategorias = useAppSelector(state => state.articulosCategorias.list?.results)


    useEffect((e) => {
        if(!hasPermissionTo(PERSMISOS.lineas)) return false
        Dispatch(mostrarArticulosCategorias());
    }, [Dispatch]);


    var options = (articulosCategorias && articulosCategorias.map(opc=>({key:opc.id,value:opc.catNombre}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})
    const validators=required?['required']:[]

    return (

        <SelectValidatorCustom
            {...{value, validators,disabled:(status === "loading" || disabled),loading: status === "loading", errorMessages:errorMessagesC(validators), name, label, onChange}}
        >
            {options.map(o=>
                <MenuItem key={o.key} value={o.key}>{o.value}</MenuItem>
            )}
        </SelectValidatorCustom>

    );
}
