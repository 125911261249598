import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {mostrarTransacciones} from "../../redux/reducers/transaccionesReducers";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  ButtonResponsive,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort
} from '../../components';
import {FilterList} from '@mui/icons-material';
import {DateInput, SelectSmart} from "../../components/CustomTextField";

import Strings from "../../assets/strings";
import {formatDate} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ITransaccion} from "../../redux/types/ITransaccion";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import {ValidatorFormCustom} from "../../helpers/form-validator";
import SelectSearchFarmacia from '../../components/selects/SelectSearchFarmacia';
import SelectSearchPaciente from '../../components/selects/SelectSearchPaciente';
import DetalleCompra from "../Compras/DetalleCompra";
import DetalleCanje from "../Canjes/DetalleCanje";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  planId: '',
  plan:'',
  farmaciaId:'',
  farmacia:'',
  pacienteId:'',
  paciente:'',
  tipo: "",
  fechaDesde:'',
  fechaHasta:'',
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Transacciones(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const { list, status,filtered} = useAppSelector(state => state.transacciones)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarTransacciones());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarTransacciones({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarTransacciones({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarTransacciones(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarTransacciones({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };


  return <>
    <AppBar title={"Transacciones"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink>{Strings.tipo}</InputLabel>
              <SelectSmart
                  loading={false}
                  value={filter?.tipo ?? ""}
                  onChange={handleFilter}
                  name="tipo"
                  opciones={[
                    {key: '', value: Strings.todo},
                    {key: '1', value: Strings.compra},
                    {key: '2', value: Strings.canje}
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaDesde"}
                    label={Strings.fechaDesde}
                    value={filter.fechaDesde}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={6}>
                {/*// @ts-ignore*/}
                <DateInput
                    name={"fechaHasta"}
                    label={Strings.fechaHasta}
                    value={filter.fechaHasta}
                    onChange={handleFilter}
                />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchPlan
                  value={{
                    plan:filter.plan,
                    planId: (filter.planId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    planId:v.planId?v.planId:"",
                    plan: v.plan
                  })}
              />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchPaciente
                    label={Strings.paciente}
                    value={{
                        id: filter.pacienteId ? parseInt(filter.pacienteId):undefined,
                        pacNombres: filter.paciente ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        paciente:v.pacNombres??"",
                        pacienteId:v.id?.toString() ?? ""
                    }))}
                />
            </Grid>
            <Grid item xs={12}>
                <SelectSearchFarmacia
                    value={{
                        farmaciaId: (filter.farmaciaId ?? "").toString(),
                        farmacia: filter.farmacia ?? ""
                    }}
                    onChange={(v)=>setFilter(prevState => ({
                        ...prevState,
                        farmacia:v.farmacia,
                        farmaciaId:v.farmaciaId?v.farmaciaId:""
                    }))}
                />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount} noView>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}

      <TableSort data={[
        {pro:"id",text:"ID"},
        {pro:"fechaCreacion",text:Strings.fecha},
        {pro:"traTipo",text:Strings.tipo},
        {pro:"puntos",text:Strings.puntos},
        {pro:"planId",text:Strings.plan},
        {pro:"farmaciaId",text:Strings.farmacia}
      ]} onSort={tableSortProps} lessVh={155}>
        <TableBody>
          {!isEmpty && list?.results.map((item:ITransaccion) =>
              <TableRow key={item.id} onClick={(e)=> {
                if(item.traTipo === 1){
                  navigate(location.pathname, {state: {compra:{id:item.referenciaId}}})
                }else if(item.traTipo === 2){
                  navigate(location.pathname, {state: {canje:{id:item.referenciaId}}})
                }
              }}>
                <TableCell>{item.referenciaId}</TableCell>
                <TableCell>{formatDate(item.fechaCreacion)}</TableCell>
                <TableCell>{item.traTipoString}</TableCell>
                <TableCell>{item.traPuntosGenerados}</TableCell>
                <TableCell>{item.plan}</TableCell>
                <TableCell>{item.farmacia}</TableCell>
              </TableRow>
          )}
        </TableBody>
      </TableSort>

      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarTransacciones({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>


    <DetalleCompra/>
    <DetalleCanje/>

  </>;
}

export default Transacciones;

