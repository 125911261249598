import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getUsuTipo, hasPermissionTo, nullToEmptyString, PERSMISOS} from '../../helpers';
import {
    Box,
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AppBar, HtmlTooltip, LoadingProgress} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {eliminarFarmacia, mostrarFarmaciaById} from "../../redux/reducers/farmaciaReducers";
import {IFarmacia} from "../../redux/types/IFarmacia";
import ConfirmDialog from "../../components/ConfirmDialog";
import {Close, Pencil, ShieldKeyOutline, StoreOutline} from "mdi-material-ui";
import CrearUsuario from "../Usuarios/CrearUsuario";
import AutorizacionDetalle from "../Autorizaciones/AutorizacionDetalle";
import {ESTADO_AUTORIZACION, getValueString, TIPO_AUTORIZACION, TIPOUSUARIO} from "../../helpers/CONSTANTES";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2),
        "& tr.aprobada":{
            background: "#eff8ff !important"
        },
        "& tr.rechazada":{
            background: "#fff3f3 !important"
        },
    },
    paper:{
        position:"relative",
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    },
    avatar:{
        background:"#f1f1f1",
        width: 100,
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 50,
        margin: "0 auto",
        marginBottom: theme.spacing(1),
        border: "1px solid #b6b6b6",
        "& svg":{
            fontSize: 54,
            color: "#b6b6b6",
        }
    },
    table:{
        "& tr":{
            background:"#fff !important"
        }
    }
}));
let initialState:IFarmacia = {
    farCodigo:"",
    farNombre:"",
    farDireccion:"",
    farTelefono:"",
    farTelefono2:"",
    farEmail:"",
    //farmaciaIdPrincipal:"",
   // autorizadoPor:"",
   // usuId:"",
}
function CrearFarmacia(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlFarId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:farmacia,status} = useAppSelector(state => state.farmacias);

    const [state, setState] = useState(initialState);
    const [showUsuarios, setShowUsuarios] = useState(false);

    useEffect(()=>{
        if(urlFarId) Dispatch(mostrarFarmaciaById(Number(urlFarId)));
    },[urlFarId,Dispatch])
    useEffect(()=>{
        if(!!urlFarId && farmacia?.id === parseInt(urlFarId)) {
            setState({...initialState, ...nullToEmptyString(farmacia)});
            setShowUsuarios(!!farmacia.farmaciaIdPrincipal)
        }else{
            setState(initialState)
            setShowUsuarios(false)
        }
    },[urlFarId,farmacia, setShowUsuarios])


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement|null>(null);
    const open = Boolean(anchorEl);

    return(
        <Fragment>
            <AppBar title={Strings.detalles+" "+Strings.farmacia} backUrl={getUsuTipo() === TIPOUSUARIO.farmacia ? "/" : undefined}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                        <Grid container >
                            <Grid item xs={12} md={4}>
                                <Paper elevation={0} variant="outlined" square className={classes.paper} sx={{textAlign: "center"}}>
                                    <div className={classes.avatar}><StoreOutline/></div>
                                    <Typography>{state.farNombre}</Typography>
                                    <Typography variant={"caption"}>{state.farDireccion}</Typography>


                                    <Button sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                    }}
                                        onClick={(e) => {setAnchorEl(e.currentTarget)}}
                                    >
                                        {Strings.opciones}
                                    </Button>

                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={()=>setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >

                                        <MenuItem
                                            disabled={!hasPermissionTo(PERSMISOS.farmaciasEliminar)}
                                            onClick={()=>{
                                            setAnchorEl(null)
                                            navigate(pathname, {state: {"confirmDialog":state.id}})}}>
                                            <ListItemIcon>
                                                <Close fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{Strings.eliminar}</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            disabled={!hasPermissionTo(PERSMISOS.farmaciasActualizar)}
                                            onClick={()=> {
                                            setAnchorEl(null)
                                            navigate("/editar/farmacia/" + state.id)
                                        }}>
                                            <ListItemIcon>
                                                <Pencil fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{Strings.editar}</ListItemText>
                                        </MenuItem>

                                    </Menu>
                                </Paper>
                                <Paper elevation={0} variant="outlined" square className={classes.paper} >
                                    <Table className={classes.table}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left">{Strings.codigo}</TableCell>
                                                <TableCell align="right">{state.farCodigo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.estado}</TableCell>
                                                <TableCell align="right">{state.farInactiva?Strings.inactivo:Strings.activo}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.telefono}</TableCell>
                                                <TableCell align="right">{state.farTelefono}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.celular}</TableCell>
                                                <TableCell align="right">{state.farTelefono2}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.pais}</TableCell>
                                                <TableCell align="right">{state.pais}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.provincia}</TableCell>
                                                <TableCell align="right">{state.provincia}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.municipio}</TableCell>
                                                <TableCell align="right">{state.municipio}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">{Strings.sector}</TableCell>
                                                <TableCell align="right">{state.sector}</TableCell>
                                            </TableRow>
                                            {/*<TableRow>*/}
                                            {/*    <TableCell colSpan={2}>*/}
                                            {/*        <Button fullWidth size={"small"}*/}
                                            {/*            onClick={()=> navigate(pathname, {state: {autdetalle: {autEstado:1, id:state.autorizacionId,autTipo:TIPO_AUTORIZACION.creacionSucursal,autTransacionId:state.id}}})}*/}
                                            {/*        >Pendiente {Strings.autorizacion}</Button>*/}
                                            {/*    </TableCell>*/}
                                            {/*</TableRow>*/}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Paper elevation={0} variant="outlined" square sx={{ml:1}}>
                                    <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"} height={38}>
                                        <Box style={{flex: '1 1 100%', display:"flex", gap:3}}>

                                            {!farmacia?.farmaciaIdPrincipal &&
                                                <Button color={showUsuarios ? "inherit" : "secondary"}
                                                        size={"small"}
                                                        onClick={()=>setShowUsuarios(false)}
                                                        style={!showUsuarios ? {borderBottom:"2px solid"} : {}}>
                                                    {Strings.sucursales}
                                                </Button>
                                            }

                                            <Button color={!showUsuarios ? "inherit" : "secondary"} size={"small"}
                                                    onClick={()=>setShowUsuarios(true)}
                                                    style={showUsuarios ? {borderBottom:"2px solid"} : {}}>
                                                {Strings.usuarios}
                                            </Button>
                                        </Box>

                                        {showUsuarios ?(<>
                                            {hasPermissionTo(PERSMISOS.usuariosCrear) &&
                                                <Button color={"primary"}
                                                        onClick={()=>navigate(pathname, {state: {usuario: {}, farmaciaId: farmacia?.id }})}
                                                >
                                                    {Strings.agregar}
                                                </Button>
                                            }
                                        </>):(<>
                                            {hasPermissionTo(PERSMISOS.farmaciasCrear) &&
                                                <Button color={"primary"} onClick={()=>navigate("/crearSucursal/farmacia/"+state.id)}>
                                                    {Strings.agregar}
                                                </Button>
                                            }
                                        </>)}



                                    </Box>

                                    {!!showUsuarios?(
                                        <TableContainer>
                                            <Table className={"hasOnclick"}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell/>
                                                        <TableCell>{Strings.nombreDeUsuario}</TableCell>
                                                        <TableCell>{Strings.primerNombre}</TableCell>
                                                        <TableCell>{Strings.email}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {farmacia?.usuarios?.map(item =>
                                                        <TableRow key={item.id}
                                                                  // className={item.usuInactivo ? "text-red-deep":""}
                                                                  onClick={()=> {
                                                                      if(!hasPermissionTo(PERSMISOS.usuariosActualizar)) return false;
                                                                      navigate(pathname, {state: {usuario: item, farmaciaId: farmacia?.id}})
                                                                  }}
                                                        >
                                                            <TableCell />
                                                            <TableCell> {item.usuInicioSesion }</TableCell>
                                                            <TableCell> {item.usuNombre}</TableCell>
                                                            <TableCell> {item.usuEmail}</TableCell>

                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ):(
                                        <TableContainer>
                                            <Table className={"hasOnclick"}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell/>
                                                        <TableCell>{Strings.codigo}</TableCell>
                                                        <TableCell>{Strings.nombre}</TableCell>
                                                        <TableCell>{Strings.direccion}</TableCell>
                                                        <TableCell>{Strings.telefono}</TableCell>
                                                        <TableCell>{Strings.celular}</TableCell>
                                                        <TableCell>{Strings.email}</TableCell>
                                                        <TableCell>{Strings.autorizacion}</TableCell>
                                                        <TableCell>{Strings.autorizadoPor}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {farmacia?.sucursales?.map((far) =>
                                                        <TableRow key={far.id} onClick={()=>navigate("/detalles/farmacia/"+far.id)} className={getValueString(ESTADO_AUTORIZACION,far?.autorizacionEstado)}>
                                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                                {far.autorizacionEstado === 1 && hasPermissionTo(PERSMISOS.autorizaciones) &&
                                                                    <HtmlTooltip title={Strings.autorizacion}>
                                                                        <IconButton  onClick={()=> navigate(pathname, {state: {autdetalle: {autEstado:1, id:far.autorizacionId,autTipo:TIPO_AUTORIZACION.creacionSucursal,autTransacionId:far.id}}})}  size={"small"}>
                                                                            <ShieldKeyOutline fontSize={"small"} className="text-blue"/>
                                                                        </IconButton>
                                                                    </HtmlTooltip>
                                                                }
                                                            </TableCell>

                                                            <TableCell>{far.farCodigo}</TableCell>
                                                            <TableCell>{far.farNombre}</TableCell>
                                                            <TableCell>{far.farDireccion}, {far.farDireccion2}</TableCell>
                                                            <TableCell>{far.farTelefono}</TableCell>
                                                            <TableCell>{far.farTelefono2}</TableCell>
                                                            <TableCell>{far.farEmail}</TableCell>
                                                            <TableCell>{far.autorizacionEstadoString}</TableCell>
                                                            <TableCell>{far.autorizadoPorNombre}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}

                                </Paper>
                            </Grid>
                        </Grid>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarFarmacia(urlState.confirmDialog))}/>
            }
            <CrearUsuario/>
            <AutorizacionDetalle />

        </Fragment>
    );

}
export default CrearFarmacia;
