import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {eliminarTarjeta, mostrarTarjetas} from "../../redux/reducers/tarjetasReducers";

import {
  Button, Checkbox, Dialog, DialogActions, DialogContent,
  Divider, FormControlLabel, Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  FilteredAlert,
  ListViewMode,
  LoadingProgress,
  Pagination,
  SeacrhAppBar,
  TableSort,
  HtmlTooltip, ButtonResponsive
} from '../../components';
import {Close, FilterList} from '@mui/icons-material';

import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ITarjeta} from "../../redux/types/ITarjeta";
import PlusFab from "../../components/PlusFab";
import GenerarTarjetas from "./GenerarTarjetas";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import {ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1)
  }
}));

const initialState={
  name: '',
  planId: '',
  plan:'',
  verInactivos: false,
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};

function Tarjetas(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);

  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.tarjetas)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarTarjetas());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarTarjetas({
        ...filter,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type === "checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    Dispatch(mostrarTarjetas({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarTarjetas(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarTarjetas({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };
  return <>
    <AppBar title={"Tarjetas"}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectSearchPlan
                  value={{
                    plan:filter.plan,
                    planId: (filter.planId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    planId:v.planId?v.planId:"",
                    plan: v.plan
                  })}
              />
            </Grid>
          </Grid>
          <FormControlLabel sx={{m: 0,mt:2}}
                            control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                            label={Strings.ver+" "+Strings.inactivo}
                            labelPlacement="start"
          />
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && !urlState?.hasOwnProperty("id") && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((tar) =>
                <React.Fragment key={tar.id}>
                  <ListItem onClick={()=> {
                              if(!hasPermissionTo(PERSMISOS.tarjetasActualizar)) return false;
                              navigate(location.pathname, {state: tar})
                            }}
                  >
                    <ListItemText primary={`${tar.paciente}`} secondary={tar.plan}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"",text:""},
            {pro:"tarCodigo",text:Strings.codigo},
            {pro:"plan",text:Strings.plan},
            {pro:"",text:"Asignada"},
            {pro:"tarInactivo",text:Strings.estado},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((tar:ITarjeta) =>
                  <TableRow key={tar.id} onClick={()=> {
                    if(!hasPermissionTo(PERSMISOS.tarjetasActualizar)) return false;
                    navigate(location.pathname, {state: tar})
                  }}>
                    <TableCell className="actionCell" sx={{width:100}} onClick={e=>e.stopPropagation()}>
                      {!tar.tarInactivo && hasPermissionTo(PERSMISOS.tarjetasEliminar) &&
                        <HtmlTooltip title={Strings.anular}>
                          <IconButton size={"small"}
                                      onClick={(e)=> {
                                        navigate(location.pathname, {state: {confirmDialog: tar.id}})
                                      }}>
                            <Close fontSize={"small"} className="text-red"/>
                          </IconButton>
                        </HtmlTooltip>
                      }
                    </TableCell>
                    <TableCell>{tar.tarCodigo}</TableCell>
                    <TableCell>{tar.plan}</TableCell>
                    <TableCell>{tar.paciente}</TableCell>
                    <TableCell>{tar.tarInactivo?Strings.inactivo:Strings.activo}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarTarjetas({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>




    <PlusFab component={Button} onClick={()=>navigate(location.pathname,{state:{id:null}})} />



    {/*<CustomSpeedDial*/}
    {/*    ariaLabel="SpeedDial basic example"*/}
    {/*    sx={{ position: 'absolute', bottom: 16, right: 16 }}*/}
    {/*    icon={<Add />}*/}
    {/*    FabProps={{*/}
    {/*      component: Link,*/}
    {/*      to: "/crear/tarjeta"*/}
    {/*    }}*/}
    {/*>*/}
    {/*  <CustomSpeedDialAction*/}
    {/*      icon={<AddTask/>}*/}
    {/*      tooltipTitle={"Generar"}*/}
    {/*      FabProps={{*/}
    {/*        component: Link,*/}
    {/*        to: "/tarjetas#generar"*/}
    {/*      }}*/}
    {/*  />*/}
    {/*    </CustomSpeedDial>*/}

        <GenerarTarjetas/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={() => Dispatch(eliminarTarjeta(urlState.confirmDialog))}/>
    }


  </>;
}

export default Tarjetas;

