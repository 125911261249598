import React, {useEffect, useState} from 'react';
import {mostrarUsuarios} from '../../redux/reducers/usuariosReducers';
import {
    Avatar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useMediaQuery
} from "@mui/material";
import Strings from "../../assets/strings"
import {
    AppBar,
    ButtonResponsive,
    Empty,
    FilteredAlert,
    ListViewMode,
    LoadingProgress,
    PlusFab,
    SeacrhAppBar,
    Pagination
} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as EmptySvg} from "../../assets/img/collaborators.svg";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {FilterList} from "@mui/icons-material";
import {PERSMISOS, TIPO_USUARIO} from "../../helpers/CONSTANTES";
import CrearUsuario from "./CrearUsuario";
import {hasPermissionTo} from "../../helpers";

const initialState={
    name:'',
    verInactivos: false,
    tipo: '',
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
function  Usuarios() {
    const navigate =useNavigate();
    const location = useLocation();
    const { list, status,filtered} = useAppSelector(state => state.usuarios);

    const Dispatch = useAppDispatch();
    const [filter, setFilter] = useState(initialState);
    const [openFilter, setOpenFilter] = useState(false)



    const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('md'));
    useEffect(() => {
        Dispatch(mostrarUsuarios());
    }, [Dispatch])
    const isEmpty = (!list?.results || list?.results.length === 0);


    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, type, checked} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return Dispatch(mostrarUsuarios({...filter,page:null,[name]:value}));

        setFilter(prevState => ({...prevState,[name]:type === "checkbox"?checked:value}))
    };
    const resetFilter = () => {
        setOpenFilter(false);
        Dispatch(mostrarUsuarios({}));
    };
    const searchFilter = () => {
        setOpenFilter(false);
        Dispatch(mostrarUsuarios(filter));
    };

    return(
        <>
            <AppBar title={Strings.usuarios}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
                <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                <DialogContent>
                        <FormControl  fullWidth>
                            <InputLabel>{Strings.tipo}</InputLabel>
                            <Select
                                value={filter.tipo}
                                onChange={({target})=>setFilter({...filter,tipo:target.value})}
                                displayEmpty
                            >
                                <MenuItem value={""}>{Strings.todo}</MenuItem>

                                {Object.keys(TIPO_USUARIO).map((pro,i)=>
                                    <MenuItem key={i} value={(TIPO_USUARIO as any)[pro]}>{(Strings as any)[pro]}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    <FormControlLabel sx={{m: 0,mt:2}}
                                      control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                                      label={Strings.ver+" "+Strings.inactivo}
                                      labelPlacement="start"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>

            <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>
            <section className="contentInner" style={status === "loading"?{opacity:.8}:{opacity:1}}>
                {status === "loading" && isEmpty && <LoadingProgress vh/>}

                {isEmpty && status === "loading" ? (
                    <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.usuarios}>
                        <p>{Strings.agentesHelpMsj}</p>
                    </Empty>
                ):(
                    smVewport ? (
                        <List>
                            {list?.results.map(usuario => (
                                <React.Fragment key={usuario.id}>
                                    <ListItem button alignItems="flex-start" onClick={()=>navigate(location.pathname, {state:usuario})} >
                                        <ListItemAvatar>
                                            <Avatar>{usuario.usuNombre?.charAt(0).toUpperCase()}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary={usuario.rol}>
                                            <Typography noWrap className={usuario.usuInactivo ? "text-red":""}>
                                                {usuario.usuNombre}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <TableContainer>
                            <Table aria-label="simple table" className={"hasOnclick"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{Strings.nombreDeUsuario}</TableCell>
                                        <TableCell>{Strings.primerNombre}</TableCell>
                                        <TableCell>{Strings.email}</TableCell>
                                        <TableCell>{Strings.rol}</TableCell>
                                        <TableCell>{Strings.tipo}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list?.results.map(item =>
                                        <TableRow key={item.id} className={item.usuInactivo ? "text-red-deep":""}
                                                  onClick={()=> {
                                                      if(!hasPermissionTo(PERSMISOS.usuariosActualizar)) return false;

                                                      navigate(location.pathname, {state: {usuario: item}})
                                                  }}>
                                            <TableCell> {item.usuInicioSesion }</TableCell>
                                            <TableCell> {item.usuNombre}</TableCell>
                                            <TableCell> {item.usuEmail}</TableCell>
                                            <TableCell> {item.rol}</TableCell>
                                            <TableCell> {!!item.usuTipoString && (Strings as any)[item.usuTipoString!]}</TableCell>

                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
                <Pagination
                    empty={Boolean(isEmpty && status !== "loading")}
                    count={list?.totalRecordCount || 0}
                    rowsPerPage={Number(filter.pageSize)}
                    page={(list?.pageNo ?? 0) -1}
                    onPageChange={(_,page:number)=>Dispatch(mostrarUsuarios({...filter,page:(page + 1).toString()}))}
                    onRowsPerPageChange={handleFilter}
                />
                {hasPermissionTo(PERSMISOS.usuariosCrear) &&
                    <PlusFab component={Button} onClick={()=>navigate(location.pathname,{state:{usuario:{}}})} />
                }
                <CrearUsuario/>
            </section>
        </>
    )


}
export default Usuarios;
