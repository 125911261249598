import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getCompras, addEditCompra, getCompraById, delCompra} from '../../services';
import {ICompraState, ICompra, ICompraRes} from "../types/ICompra";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
const initialState: ICompraState = {
    status: "idle"
}

export const mostrarCompras = createAsyncThunk(
    'mostrarCompras', async (params:object|undefined ) => {
        const response = await getCompras(params)
        return {data: response.data as ICompraRes, params: response.config.params}
    }
)
export const mostrarCompraById = createAsyncThunk(
    'mostrarCompraById', async (id:number ) => {

        const response = await getCompraById(id)
        return response.data as ICompra
    }
)

export const agregarEditarCompra = createAsyncThunk(
    'Compras/agregarEditar', async (compra: ICompra) => {
        const response = await addEditCompra(compra);

        if (response.status === 200) {
            if(!compra.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Esta campra esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!compra.id};
    }
);
export const eliminarCompra = createAsyncThunk(
    'compra/eliminar', async (id: number) => {
        await delCompra(id);
       // if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarCompras',
    initialState,
    reducers: {
        updateCompraRow: (state,{payload}) => {
            let data = payload as ICompra;
            if(state.list?.results){
                state.list.results = state.list.results.map((com)=>{
                    if(com.id === data.id) return {...com,...data};

                    return com;
                })
            }
        },
    },
    extraReducers: builder => {
        //--mostrarCompras
        builder.addCase(mostrarCompras.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCompras.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarCompras.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarCompra
        builder.addCase(agregarEditarCompra.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCompra.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarCompra.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarCompraById
        builder.addCase(mostrarCompraById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarCompraById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarCompraById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarCompra
        builder.addCase(eliminarCompra.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarCompra.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results?.filter((r)=>r.id !== id)
            }
        })
        builder.addCase(eliminarCompra.rejected, (state ) => {
            state.status = "idle";
        })
    }
});




export const { updateCompraRow } = slice.actions;

export default slice.reducer;
