import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getParientes,generateUsuarioPariente, addEditPariente, getParienteById, delPariente} from '../../services';
import {IParienteState, IPariente, IParienteRes} from "../types/IPariente";
import historyHelper from "../../helpers/history";
import {store} from "../store";
import Notifications from "react-notification-system-redux";
const initialState: IParienteState = {
    status: "idle"
}

export const mostrarParientes = createAsyncThunk(
    'mostrarParientes', async (params:object|undefined ) => {
        const response = await getParientes(params)
        return {data: response.data as IParienteRes, params: response.config.params}
    }
)
export const mostrarParienteById = createAsyncThunk(
    'mostrarParienteById', async (id:number ) => {

        const response = await getParienteById(id)
        return response.data as IPariente
    }
)

export const agregarEditarPariente = createAsyncThunk(
    'Parientes/agregarEditar', async (pariente: IPariente) => {
        const response = await addEditPariente(pariente);

        if (response.status === 200) {
            if(!pariente.id){
                store.dispatch(Notifications.success({ title: 'Registro agregada con exito.',message:"Este regitro esta pendiente a autorización por un administrador.", autoDismiss: 8,
                    action: {
                        label: 'ok',
                        callback: () => Notifications.removeAll()
                    }
                }));
            }
            historyHelper.back();
        }

        return {data: response.data, edit:!!pariente.id};
    }
);
export const generarUsuarioPariente = createAsyncThunk(
    'pariente/generarUsuario', async (id: number) => {
        const response = await generateUsuarioPariente(id);
        if (response.status === 200 || response.status === 204) {
            historyHelper.push(historyHelper.location.pathname,response.data);
        }

        return {data: response.data};
    }
);
export const eliminarPariente = createAsyncThunk(
    'pariente/eliminar', async (id: number) => {
        const response = await delPariente(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarParientes',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarParientes
        builder.addCase(mostrarParientes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarParientes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarParientes.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarPariente
        builder.addCase(agregarEditarPariente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPariente.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarPariente.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarParienteById
        builder.addCase(mostrarParienteById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarParienteById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarParienteById.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarPariente
        builder.addCase(eliminarPariente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPariente.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarPariente.rejected, (state ) => {
            state.status = "idle";
        })
        //--generar Usuario
        builder.addCase(generarUsuarioPariente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(generarUsuarioPariente.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = {...state.byId, usuarioId:action.payload.data.id}
        })
        builder.addCase(generarUsuarioPariente.rejected, (state ) => {
            state.status = "idle";
        })
    }
});


export default slice.reducer;
