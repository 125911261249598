import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {hasPermissionTo, PERSMISOS, nullToEmptyString} from '../../helpers';
import {Box, Button, CircularProgress, Grid, TextField, Theme,Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress,AppBar} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {agregarEditarMedico, eliminarMedico, mostrarMedicoById} from "../../redux/reducers/medicosReducers";
import {IMedico} from "../../redux/types/IMedico";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectUsoMultiplesMul from "../../components/selects/SelectUsoMultiplesMul";
import {PhoneTextField} from "../../components/CustomTextField";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IMedico = {
    medNombres: "",
    medApellidos: "",
    medDireccion: "",
    medNumLicencia: "",
    medTelefono: "",
    medTelefono2: "",
    especialidades: [],
}
function CrearMedico(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlMedId} = useParams()
    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:medico,status} = useAppSelector(state => state.medicos);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlMedId) Dispatch(mostrarMedicoById(Number(urlMedId)));
    },[urlMedId,Dispatch])
    useEffect(()=>{
        if(!!urlMedId && medico?.id === parseInt(urlMedId)) {
            setState({...initialState, ...nullToEmptyString(medico)});
        }else{
            setState(initialState)
        }
    },[urlMedId,medico])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarMedico(state));
    }

    return(
        <Fragment>
            <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+Strings.medico}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}

                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />

                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        name={"medNombres"}
                                        label={Strings.nombre}
                                        value={state.medNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        name={"medApellidos"}
                                        label={Strings.apellidos}
                                        value={state.medApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        type={"number"}
                                        name={"medNumLicencia"}
                                        label={Strings.licencia}
                                        value={state.medNumLicencia}
                                        onChange={handleChange}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <PhoneTextField
                                        name={"medTelefono"}
                                        label={Strings.telefono}
                                        value={state.medTelefono}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField
                                        name={"medTelefono2"}
                                        label={Strings.celular}
                                        value={state.medTelefono2}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <SelectUsoMultiplesMul
                                        label={Strings.especialidad}
                                        values={state.especialidades}
                                        oldValue={medico?.especialidades??[]}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            especialidades:v
                                        }))}
                                        group={"Especialidades"}
                                        defaultObj={{medicoId:urlMedId}}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={"medDireccion"}
                                        label={Strings.direccion}
                                        value={state.medDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {!!state.id &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.medicosEliminar)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="inherit" sx={{marginRight:"auto"}}>Eliminar</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>

                    </ValidatorFormCustom>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarMedico(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearMedico;
