import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {IconButton, Toolbar, Typography} from '@mui/material';
import {ArrowBack, Menu as MenuIcon} from '@mui/icons-material';
import {toggleDrawer} from "../redux/reducers/themeReducer";
import {useAppDispatch} from '../redux/hooks';

import AppBar from '@mui/material/AppBar';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Routes from "../routes";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: "100%",
    boxShadow: theme.shadows[2],
  },
  toolbar:{
    padding: "0px "+theme.spacing(2),
  },
  under:{
    [theme.breakpoints.up('md')]: {
      //top:50,
      padding: "7px 4px",
      width: "inherit",
      backgroundColor: "#f3f6fc",
      //borderBottom: "1px solid rgba(0,0,0,0.1)",
      color: "inherit",
      "& + .contentInner":{
        marginTop: 15
      },
    },
    [theme.breakpoints.down('md')]: {
      "& *":{
        outline: "none",
        border:"none",
        color: theme.palette.common.white,
      },
      "& option":{
        color: theme.palette.common.black,

      }
    },

  },
  toggleBtn:{
    marginRight:theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      cursor:"pointer"
      //display: "none",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    fontSize:"1.25rem",
    fontWeight: 400,
    textTransform:"capitalize",
    "& button":{
      marginRight: theme.spacing(1),
    }
  }
}));
export default function TopAppBar({title,backUrl="",children=null}){
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = useParams();

  const getBackUrl = () => {

    if(backUrl) return {path:backUrl};

    let topRoute = null;

    let pathname = location?.pathname;

    for (const [key, value] of Object.entries(urlParams)) {
      if(key !== "*")
        pathname = pathname.replace(value,":"+key);
    }

    let routesArray = Routes();
    routesArray.forEach(e=>{
      if(e.nestedRoutes?.length){
        let found = e.nestedRoutes.find(n=>n.path.toUpperCase() === pathname.toUpperCase());
        if(found && e.path){
          topRoute = e
        }else{
          e.nestedRoutes.forEach(nestedRoute=>{
            if(nestedRoute.nestedRoutes?.length){
              let found = nestedRoute.nestedRoutes.find(n=>n.path.toUpperCase() === pathname.toUpperCase());
              if(found && nestedRoute.path) topRoute = nestedRoute;
            }
          })
        }
      }
    })


    return topRoute;

  };
  const topRoute = getBackUrl();

      return (
        <AppBar className={classes.root + " d-print-none " + (title?classes.under: "")} >
          <Toolbar className={classes.toolbar}>
            <IconButton size="small"
                        color={topRoute?"secondary":"default"}
                        className={classes.toggleBtn}
                        onClick={()=>{
                          if(topRoute){
                            navigate(topRoute.path, { replace: true })
                          }else{
                            dispatch(toggleDrawer())
                          }
                        }}
            >

              {topRoute ? <ArrowBack /> : <MenuIcon />}
            </IconButton>

            <Typography variant="h1" color={title?"secondary":"inherit"} noWrap className={classes.title}>
              {(title)?(
                  <>
                    {/*{topRoute &&*/}
                    {/*  <IconButton size="small" color={"secondary"} onClick={()=>navigate(topRoute.path, { replace: true })}> <ArrowBack/></IconButton>*/}
                    {/*}*/}
                    {title}
                  </>
              ):(
                 <>SeguraPOS</>
              )}

            </Typography>
            {!!children && children}
          </Toolbar>
        </AppBar>
      );
}
