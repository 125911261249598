import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {
    getPacientes,
    addEditPaciente,
    getPacienteByIdDetalle,
    delPaciente,
    generateUsuarioPaciente,
    addEditPariente,
    delPariente
} from '../../services';
import {IPacienteState, IPaciente, IPacienteRes, IPacienteDetalle, IIndicaciones} from "../types/IPacientes";
import historyHelper from "../../helpers/history";
import {IPariente} from "../types/IPariente";
const initialState: IPacienteState = {
    status: "idle"
}

export const mostrarPacientes = createAsyncThunk(
    'mostrarPacientes', async (params:object|undefined ) => {
        const response = await getPacientes(params)

        return {data: response.data as IPacienteRes, params: response.config.params}
    }
)
export const mostrarPacienteById = createAsyncThunk(
    'mostrarPacienteById', async (id:number ) => {
        const response = await getPacienteByIdDetalle(id)
        return response.data as IPacienteDetalle
    }
)

export const agregarEditarPaciente = createAsyncThunk(
    'Pacientes/agregarEditar', async (Paciente: IPaciente) => {
        const response = await addEditPaciente(Paciente);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Paciente.id};
    }
);
export const generarUsuarioPaciente = createAsyncThunk(
    'Paciente/generarUsuario', async (id: number) => {
        const response = await generateUsuarioPaciente(id);
        return {data: response.data};
    }
);
export const eliminarPaciente = createAsyncThunk(
    'paciente/eliminar', async (id: number) => {
        const response = await delPaciente(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);

//-----Pariente------
export const agregarEditarPariente = createAsyncThunk(
    'Parientes/agregarEditar', async (Pariente: IPariente) => {
        const response = await addEditPariente(Pariente);

        if (response.status === 200) historyHelper.back();

        return {data: response.data, edit:!!Pariente.id};
    }
);
export const eliminarPariente = createAsyncThunk(
    'pariente/eliminar', async ({pacId, parId}: { pacId: number, parId: number }) => {
        const response = await delPariente(parId);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {pacId,parId};
    }
);


const slice = createSlice({
    name: 'mostrarPacientes',
    initialState,
    reducers: {
        updateIndicacionRow: (state,{payload}) => {
            let data = payload as IIndicaciones;
            if(state.byId){
                state.byId.indicaciones = state.byId.indicaciones?.map((ind)=>{
                    if(ind.id === data.id) return {...ind,...data};
                    return ind;
                })
            }
        },
    },
    extraReducers: builder => {
        //--mostrarPacientes
        builder.addCase(mostrarPacientes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPacientes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarPacientes.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarPaciente
        builder.addCase(agregarEditarPaciente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPaciente.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.results = state.list?.results.filter(e => e.id !== action.payload.data.id);
                state.list.results.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalRecordCount
                }

            }
        })
        builder.addCase(agregarEditarPaciente.rejected, (state ) => {
            state.status = "idle";
        })

        //---mostrarPacienteById
        builder.addCase(mostrarPacienteById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarPacienteById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload;
        })
        builder.addCase(mostrarPacienteById.rejected, (state ) => {
            state.status = "idle";
        })

        //--generar Usuario
        builder.addCase(generarUsuarioPaciente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(generarUsuarioPaciente.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = {...state.byId, usuarioId:action.payload.data}
        })
        builder.addCase(generarUsuarioPaciente.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarPaciente
        builder.addCase(eliminarPaciente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPaciente.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarPaciente.rejected, (state ) => {
            state.status = "idle";
        })


        //-----Pariente------

        //--agregarEditarPariente
        builder.addCase(agregarEditarPariente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarPariente.fulfilled, (state,action ) => {
            state.status = "idle";
            // const {id,pacienteId} = action.payload.data;
            //
            // if(state.list){
            //     state.list.results = state.list?.results.map(p=>{
            //         if(p.id === pacienteId){
            //             let parientes = p.pariente?.filter(e => e.id !== id);
            //             state.byId = {...p,parientes}
            //             return state.byId
            //         }
            //         return p
            //     })
            // }
        })
        builder.addCase(agregarEditarPariente.rejected, (state ) => {
            state.status = "idle";
        })

        //--eliminarPariente
        builder.addCase(eliminarPariente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarPariente.fulfilled, (state,action ) => {
            state.status = "idle";
            const {pacId} = action.payload;
            if(state.list?.results){
                state.list.results = state.list?.results.map(p=>{
                    if(p.id === pacId){
                        state.byId = {...p,pariente:undefined}
                        return state.byId
                    }
                    return p
                })
            }
        })
        builder.addCase(eliminarPariente.rejected, (state ) => {
            state.status = "idle";
        })


    }
});



export const { updateIndicacionRow } = slice.actions;

export default slice.reducer;
