export const colors = [
    {primary: { main: "#003789"}, secondary: { main:"#b01630"}},
]


export function getColor(color) {

    let foundColor = colors.find(c => c.primary.main === color);
    if(foundColor) return foundColor;

    return colors[0]
}
