import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Strings from "../../assets/strings";
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {getLSLang, setLSLang} from "../../helpers";
import fachadaIMG from "../../assets/img/fachada-ACROMAX.jpg";
import Notifications from "react-notification-system-redux";
import {useAppSelector} from "../../redux/hooks";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Typography component={"a"} variant="body2" color="inherit" href="https://www.acromax.com.do/" target={"_blank"} rel={""}>
                Acromax
            </Typography>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const style = {
    NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
            margin: '10px 5px 2px 1px',
            borderRadius: '0px',
            boxShadow:"none",
        },
    },
    Dismiss: {
        DefaultStyle: {
            backgroundColor: "none",
            color: '#bbbbbb',
        },
    }
};
export default function LayOutLogin({children}:{children:any}) {
    const notifications = useAppSelector(state=> state.notifications);

    const [state, setState] = useState({
        lang:getLSLang("l"),
    });

    return (
           <>
               <Grid container component="main" sx={{ height: '100vh' }}>
                   <CssBaseline />
                   <Grid
                       item
                       xs={false}
                       sm={4}
                       md={7}
                       sx={{
                           display: { xs: 'none', sm: 'flex' },
                           backgroundImage: `url(${fachadaIMG})`,
                           backgroundRepeat: 'no-repeat',
                           backgroundColor: (t) =>
                               t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                       }}
                   >
                       <Typography variant={"h4"}
                                   sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       flexDirection: 'column',
                                       background: "rgba(0,0,0,0.4)",
                                       color:'common.white',
                                       textAlign:"center",
                                       height: "100%",
                                       padding: "0 28px",
                                       textShadow:'1px 1px 2px #5d5d5d',
                                       fontSize: 30,
                                       fontWeight: 500
                                   }}>Empresa farmacéutica líder, reconocida por su impacto en la salud y calidad de vida de las personas.</Typography>
                   </Grid>
                   <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                       <Box
                           sx={{
                               my: 8,
                               mx: 4,
                               display: 'flex',
                               flexDirection: 'column',
                               alignItems: 'center',
                           }}
                       >
                           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                               <LockOutlinedIcon />
                           </Avatar>

                           {children}

                           <Copyright sx={{ mt: 5 }} />
                           <Box mt={2} sx={{display:"none"}}>
                               <select value={state.lang} onChange={(e)=>{
                                   let lang = e.target.value;
                                   setLSLang(lang);
                                   Strings.setLanguage(lang);
                                   setState({...state,lang});
                               }}>
                                   {Strings.getAvailableLanguages().map(l=>
                                       <option key={l} value={l}>{(Strings as any)[l]}</option>
                                   )}
                               </select>
                           </Box>
                           {/*<Box>dssss</Box>*/}
                       </Box>
                   </Grid>
               </Grid>
               <Notifications notifications={notifications} style={style} />
           </>
    );
}
