import errorToNotifObj from "./errorToNotif"
import moment from 'moment';
import "moment/min/locales.min"
import {PERSMISOS, TIPOPAGO, TIPOUSUARIO} from "./CONSTANTES"
import {getIdToken, getTokenInfo} from "../services/auth";
import {isMobile} from "./browser_detect";
import _ from "lodash";
import Strings from "../assets/strings";

export function getLSLang(tipo) {
    const langLS = localStorage.getItem('lang');
   // const browserLang = navigator.language.split('-')[0];
    let defaultLang = "es";
    let defaultCountry = "";

    //si el idioma del navegador es soportado sera el idioma por defecto
    //if(Strings.getAvailableLanguages().includes(browserLang)) defaultLang = browserLang;

    //valor por defecto por si no esta guardado
    var response = tipo?(tipo === "l" ? defaultLang :defaultCountry):defaultLang+"-"+defaultCountry;


    if(langLS && langLS.split("-").length === 2){
        switch (tipo) {
            case "l":
                response = langLS.split("-")[0];
              break;
            case "c":
                response = langLS.split("-")[1];
                break;
            default:
                response = langLS;
                break;
        }
    }else{
        //si no esta setearlo
        localStorage.setItem("lang",defaultLang+"-"+defaultCountry)
    }
    return response;
}
moment.locale(getLSLang("l"));
export function setLSLang(language,country) {
    const langLS = localStorage.getItem('lang');
    let lang = "es";
    let count = "";

    //set language
    if(language) {
        lang = language;
    }else if(langLS && langLS.split("-").length === 2){
        lang = langLS.split("-")[0];
    }
    //set country
    if(country) {
        count = country;
    }else if(language && langLS && langLS.split("-").length === 2){
        count = langLS.split("-")[1];
    }

    localStorage.setItem("lang", lang+"-"+count)
}
export function formatDate(date, format="DD/MM/YYYY") { //h:mm a
    moment.locale(getLSLang("l"));
    if(!date)
        return "";

   return moment(date).format(format);

    // format = "DD/MM/YYYY";
    //
    // let fecha =  moment(date).calendar(null,{
    //     // lastDay : '[Yesterday]',
    //     // sameDay : '[Hoy]',
    //     // nextDay : '[Tomorrow]',
    //     lastWeek : format,
    //     nextWeek : format,
    //     sameElse : format});
    //
    // fecha = fecha.includes("a la") || fecha.includes("at") ? fecha.split(" ")[0] : fecha;
    //
    // return fecha.charAt(0).toUpperCase() + fecha.slice(1)


}
export {moment};
export function formatPeso(val,maxFraction = 2) {
    let value = val ? parseFloat(val): 0;
    let countryCode = "";
    const lSCountryCode = getLSLang("c");
    if (lSCountryCode) countryCode = lSCountryCode;

    const paises = [
        {paiCodigo: "DO",paiModeneda: "DOP", paiIdioma:"es"},
        {paiCodigo: "US",paiModeneda: "USA", paiIdioma:"en"},
        {paiCodigo: "ES",paiModeneda: "EUR", paiIdioma:"es"},
        {paiCodigo: "BO",paiModeneda: "BOB", paiIdioma:"es"},
        {paiCodigo: "CO",paiModeneda: "COP", paiIdioma:"es"},
        {paiCodigo: "CR",paiModeneda: "CRC", paiIdioma:"es"},
        {paiCodigo: "PY",paiModeneda: "PYG", paiIdioma:"es"},
        {paiCodigo: "GT",paiModeneda: "GTQ", paiIdioma:"es"},
        {paiCodigo: "VE",paiModeneda: "VES", paiIdioma:"es"},
        {paiCodigo: "PE",paiModeneda: "PEN", paiIdioma:"es"},
        {paiCodigo: "HN",paiModeneda: "HNL", paiIdioma:"es"},
        {paiCodigo: "NI",paiModeneda: "NIO", paiIdioma:"es"},
        {paiCodigo: "PA",paiModeneda: "PAB", paiIdioma:"es"},
        {paiCodigo: "MX",paiModeneda: "MXN", paiIdioma:"es"},
        {paiCodigo: "AR",paiModeneda: "ARS", paiIdioma:"es"},
        {paiCodigo: "CL",paiModeneda: "CLP", paiIdioma:"es"},
        {paiCodigo: "UY",paiModeneda: "UYU", paiIdioma:"es"}
    ];
    let monedaDisponible = paises.find(p=>p.paiCodigo === countryCode.toUpperCase());
    //si no se encuentra la moneda poner una universal
    if(!monedaDisponible){
        return '$' + parseFloat(value)
            .toFixed(maxFraction)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    //  if (countryCode && typeof Intl == 'object' && typeof Intl.NumberFormat == 'function' && monedaDisponible){
        return value.toLocaleString(monedaDisponible.paiIdioma+"-"+monedaDisponible.paiCodigo, {
            style: 'currency',
            currency: monedaDisponible.paiModeneda,
            minimumFractionDigits: 0,
            maximumFractionDigits: maxFraction,
        }).replace("DOP","$");
   // }


}
export function removeEmptyValues(obj) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) { newObj[prop] = obj[prop]; }
    });
    return newObj;
}
export function nullToEmptyString(obj,noCero) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        newObj[prop] = (obj[prop] === null || (noCero && obj[prop] === 0))  ? "" : obj[prop];
    });
    return newObj;
}
export function getMinMaxDate(dates=[], obj){
    let datesArray = dates.map(d=> new Date(d.fecha));

    if(datesArray.length <= 0) return "";

    //fecha
    let minDate = Math.min.apply(null,datesArray);
    let maxDate = Math.max.apply(null,datesArray);
    if (obj)
        return {minDate,maxDate};

    return  formatDate(minDate)+" - "+formatDate(maxDate);

}
export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
}
export function noTilde(input) {

    let r = input?.toLowerCase()??"";
    r = r.replace(new RegExp(/\s/g), "");
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/æ/g), "ae");
    r = r.replace(new RegExp(/ç/g), "c");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/ñ/g), "n");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/œ/g), "oe");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    r = r.replace(new RegExp(/[ýÿ]/g), "y");
    r = r.replace(new RegExp(/\W/g), "");
    return r;
}
export function removeSpecialCaracter(val=""){
    return val.replace(/[^A-zÀ-ú0-9,;@/\-.!? ]/g, '');
}

export function getPermisosList() {
    const {Permisos} = getTokenInfo();
    let permisosList = [];
    if(Permisos && Permisos.length > 1){
        try {
            permisosList = Permisos.split(",").map(r=>parseInt(r));
        }catch (e) {
            throw e;
        }
    }
    return permisosList
}
export function hasPermissionTo(idperId) {
    return getPermisosList().indexOf(idperId) !== -1;
}
export function getUsuTipo() {
    const {Type} = getTokenInfo();
    return parseInt(Type);
}
export function checkDateInput() {
    let input = document.createElement('input');
    input.setAttribute('type','date');

    let notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
}

export function openNewWin(link,params){
    let filtro = _.omitBy(params, (v) => v === '' || _.isNil(v));
    let qr;

    switch (typeof params){
        case "object":
            qr = new URLSearchParams(filtro).toString();
            break
        case "string":
            qr = params;
            break
        default:
            qr = undefined;
    }
    let url = link+(qr ? "?"+qr : "")
    if(isMobile.anyWebView()){
       url += (qr ? "&":"?")+"t="+getIdToken()+"&l="+getLSLang();
    }

    let width = 1061;
    let height = 850;
    let my_window;
    let center_left = (window.screen.width / 2) - (width / 2);
    let center_top = (window.screen.height / 2) - (height / 2);

    my_window = window.open(url, "Title", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1, width=" + width + ", height=" + height + ", left=" + center_left + ", top=" + center_top);
    my_window.focus();
    //  }
}
export function urlPramsToObj(querystring){
    // parse query string
    const params = new URLSearchParams(querystring);

    let obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}
export {errorToNotifObj,PERSMISOS,TIPOUSUARIO,TIPOPAGO}

export function translateString(string="") {
    if (!string || !string.length) return "";
    let value = string.charAt(0).toLowerCase() + string.slice(1);

    if(Strings[value]) {
        return Strings[value];
    }

    return value;
}
