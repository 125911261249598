import {authReducer} from './authReducer';
import { combineReducers } from '@reduxjs/toolkit'
import {reducer as notifications} from 'react-notification-system-redux';
import theme from './themeReducer';
import planes from './planesReducers';
import pacientes from './pacientesReducers';
import centrosMedicos from './centrosMedicosReducers';
import medicos from './medicosReducers';
import farmacias from './farmaciaReducers';
import ofertas from './ofertasReducers';
import compras from './comprasReducers';
import canjes from './canjesReducers';
import transacciones from './transaccionesReducers';
import tarjetas from './tarjetasReducers';
import parientes from './parientesReducers';

import articulos from './articulosReducers';
import articulosCategorias from './articulosCategoriaReducers';
import articulosMarcas from './articulosMarcaReducers';
import autorizaciones from  './autorizacionesReducers'
import notificaciones from './notificacionesReducers';

import roles from './rolesReducers';
import usuarios from './usuariosReducers';


import usosMultiples from "./usosMultiplesReducers";
import paises from "./paisesReducers";



const rootReducer = combineReducers({
    auth: authReducer,
    theme,
    articulos,
    planes,
    pacientes,
    centrosMedicos,
    medicos,
    farmacias,
    ofertas,
    compras,
    tarjetas,
    parientes,
    canjes,
    transacciones,
    articulosCategorias,
    articulosMarcas,
    roles,
    usuarios,
    usosMultiples,
    paises,
    autorizaciones,
    notificaciones,
    notifications
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
