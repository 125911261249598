import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import {mostrarArticulos} from "../../redux/reducers/articulosReducers";

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemText,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    useMediaQuery
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {formatDate, hasPermissionTo, PERSMISOS} from '../../helpers';
import {
    AppBar,
    ButtonResponsive,
    FilteredAlert,
    ListViewMode,
    LoadingProgress,
    MostrarError,
    Pagination,
    PlusFab,
    SeacrhAppBar,
    TableSort
} from "../../components";
import {FilterList} from "@mui/icons-material";
import Strings from '../../assets/strings'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {IArticulo} from "../../redux/types/IArticulo";
import SelectSearchCategoria from "../../components/selects/SelectSearchCategoria";
import SelectSearchMarca from "../../components/selects/SelectSearchMarca";
import {ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    root:{},
    pagAnulado:{
        background: "#fff1f1",
        "& td, th":{
            color:theme.palette.error.light,
        }
    },
    pagAjuste:{
        background:"#d6f4ff !important"
    },
    nombre:{
        "&>div":{
            display: "flex",
            alignItems: "center",
            "&>div:first-child":{
                marginRight:theme.spacing(1)
            }

        }
    }
}));
const initialState={
    name:'',
    verInactivos: false,
    categoriaId: '',
    categoria: '',
    marcaId:"",
    marca:"",
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
export default function Articulos() {
    const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

    let navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const viewTableList = useAppSelector(state => state.theme.viewTableList)
    const { list, status,error,filtered} = useAppSelector(state => state.articulos)

    const classes = useStyles();

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);



    const isEmpty = (!list?.results || list?.results.length === 0);

    useEffect(() => {
        Dispatch(mostrarArticulos());
    }, [Dispatch]);


    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, type, checked} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return Dispatch(mostrarArticulos({...filter,page:null,[name]:value}));

        setFilter(prevState => ({...prevState,[name]:type === "checkbox"?checked:value}))
    };
    const resetFilter = () => {
        setOpenFilter(false);
        Dispatch(mostrarArticulos({}));
    };
    const searchFilter = () => {
        setOpenFilter(false);
        Dispatch(mostrarArticulos(filter));
    };
    const tableSortProps = (orderBy:string) => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> Dispatch(mostrarArticulos({...filter, orderBy,page:null, orderByIsDescending: !filter.orderByIsDescending}))
        }
    }
    return (
        <>
            <AppBar title={Strings.articulos}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
                <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                <DialogContent>
                    <ValidatorFormCustom onSubmit={()=>{}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SelectSearchCategoria
                                    value={{
                                        categoria:filter.categoria,
                                        categoriaId: (filter.categoriaId ?? "").toString(),
                                    }}
                                    onChange={(v)=>setFilter({
                                        ...filter,
                                        categoriaId:v.categoriaId?v.categoriaId:"",
                                        categoria: v.categoria
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectSearchMarca
                                    value={{
                                        marca:filter.marca,
                                        marcaId: (filter.marcaId ?? "").toString(),
                                    }}
                                    onChange={(v)=>setFilter({
                                        ...filter,
                                        marcaId:v.marcaId?v.marcaId:"",
                                        marca: v.marca
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <FormControlLabel sx={{m: 0,mt:2}}
                            control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                            label={Strings.ver+" "+Strings.inactivo}
                            labelPlacement="start"
                        />
                    </ValidatorFormCustom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>

            <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>
            <section className={"contentInner "+classes.root} style={status==="loading"?{opacity:.8}:{opacity:1}}>
                {status === "loading" && <LoadingProgress vh/>}

                {error && <MostrarError errors={error}/>}

                {(!viewTableList && smVewport)?(
                    <List >
                        {list?.results.map((pro:IArticulo) =>
                            <React.Fragment key={pro.id}>
                                <ListItem button
                                          onClick={()=>{
                                              if(!hasPermissionTo(PERSMISOS.articulosActualizar)) return false;
                                              navigate("/editar/articulo/"+pro.id)}}>
                                    <ListItemText primary={pro.artNombre} secondary={pro.artCodigo}/>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        )}
                    </List>
                ):(
                    <TableSort data={[
                        {pro:"artCodigo",text:Strings.codigo},
                        {pro:"artNombre",text:Strings.nombre},
                        {pro:"artDescripcion",text:Strings.descripcion},
                        {pro:"categoria",text:Strings.categoria},
                        {pro:"marca",text:Strings.marca},
                        {pro:"artCodigoBarra",text:Strings.codigoBarra},
                        {pro:"artComprimidos",text:"Comprimidos"},
                        {pro:"artComprimidos",text:Strings.estado},
                        {pro:"fechaCreacion",text:"Creado"},
                    ]} onSort={tableSortProps} lessVh={155}>
                        <TableBody>
                            {list?.results.map((pro:IArticulo) =>
                                <TableRow key={pro.id}
                                          onClick={()=> {
                                              if(!hasPermissionTo(PERSMISOS.articulosActualizar)) return false;
                                              navigate("/editar/articulo/" + pro.id)
                                          }} className={pro.artInactivo ? "text-red-deep":""}>
                                        <TableCell>{pro.artCodigo}</TableCell>
                                        <TableCell>{pro.artNombre}</TableCell>
                                        <TableCell>{pro.artDescripcion}</TableCell>
                                        <TableCell>{pro.categoria}</TableCell>
                                        <TableCell>{pro.marca}</TableCell>
                                        <TableCell>{pro.artCodigoBarra}</TableCell>
                                        <TableCell>{pro.artComprimidos}</TableCell>
                                        <TableCell>{pro.artInactivo?Strings.inactivo:Strings.activo}</TableCell>
                                        <TableCell>{formatDate(pro.fechaCreacion)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </TableSort>
                )}
                <Pagination
                    empty={Boolean(isEmpty && status !== "loading")}
                    count={list?.totalRecordCount || 0}
                    rowsPerPage={Number(filter.pageSize)}
                    page={(list?.pageNo ?? 0) -1}
                    onPageChange={(_,page:number)=>Dispatch(mostrarArticulos({...filter,page:(page + 1).toString()}))}
                    onRowsPerPageChange={handleFilter}
                />
            </section>
            {hasPermissionTo(PERSMISOS.articulosCrear) &&
                <PlusFab component={Link} to="/crear/articulo" />
            }
        </>
    );
}
