import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import HomeCounter from "../../components/homeWidget/HomeCounter";
import Strings from "../../assets/strings";
import {AppBar} from "../../components";
const useStyles = makeStyles(theme => ({
    root:{
        [theme.breakpoints.down('md')]: {
            padding:theme.spacing(1)
        }
    },
    h1:{
        textTransform: "uppercase",
        textAlign: "center",
        color: theme.palette.secondary.main,
        paddingTop: "7%",
        [theme.breakpoints.down('md')]: {
            fontSize: "1.3em",
        }
    },
}));
function DashBoard({user}) {
    const {usuNombre} = user
    const classes = useStyles();



    return (
        <>
            <AppBar title={Strings.inicio}></AppBar>
            <div className={"contentInner "+classes.root}>
                <HomeCounter/>
                <div>
                    <h1 className={classes.h1}>Bienvenido, {usuNombre}</h1>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(DashBoard);

