import React, {useState,useEffect} from 'react';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Divider
} from '@mui/material';
import {Check, Close} from "@mui/icons-material";
import Strings from "../../assets/strings";
import Typography from "@mui/material/Typography";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {IPlanDetalle} from "../../redux/types/IPlanes";
import SelectSearchArticulo from "../../components/selects/SelectSearchArticulo";
import {useParams} from "react-router-dom";
import { IArticulo } from '../../redux/types/IArticulo';
import { getArticulosById } from '../../services';
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";

let initialState:IPlanDetalle={
    accion: 1,
    articuloId: 0,
    plaPuntosMinimo:undefined,
    plaCantPunto: undefined,
    plaIndicadorVentaLibre: false,
    planCantCanje: 0,
    plaCantCanjeMax: 0,
};
type Iprops = {
    detalles?:IPlanDetalle[],
    onChange: (vars:IPlanDetalle[])=>void
}

export default  function PlanDetalles({detalles, onChange}:Iprops){
    let { id:planId} = useParams()
    if(planId) initialState.planId = parseInt(planId);

    const [state, setState] = useState(initialState);
    const [error, setError] = useState<string>("");
    const [articulo, setArticulo] = useState<IArticulo>();

    useEffect(()=>{
        if(state.articuloId){
            getArticulosById(state.articuloId).then((res)=>{
                setArticulo(res.data)
            })
        }else{
            setArticulo(undefined)
        }
    },[setArticulo,state.articuloId])
    const [openNewVar, setOpenNewVar] = useState(false);
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value,type,checked} = e.target;
        if(type==="checkbox"){
            return setState({...state,[name]:checked})
        }
        setState({...state,[name]:value})
    };
    const deleteDet = (varIndex:number) =>{
        let vars = [...(detalles ?? [])];
        let itemToDelete = {...vars[varIndex]};

        if(itemToDelete.accion === 0 || itemToDelete.accion === 2){
            itemToDelete.accion = 3;
            vars.splice(varIndex, 1,itemToDelete);
        }else if(itemToDelete.accion === 3){
            itemToDelete.accion = 0;
            vars.splice(varIndex, 1,itemToDelete);
        }else{
            vars.splice(varIndex, 1);
        }
        onChange(vars);
    };
    const addDetalle = ()=>{
        setError("");
        let vars = [...(detalles ?? [])];
        let datos = {...state};

        if(datos.articuloId && datos.plaCantPunto){
            let found = vars.find(v=>v.articuloId === datos.articuloId);
            if(found){
                vars = vars.map(v=>{
                    if(v.articuloId === datos.articuloId){
                        return {...datos,accion: found?.id?2:1}
                    }
                    return v
                })
            }else{
                if(datos.id){
                    let varIndex = vars.findIndex((i=>i.id === datos.id));
                    datos.accion = 2; //si tiene un id, se esta editando.
                    vars.splice(varIndex, 1,datos);
                }else{
                    vars.unshift(datos);
                }
            }

            onChange(vars);
            closeDialog()
            setState(initialState)
        }
    };
    const closeDialog = ()=>{
        setState(initialState)
        setOpenNewVar(false)
    }
    return <>
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.detalles}
                </Typography>
                <Button color={"primary"} onClick={()=>setOpenNewVar(!openNewVar)}>
                    {Strings.agregar}
                </Button>
            </Box>
            {!!detalles?.length &&
                <TableContainer>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.articulo}</TableCell>
                                <TableCell>{Strings.punto} a generar</TableCell>
                                <TableCell>{Strings.punto} mínimo</TableCell>

                                <TableCell>{Strings.puntos+" "+Strings.requerido} ({Strings.canje})</TableCell>
                                <TableCell>{Strings.cantidad + " " + Strings.maxima} ({Strings.canje})</TableCell>
                                <TableCell>{Strings.ventaLibre}</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detalles.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow sx={{textDecoration:item.accion===3?"line-through":""}} onClick={()=> {
                                            if(item.accion===3 || item.accion===1) return false;
                                            setState(item);
                                            setOpenNewVar(true);
                                        }}>
                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                <IconButton onClick={()=>deleteDet(i)} size="large">
                                                    {item.accion===3?(
                                                        <Check className="text-blue"/>
                                                    ):(
                                                        <Close className="text-red"/>
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{item.articulo}</TableCell>
                                            <TableCell>{item.plaCantPunto}</TableCell>
                                            <TableCell>{item.plaPuntosMinimo}</TableCell>

                                            <TableCell>{item.planCantCanje}</TableCell>
                                            <TableCell>{item.plaCantCanjeMax}</TableCell>
                                            <TableCell>{item.plaIndicadorVentaLibre?"Si":"No"}</TableCell>

                                        </TableRow>
                                    </React.Fragment>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="sm" onClose={closeDialog} open={!!openNewVar}>
            <TopBarDialog title={(state.id?Strings.actualizar:Strings.agregar)+" "+Strings.detalles} onClose={closeDialog} nofullScreen/>
            <DialogContent>
                {error && <Alert severity="warning">{error}</Alert>}

            <ValidatorFormCustom onSubmit={addDetalle} id={"addDetalleForm"}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectSearchArticulo
                            value={{
                                articuloId: (state.articuloId ?? "").toString(),
                                articulo: state.articulo ?? ""
                            }}
                            onChange={(v)=>setState(prevState => ({
                                ...prevState,
                                articulo:v.articulo,
                                articuloId:v.articuloId?parseInt(v.articuloId):undefined
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} md={!!articulo?.artComprimidos ? 6 : 12}>
                        <TextValidatorCustom
                            type={"number"}
                            name="plaCantPunto"
                            label={Strings.cantidad+" "+Strings.punto}
                            value={state.plaCantPunto??""}
                            onChange={handleChange}
                            validators={['required']}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    {!!articulo?.artComprimidos &&
                        <Grid item xs={12} md={6}>
                            <TextValidatorCustom
                                type={"number"}
                                name="plaPuntosMinimo"
                                label={Strings.cantidad + " " + Strings.punto + " mínimo"}
                                value={state.plaPuntosMinimo??""}
                                onChange={handleChange}
                                validators={['required']}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={state.plaIndicadorVentaLibre}
                                                                 name={"plaIndicadorVentaLibre"}
                                                                 onChange={handleChange} />}
                                              label={Strings.ventaLibre} />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Typography color={"secondary"} sx={{mt:1.5,mb:.5}} variant={"body1"}>{Strings.canje}:</Typography>
                <Divider sx={{mb:1}}/>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type={"number"}
                            name="planCantCanje"
                            label={Strings.puntos+" "+Strings.requerido}
                            value={state.planCantCanje}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type={"number"}
                            name="plaCantCanjeMax"
                            label={Strings.cantidad+" "+Strings.maxima}
                            value={state.plaCantCanjeMax}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </ValidatorFormCustom>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">{Strings.cancelar}</Button>
                <Button color="primary" type={"submit"} form={"addDetalleForm"}>{Strings.guardar}</Button>
            </DialogActions>
        </Dialog>
    </>;
}
