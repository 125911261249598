import React, {useEffect, useState} from 'react';
import {
    Button, Checkbox, Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress,
    IconButton, List, ListItemButton, ListItemIcon, ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {Add, Check, Close, Remove} from "@mui/icons-material";
import Strings from "../../assets/strings";
import Typography from "@mui/material/Typography";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {IOfertaFarmacia} from "../../redux/types/IOferta";
import {useParams} from "react-router-dom";
import {IFarmacia} from "../../redux/types/IFarmacia";
import {getFarmacias} from "../../services";

type Iprops = {
    farmacias?:IOfertaFarmacia[],
    onChange: (vars:IOfertaFarmacia[])=>void
}
let searchTimeout:ReturnType<typeof setTimeout>;

export default  function OfertaFarmacia({farmacias, onChange}:Iprops){
    let { id:ofeId} = useParams()
    const [search, setSearch] = React.useState<string>("");
    const [selected, setSelected] = React.useState<IOfertaFarmacia[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading,setLoading] = useState(false)
    const [open, setOpen] = React.useState<number|null>(null);
    const [farmaciasList, setFarmaciasList] = React.useState<IFarmacia[]>([]);



    useEffect(()=>{
        if(openDialog){
            setSelected(farmacias??[])
        }else{
            setSelected([])
        }
    },[farmacias,openDialog,setSelected])
    useEffect(()=>{
        fetchFarmacia(search)
        return ()=>clearTimeout(searchTimeout)
    },[search])
    const fetchFarmacia = (name:string) => {
        setLoading(true)
        getFarmacias({name}).then(res=>{
            let result = res.data.results as IFarmacia[];
            setFarmaciasList(result)
        }).finally(()=>setLoading(false))
    }
    const handleSelected = (val:IFarmacia,sucursales:IFarmacia[] = []) => {

        let selectedList = [...selected];

        // let handleList = [val];
        // if(val.sucursales?.length){
        //     val.sucursales.forEach(e=>{
        //         if(!selectedList.find(s=>s.farmaciaId===e.id)){
        //             handleList.push(e)
        //         }
        //     })
        // }


        [val,...sucursales].forEach(value=>{
            let item:IOfertaFarmacia = {
                ofertaId: ofeId?parseInt(ofeId):undefined,
                farmaciaId: value.id,
                farmacia: value.farNombre,
                accion: 1,
            }

            let found = selected.find(s=>s.farmaciaId === value.id);
            if(found){
                if(found.accion === 1){
                    selectedList = selectedList.filter(s=>s.farmaciaId !== value.id)
                }else if(found.accion === 0){
                    selectedList = selectedList.map(s => {
                        if (s.farmaciaId === found?.farmaciaId) {
                            return {...s, accion: 3}
                        }
                        return s;
                    })

                }else{
                    selectedList = selectedList.map(s => {
                        if (s.farmaciaId === found?.farmaciaId) {
                            return {...s, accion: 0}
                        }
                        return s;
                    })

                    // setSelected(prevState => [...prevState.filter(s=>s.farmaciaId === value.id),{...found,accion:0}])
                }
            }else{
                selectedList.push(item)
                // if(value.farmaciaIdPrincipal){
                //     //si tiene una farmacia principal y no esta seleccionada
                //     let farmaciaPrincipal = farmaciasList.find(f=>f.id===value.farmaciaIdPrincipal);
                //     if(farmaciaPrincipal && selectedNotDeleted.findIndex(s=>s.farmaciaId===farmaciaPrincipal?.id) !== -1){
                //         farmaciaPrincipal.sucursales = [];
                //         handleSelected(farmaciaPrincipal)
                //     }
                // }
            }

        })

        setSelected(selectedList)


        // if(value?.sucursales){
        //     // console.log(value)
        //
        //     value.sucursales.forEach((s:IFarmacia)=>{
        //         console.log(s)
        //         handleSelected(s)
        //     })
        // }
    }

    const deleteDet = (varIndex:number) =>{
        let vars = [...(farmacias ?? [])];
        let itemToDelete = {...vars[varIndex]};

        if(itemToDelete.accion === 0){
            itemToDelete.accion = 3;
            vars.splice(varIndex, 1,itemToDelete);
        }else if(itemToDelete.accion === 3){
            itemToDelete.accion = 0;
            vars.splice(varIndex, 1,itemToDelete);
        }else{
            vars.splice(varIndex, 1);
        }
        onChange(vars);
    };
    const addFarmacia = (e:any)=>{
        onChange(selected);
        closeDialog()
    };
    const closeDialog = ()=>{
        setOpenDialog(false);
    }
    let selectedNotDeleted = selected.filter(s=>s.accion !== 3);

    return <>
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.farmacias}
                </Typography>
                <Button color={"primary"} onClick={()=>setOpenDialog(!openDialog)}>
                    {farmacias?.length?Strings.editar:Strings.agregar}
                </Button>
            </Box>
            {!!farmacias?.length &&
                <TableContainer>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.farmacia}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {farmacias.map((item,i)=>{
                                return (
                                    <React.Fragment key={i}>
                                        <TableRow sx={{textDecoration:item.accion===3?"line-through":""}}>
                                            <TableCell className="actionCell" onClick={(e)=>e.stopPropagation()}>
                                                <IconButton onClick={()=>deleteDet(i)} size="large">
                                                    {item.accion===3?(
                                                        <Check className="text-blue"/>
                                                    ):(
                                                        <Close className="text-red"/>
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{item.farmacia}</TableCell>
                                        </TableRow>
                                    </React.Fragment>

                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="xs" fullWidth onClose={closeDialog} open={!!openDialog}>
            <TopBarDialog title={Strings.agregar+" "+Strings.farmacia} onClose={closeDialog} nofullScreen/>
            <div style={{
                position:"relative",
            }}>
                <input
                    style={{
                        padding: 10,
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid",
                        outline: "none",
                    }}
                    type={"search"} placeholder={Strings.buscar}
                    onChange={({target})=>{
                        clearTimeout(searchTimeout);
                        searchTimeout = setTimeout(()=> setSearch(target.value), 1000);
                    }}
                />
                {loading &&
                    <CircularProgress size={18} sx={{
                        position: "absolute",
                        top: "8px",
                        right: "15px",
                    }}/>
                }
            </div>
            <DialogContent sx={{p:1}}>
                <List
                    component="nav"
                    disablePadding
                >
                    {farmaciasList.map(far=>(
                        <React.Fragment key={far.id}>
                            {far.sucursales?.length ?(
                                <>
                                    <ListItemButton onClick={()=>setOpen( open===far.id?null:far.id!)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selectedNotDeleted.findIndex(s=>s.farmaciaId===far.id) !== -1}
                                                tabIndex={-1}
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    let toAdd = selected.findIndex(s=>s.farmaciaId===far.id) === -1; //seleccionar sucursales
                                                    handleSelected(far,toAdd?far?.sucursales?.filter(s=>!selected.find(sel=>sel.farmaciaId=s.id)):[])
                                                }}
                                                inputProps={{ 'aria-labelledby': far.farCodigo }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={far.farCodigo}
                                            primary={<Typography noWrap>{far.farNombre}</Typography>} secondary={far.farCodigo} />
                                        {open===far.id ? <Remove sx={{fontSize:"1em",color: "#767676"}}/> : <Add sx={{fontSize:"1em",color: "#767676"}}/>}

                                    </ListItemButton>
                                    {!!far.sucursales?.length &&
                                        <Collapse in={open===far.id} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding sx={{
                                                ml: "57px",
                                                background: "#efefef"
                                            }}>
                                                {far.sucursales?.map(suc=>
                                                    <ListItemButton dense key={suc.id}  onClick={()=>handleSelected(suc)}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                size={"small"}
                                                                edge="start"
                                                                checked={selectedNotDeleted.findIndex(s=>s.farmaciaId===suc.id) !== -1}
                                                                tabIndex={-1}
                                                                inputProps={{ 'aria-labelledby': suc.farCodigo }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            id={suc.farCodigo}
                                                            primary={<Typography noWrap>{suc.farNombre}</Typography>} secondary={suc.farCodigo} />
                                                    </ListItemButton>
                                                )}
                                            </List>
                                        </Collapse>
                                    }
                                </>
                            ):(
                                <ListItemButton  onClick={()=>handleSelected(far)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedNotDeleted.findIndex(s=>s.farmaciaId===far.id) !== -1}
                                            tabIndex={-1}
                                            inputProps={{ 'aria-labelledby': far.farCodigo }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={far.farCodigo}
                                        primary={far.farNombre} secondary={far.farCodigo} />
                                </ListItemButton>
                            )}

                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">{Strings.cancelar}</Button>
                <Button color="primary" onClick={addFarmacia}>{Strings.guardar}</Button>
            </DialogActions>
        </Dialog>
    </>;
}
