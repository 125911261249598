import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {ITarjeta} from "../../redux/types/ITarjeta";
import {genTarjeta} from "../../services";
import {agregarEditarTarjeta, mostrarTarjetas} from "../../redux/reducers/tarjetasReducers";
import SelectSearchPaciente from "../../components/selects/SelectSearchPaciente";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";

const initialState:ITarjeta = {
    tarCodigo: "",
    plan: "",
    paciente: "",
    tarInactivo: false,
    planId: undefined,
   // cuantas: 1,
    // fechaVencimiento: moment().format("YYYY-MM-DD")
};
export default function GenerarTarjetas() {
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    const {state:urlState}:{state:any} = useLocation();
    const [generadas, setGeneradas] = useState("");
    const [loading, setLoading] = useState(false);
    const {status} = useAppSelector(state => state.tarjetas);

    const [lote, setLote] = useState(false);
    const [cantidad, setCantidad] = useState(1);

    const [state, setState] = useState(initialState);

    useEffect(() => {
        setLote(false);
        setGeneradas("")
        setCantidad(0)
        if(!!urlState && urlState.id) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = () => {

        if(lote){
            setLoading(true)
            genTarjeta({...state,cantidad}).then(({data})=>{
                setGeneradas(`${data} tarjetas generadas`)
                Dispatch(mostrarTarjetas());
            }).finally(()=>{
                setLoading(false)
            })
        }else{
            Dispatch(agregarEditarTarjeta(state));
        }

    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("id"))} onClose={()=>navigate(-1)} maxWidth={"xs"} fullWidth={true}>
                <TopBarDialog title={Strings.tarjeta} onClose={()=>navigate(-1)}/>
                    <DialogContent>
                        {(loading || status === "loading") && <LoadingProgress />}
                        {!generadas.length?(
                            <ValidatorFormCustom onSubmit={handleSubmit} id="form1">
                                {!state.id &&
                                    <FormGroup sx={{mb: 2}}>
                                        <FormControlLabel sx={{justifyContent: "end", m: 0}}
                                                          control={<Switch checked={lote} color={"secondary"}
                                                                           onChange={({target}) => setLote(target.checked)}/>}
                                                          labelPlacement="start" label="Generar lote"/>
                                    </FormGroup>
                                }
                                {!!lote &&
                                    <TextValidatorCustom
                                        type={"number"}
                                        value={cantidad}
                                        name={""}
                                        onChange={(e:any) => setCantidad(parseInt(e.target.value))}
                                        validators={['required']}
                                        label={Strings.cantidad}
                                        fullWidth
                                        inputProps={{
                                            min:1
                                        }}
                                        sx={{mb:2}}
                                    />
                                    }
                                <Box sx={{mb:2}}>
                                <SelectSearchPlan
                                    disabled={!!state.id}
                                    required
                                    value={{
                                        planId: (state.planId ?? "").toString(),
                                        plan: state.plan ?? ""
                                    }}
                                    onChange={(v)=>setState(prevState => ({
                                        ...prevState,
                                        plan:v.plan,
                                        planId:v.planId?parseInt(v.planId):undefined
                                    }))}
                                />
                                </Box>
                                {!lote &&<>
                                    <SelectSearchPaciente
                                        label={"Asignado a:"}
                                        value={{
                                            id: state.pacienteId,
                                            pacNombres: state.paciente
                                        }}
                                        onChange={(v)=>setState(prevState => ({
                                            ...prevState,
                                            paciente: v.pacNombres??"",
                                            pacienteId:v.id,
                                        }))}
                                    />
                                    <FormControl sx={{ mt: 1 }} variant="standard">
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="secondary" checked={state.tarInactivo} onChange={({target})=>setState(prev=>({...prev,tarInactivo:target.checked}))} />
                                            }
                                            label={Strings.inactivo}
                                        />
                                    </FormControl>
                                </>}
                                {/*<TextField*/}
                                {/*    sx={{mt:2}}*/}
                                {/*    type={"date"}*/}
                                {/*    value={state.fechaVencimiento}*/}
                                {/*    onChange={(e) => setState({ ...state, fechaVencimiento: e.target.value })}*/}
                                {/*    autoFocus*/}
                                {/*    required*/}
                                {/*    label={Strings.fechaVencimiento}*/}
                                {/*    fullWidth*/}
                                {/*/>*/}

                            </ValidatorFormCustom>
                        ):(
                            <Typography>{generadas}</Typography>
                        )}

                    </DialogContent>
                {!generadas.length &&
                    <DialogActions>
                        <Button onClick={()=>navigate(-1)} color="primary">{Strings.cancelar}</Button>
                        <Button disabled={status === "loading"} type="submit" form="form1">
                            {status === "loading"? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <span>{lote ? "Generar lote": <>{state.id?Strings.actualizar:Strings.guardar}</>}</span>
                            )}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </>
    );

}
