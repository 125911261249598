import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {eliminarCompra, mostrarCompras} from "../../redux/reducers/comprasReducers";

import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl, FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  ButtonResponsive,
  FilteredAlert,
  HtmlTooltip,
  ListViewMode,
  LoadingProgress,
  Pagination,
  PlusFab,
  SeacrhAppBar,
  TableSort
} from '../../components';

import Strings from "../../assets/strings";
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ICompra} from "../../redux/types/ICompra";
import {AttachFile, Close, FilterList} from '@mui/icons-material';
import DetalleCompra from "./DetalleCompra";
import ConfirmDialog from "../../components/ConfirmDialog";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {DateInput, SelectSmart} from "../../components/CustomTextField";
import {ShieldKeyOutline} from "mdi-material-ui";
import AutorizacionDetalle from "../Autorizaciones/AutorizacionDetalle";
import {ESTADO_AUTORIZACION, getValueString, objToKeyValue, TIPO_AUTORIZACION} from "../../helpers/CONSTANTES";
import SelectSearchPlan from "../../components/selects/SelectSearchPlan";
import SelectSearchPaciente from "../../components/selects/SelectSearchPaciente";
import SelectSearchFarmacia from "../../components/selects/SelectSearchFarmacia";
import {ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
  root:{
    padding:theme.spacing(1),
    "& tr.aprobada":{
      background: "#eff8ff !important"
    },
    "& tr.rechazada":{
      background: "#fff3f3 !important"
    },
  }
}));

const initialState={
  name: '',
  planId: '',
  plan:'',
  farmaciaId:'',
  farmacia:'',
  pacienteId:'',
  paciente:'',
  estado: "",
  fechaDesde:'',
  fechaHasta:'',
  verInactivos:false,
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Compras(){
  let navigate =useNavigate();
  const {state:urlState,...location}:any = useLocation();
  const [openImg, setOpenImg] = useState("");

  const Dispatch = useAppDispatch();
  const smVewport = useMediaQuery((theme:Theme)=>theme.breakpoints.down('lg'));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false)
  const [filter, setFilter] = useState(initialState);


  const viewTableList = useAppSelector(state => state.theme.viewTableList)
  const { list, status,filtered} = useAppSelector(state => state.compras)

  const isEmpty = (!list?.results || list?.results.length === 0);

  useEffect(() => {
    Dispatch(mostrarCompras());
  }, [Dispatch]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return Dispatch(mostrarCompras({
        ...filter,
        page:null,
        [name]:value
      }));
    }
    setFilter({...filter,[name]:type==="checkbox"?checked:value})
  };
  const resetFilter = () => {
    setOpenFilter(false)
    setFilter(initialState);
    Dispatch(mostrarCompras({}))
  };
  const searchFilter = () => {
    setOpenFilter(false);
    Dispatch(mostrarCompras(filter));
  };
  const tableSortProps = (orderBy:string) => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>Dispatch(mostrarCompras({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
    };
  };

  const viewFileClick = (src?:string) =>{
    if(src?.indexOf("pdf") !== -1) return src?.indexOf("base64") === -1 ? window.open(encodeURI(src??"")) : false;
    if(src) setOpenImg(src);
  }

  return <>
    <AppBar title={Strings.compras}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>

    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <ValidatorFormCustom onSubmit={()=>{}}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaDesde"}
                  label={Strings.fechaDesde}
                  value={filter.fechaDesde}
                  onChange={handleFilter}
              />
            </Grid>
            <Grid item xs={6}>
              {/*// @ts-ignore*/}
              <DateInput
                  name={"fechaHasta"}
                  label={Strings.fechaHasta}
                  value={filter.fechaHasta}
                  onChange={handleFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchPlan
                  value={{
                    plan:filter.plan,
                    planId: (filter.planId ?? "").toString(),
                  }}
                  onChange={(v)=>setFilter({
                    ...filter,
                    planId:v.planId?v.planId:"",
                    plan: v.plan
                  })}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchPaciente
                  label={Strings.paciente}
                  value={{
                    id: filter.pacienteId ? parseInt(filter.pacienteId):undefined,
                    pacNombres: filter.paciente ?? ""
                  }}
                  onChange={(v)=>setFilter(prevState => ({
                    ...prevState,
                    paciente:v.pacNombres??"",
                    pacienteId:v.id?.toString() ?? ""
                  }))}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSearchFarmacia
                  value={{
                    farmaciaId: (filter.farmaciaId ?? "").toString(),
                    farmacia: filter.farmacia ?? ""
                  }}
                  onChange={(v)=>setFilter(prevState => ({
                    ...prevState,
                    farmacia:v.farmacia,
                    farmaciaId:v.farmaciaId?v.farmaciaId:""
                  }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel shrink>{Strings.estado}</InputLabel>
                <SelectSmart
                    loading={false}
                    value={filter?.estado ?? ""}
                    onChange={handleFilter}
                    name="estado"
                    opciones={[
                      {key: '', value: Strings.todo},
                      ...objToKeyValue(ESTADO_AUTORIZACION)
                    ]}
                />
              </FormControl>

              <FormControlLabel sx={{m: 0,mt:2}}
                                control={<Checkbox checked={filter.verInactivos} onChange={handleFilter} name="verInactivos" />}
                                label={Strings.ver+" "+Strings.inactivo}
                                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </ValidatorFormCustom>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilter}>{Strings.cancelar}</Button>
        <Button color="primary" onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>

    <ListViewMode pageRows={list?.pageSize} pageTotalRows={list?.totalRecordCount}>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className={"contentInner "+classes.root} style={status === "loading"?{opacity:.8}:{opacity:1}}>
      {status === "loading" && <LoadingProgress vh/>}


      {(!viewTableList && smVewport)?(
          <List>
            {!isEmpty && list?.results.map((com) =>
                <React.Fragment key={com.id}>
                  <ListItem onClick={()=> navigate(location.pathname, {state:com})}>
                    <ListItemText primary={`${com.paciente}`} secondary={formatDate(com.fechaCreacion)}/>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
            )}
          </List>
          ):(
          <TableSort data={[
            {pro:"fechaCreacion",text:""},
            {pro:"id",text:"ID"},
            {pro:"fechaCreacion",text:Strings.fecha},
            {pro:"paciente",text:Strings.paciente},
            {pro:"farmacia",text:Strings.farmacia},
            {pro:"comPuntosGenerados",text:Strings.puntos},
            {pro:"plan",text:Strings.plan},
            {pro:"autorizacionEstado",text:Strings.autorizacion},
            {pro:"autorizadoPor",text:Strings.autorizadoPor},
          ]} onSort={tableSortProps} lessVh={155}>
            <TableBody>
              {!isEmpty && list?.results.map((com:ICompra) =>
                  <TableRow key={com.id} onClick={()=> navigate(location.pathname, {state: {compra: com}})} className={getValueString(ESTADO_AUTORIZACION,com?.autorizacionEstado)}>
                    <TableCell className="actionCell" sx={{width:100}}
                               onClick={e=>e.stopPropagation()}>
                      {!com.comInactivo && hasPermissionTo(PERSMISOS.comprasEliminar) &&
                          <HtmlTooltip title={Strings.anular}>
                            <IconButton size={"small"}
                                        onClick={(e)=> {
                                          navigate(location.pathname, {state: {confirmDialog: com.id}})
                                        }}>
                              <Close fontSize={"small"} className="text-red"/>
                            </IconButton>
                          </HtmlTooltip>
                      }
                      {com.comAdjunto &&
                      <HtmlTooltip title={Strings.ver+" adjunto"}>
                        <IconButton onClick={()=>viewFileClick(com.comAdjunto!)} size={"small"}><AttachFile fontSize={"small"} className="text-blue"/></IconButton>
                      </HtmlTooltip>}
                      {com.autorizacionEstado === 1 && hasPermissionTo(PERSMISOS.autorizaciones) &&
                        <HtmlTooltip title={Strings.autorizacion}>
                          <IconButton  onClick={()=> navigate(location.pathname, {state: {autdetalle: {autEstado:1, id:com.autorizacionId,autTipo:TIPO_AUTORIZACION.compra,autTransacionId:com.id}}})}  size={"small"}>
                            <ShieldKeyOutline fontSize={"small"} className="text-violet"/>
                          </IconButton>
                        </HtmlTooltip>
                      }
                    </TableCell>
                    <TableCell>{com.id}</TableCell>
                    <TableCell>{formatDate(com.fechaCreacion)}</TableCell>
                    <TableCell>{com.paciente}</TableCell>
                    <TableCell>{com.farmacia}</TableCell>
                    <TableCell>{com.comPuntosGenerados}</TableCell>
                    <TableCell>{com.plan}</TableCell>
                    <TableCell>{com.autorizacionEstadoString}</TableCell>
                    <TableCell>{com.autorizadoPorNombre}</TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      )}
      <Pagination
          empty={Boolean(isEmpty && status !== "loading")}
          count={list?.totalRecordCount || 0}
          rowsPerPage={Number(filter.pageSize)}
          page={(list?.pageNo ?? 0) -1}
          onPageChange={(_,page:number)=>Dispatch(mostrarCompras({...filter,page:(page + 1).toString()}))}
          onRowsPerPageChange={handleFilter}
      />
    </section>
    {hasPermissionTo(PERSMISOS.comprasCrear) &&
    <PlusFab component={Link} to="/crear/compra" />
    }

    <DetalleCompra/>
    {!!urlState?.hasOwnProperty("confirmDialog") &&
        <ConfirmDialog onConfirm={()=>Dispatch(eliminarCompra(urlState.confirmDialog))}/>
    }
    {hasPermissionTo(PERSMISOS.autorizaciones) &&
        <AutorizacionDetalle />
    }



    <Dialog
        open={!!openImg}
        onClose={()=>setOpenImg("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

    >
      <TopBarDialog title={"Adjunto"} nofullScreen onClose={()=>setOpenImg("")}/>

      <DialogContent  sx={{p:0}}>
        <img src={openImg} alt="adjunto" style={{width:"100%"}}/>
      </DialogContent>
    </Dialog>
  </>;
}

export default Compras;

