import React, {useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {CircularProgress,ListItem,ListItemText} from '@mui/material';
import Strings from "../../assets/strings";
import {getPacientesListSimple} from "../../services";
import {TextValidatorCustom} from "../../helpers/form-validator";

type TProps = {
    label?:string,
    value:TValue,
    required?:boolean,
    disabled?:boolean,
    onChange: (val:TValue)=>void

}
type TValue = {
    id?: number,
    pacDocIdentidad?:string,
    pacNombres?:string,
    pacPuntos?: number,
    planId?: number,
    tarjeta?:string,
}
var searchTimeout: ReturnType<typeof setTimeout>;

let emptyValue:TValue = {
    id:undefined,
    pacNombres:""
}



// pacienteId?:string,
//     paciente:string,
//     id?:string
//


export default function SelectSearchPaciente({ value,label=Strings.paciente,required,disabled, onChange}:TProps) {
    const [options, setOptions] = React.useState<TValue[]>([]);
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        return ()=>clearTimeout(searchTimeout)
    })
    const fetchPaciente = (name:string) => {
        setLoading(true)
        getPacientesListSimple({name}).then(res=>{
            let result = res.data.results as TValue[];
            setOptions(result)
        }).finally(()=>setLoading(false))
    }


    return (

        <React.Fragment>
            <Autocomplete
                fullWidth
                disabled={disabled}
                aria-required={required}
                loading={loading}
                onOpen={()=> {
                  //  if(options.length) return false;
                    fetchPaciente("")
                }}
                options={[value,...options.filter((r) =>r.id!==value.id)]}
                getOptionLabel={(option) => option.pacNombres??""}
                value={value}
                isOptionEqualToValue={(option, value) => option.id === value.id}

                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                    onChange(newValue??emptyValue)
                }}
                // inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                        fetchPaciente(newInputValue)
                    },1000);
                }}
                renderOption={(props, option) => (
                    <ListItem {...{...props,key:option.id}}>
                        <ListItemText primary={option.pacNombres} secondary={option.pacPuntos !== undefined ? Strings.puntos+": "+option.pacPuntos : ""} />
                    </ListItem>
                )}
                renderInput={(params) => (
                    <TextValidatorCustom
                        {...params}
                        name={""}
                        value={value.id}
                        label={label}
                        validators={required?['required']:[]}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </React.Fragment>

    );
}
