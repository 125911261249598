import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {hasPermissionTo, PERSMISOS, nullToEmptyString} from '../../helpers';
import {Box, Button, CircularProgress, Grid, TextField, Theme, Paper, Typography, FormControlLabel, FormGroup, Switch} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress,AppBar} from "../../components";
import Strings from "../../assets/strings";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {agregarEditarFarmacia, eliminarFarmacia, mostrarFarmaciaById} from "../../redux/reducers/farmaciaReducers";
import {IFarmacia} from "../../redux/types/IFarmacia";
import ConfirmDialog from "../../components/ConfirmDialog";
import SelectPaiProMun from "../../components/selects/SelectPaiProMun";
import {PhoneTextField} from "../../components/CustomTextField";
import {TextValidatorCustom, ValidatorFormCustom} from "../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding:theme.spacing(2)
    },
    paper:{
        padding:`${theme.spacing(2)} ${theme.spacing(1)}`,
        marginBottom:theme.spacing(1)
    }
}));
let initialState:IFarmacia = {
    farCodigo:"",
    farNombre:"",
    farTelefono:"",
    farTelefono2:"",
    farEmail:"",
    pais:"",
    paisId:undefined,
    provincia:"",
    provinciaId:undefined,
    municipio:"",
    municipioId:undefined,
    sector:"",
    sectorId:undefined,
    farDireccion:"",
    //farmaciaIdPrincipal:"",
   // autorizadoPor:"",
   // usuId:"",
}
function CrearFarmacia(){
    const classes = useStyles();
    const navigate = useNavigate();
    const Dispatch = useAppDispatch();
    let { id:urlFarId, farPrincipal} = useParams()
    if(farPrincipal) initialState.farmaciaIdPrincipal = parseInt(farPrincipal);

    const {state:urlState,pathname}:{state:any,pathname:string} = useLocation();
    const {byId:farmacia,list,status} = useAppSelector(state => state.farmacias);

    const [state, setState] = useState(initialState);
    const [dataFarmPrincipal, setDataFarmPrincipal] = useState<IFarmacia>();


    useEffect(()=>{
        if(urlFarId) Dispatch(mostrarFarmaciaById(Number(urlFarId)));
    },[urlFarId,Dispatch])

    //get farmacia principal
    useEffect(()=>{
        if(state.farmaciaIdPrincipal && list?.results){
            let principal = list.results.find(f=>f.id===state.farmaciaIdPrincipal);
            if(principal) setDataFarmPrincipal(principal)
        }
        if(urlFarId) Dispatch(mostrarFarmaciaById(Number(urlFarId)));
    },[state.farmaciaIdPrincipal,urlFarId,list,Dispatch])

    useEffect(()=>{
        if(!!urlFarId && farmacia?.id === parseInt(urlFarId)) {
            setState({...initialState, ...nullToEmptyString(farmacia)});
        }else{
            setState(initialState)
        }
    },[urlFarId,farmacia])
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = () =>{
        Dispatch(agregarEditarFarmacia(state));
    }

    return(
        <Fragment>
            <AppBar title={(state.id?Strings.actualizar:Strings.crear)+" "+(farPrincipal?Strings.sucursal:Strings.farmacia)}/>
            <div className={"contentInner "+classes.root} >
                <div className={"content"}>
                    {status === "loading" && <LoadingProgress vh/>}
                    <ValidatorFormCustom id="form1" onSubmit={submit} autoComplete={"off"}>
                        <input autoComplete="false" name="hidden" type="text" style={{display:"none"}} />
                        {!!dataFarmPrincipal && <Typography sx={{mb:1}}>Principal: {dataFarmPrincipal.farNombre}</Typography>}
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                {(!farPrincipal || state.id) &&
                                    <Grid item xs={6} sm={3}>
                                        <TextValidatorCustom
                                            name={"farCodigo"}
                                            label={Strings.codigo}
                                            disabled={!!state.id}
                                            value={state.farCodigo}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            validators={['required']}
                                            fullWidth
                                        />
                                    </Grid>
                                }

                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        name={"farNombre"}
                                        label={Strings.nombre}
                                        value={state.farNombre}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        validators={['required']}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <PhoneTextField name={"farTelefono"} validators={['required']} label={Strings.telefono} value={state.farTelefono} onChange={handleChange} inputProps={{maxLength: 15}}  fullWidth />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <PhoneTextField
                                        name={"farTelefono2"}
                                        label={Strings.celular}
                                        value={state.farTelefono2}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 15}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextValidatorCustom
                                        type={"email"}
                                        name={"farEmail"}
                                        label={Strings.email}
                                        value={state.farEmail}
                                        onChange={handleChange}
                                        validators={['required','isEmail']}
                                        fullWidth
                                    />
                                </Grid>
                                <SelectPaiProMun required value={{
                                    pais:state.pais,
                                    paisId:state.paisId,
                                    provincia:state.provincia,
                                    provinciaId:state.provinciaId,
                                    municipio:state.municipio,
                                    municipioId:state.municipioId,
                                    sector:state.sector,
                                    sectorId:state.sectorId
                                }} onChange={(val)=>setState(prev=>({...prev,...val}))} />

                                <Grid item xs={12} md={5}>
                                    <TextValidatorCustom
                                        name={"farDireccion"}
                                        label={Strings.direccion}
                                        value={state.farDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 200}}
                                        validators={['required',"matchRegexp:^[A-zÀ-ú0-9 \\/#,.]*$"]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        name={"farDireccion2"}
                                        label={Strings.direccion+" "+Strings.referencia}
                                        value={state.farDireccion2}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 100}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch checked={state.farInactiva} color={"secondary"}
                                                                            onChange={({target})=>setState({...state, farInactiva:target.checked})} />}
                                                            labelPlacement="end" label={Strings.inactivo}/>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
                            {!!state.id &&
                                <Button onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.farmaciasActualizar)) return false;
                                    navigate(pathname, {state: {"confirmDialog":state.id}})}}
                                        color="inherit" sx={{marginRight:"auto"}}>Eliminar</Button>}
                            <Button color="inherit" onClick={()=>navigate(-1)}>Cancelar</Button>
                            <Button disabled={status === "loading"} type="submit" form="form1" value="Submit">
                                {status === "loading"? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <span>{Strings.guardar}</span>
                                )}
                            </Button>
                        </Box>

                    </ValidatorFormCustom>
                </div>
            <br/>
            </div>
            {urlState?.hasOwnProperty("confirmDialog") &&
                <ConfirmDialog onConfirm={()=>Dispatch(eliminarFarmacia(urlState.confirmDialog))}/>
            }
        </Fragment>
    );

}
export default CrearFarmacia;
