import React, {Fragment, useEffect} from 'react';
import { mostrarUsosMultiples, eliminarUsosMultiples } from '../../redux/reducers/usosMultiplesReducers';
import AppBar from "../../components/appBar";
import {Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import {Close} from "@mui/icons-material";
import {useNavigate, useLocation} from "react-router-dom";
import Crear from "./crear";
import PlusFab from "../../components/PlusFab";
import ConfirmDialog from "../../components/ConfirmDialog";
import Strings from "../../assets/strings";
import _ from "lodash";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {Empty, LoadingProgress} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/collaborators.svg";

function UsosMultiples() {
    let navigate =useNavigate();
    const {state:urlState,...location} = useLocation();

    const Dispatch = useAppDispatch();
    const {list,status} = useAppSelector(state=>state.usosMultiples)


    useEffect(() => {
        Dispatch(mostrarUsosMultiples());
    }, [Dispatch]);


    const isEmpty = status !== "loading" && Object.keys(list??{})?.length === 0;

    // const groups = list.reduce((groups, item) => ({
    //     ...groups,
    //     [item.group]: [...(groups[item.group] || []), item]
    // }), {});
    //

    return <>
        <AppBar title={Strings["usosMultiples"]}/>
        <section className="contentInner">
            <div className="table-responsive">
                {status === "loading" && isEmpty && <LoadingProgress vh/>}
                {isEmpty && !status === "loading" ? (
                    <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.usuarios}>
                        <p>{Strings.agentesHelpMsj}</p>
                    </Empty>
                ):(
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Descripción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isEmpty && Object.keys(list??{}).map((property,i) =>
                                    <Fragment key={i}>
                                        <TableRow>
                                            <TableCell colSpan={6}><Typography variant={"body1"} color={"primary"}>{property}</Typography> </TableCell>
                                        </TableRow>
                                        {list[property].map((item,i)=>
                                            <TableRow key={i} onClick={()=> navigate(location.pathname, {state:item})}>
                                                <TableCell className={"actionCell"}>
                                                    <IconButton
                                                        onClick={(e)=> {
                                                            navigate(location.pathname, {state: {confirmDialog: item}})
                                                            e.stopPropagation();
                                                        }}
                                                        size="large">
                                                        <Close className={"text-red"}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{Strings[_.camelCase(item.usoDescripcion)] || <span className="text-red">{item.usoDescripcion}</span> }</TableCell>

                                            </TableRow>
                                        )}
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}


            </div>

            <PlusFab component={Button} onClick={()=>navigate(location.pathname,{state:{id:null}})} />

        </section>
        <Crear/>
        {!!urlState?.hasOwnProperty("confirmDialog") &&
            <ConfirmDialog onConfirm={() => Dispatch(eliminarUsosMultiples(urlState.confirmDialog))}/>
        }
    </>;

}

export default UsosMultiples

